<template>
  <div class="order-tasks">
    <LlcFormationView v-if="isLlcFormation && accountUuid" v-bind:orderTask="orderTask" v-bind:businessEntityUuid="businessEntityUuid" v-bind:accountUuid="accountUuid"></LlcFormationView>
    <CorpFormationView v-else-if="isCorpFormation" v-bind:orderTask="orderTask"></CorpFormationView>
    <EinCreationView v-else-if="isEinCreation" v-bind:orderTask="orderTask"></EinCreationView>
    <RegisteredAgentServiceView v-else-if="isRegisteredAgent" v-bind:orderTask="orderTask"></RegisteredAgentServiceView>
    <WorryFreeServiceView v-else-if="isWorryFree" v-bind:orderTask="orderTask"></WorryFreeServiceView>
    <BankingServiceView v-else-if="isBanking" v-bind:orderTask="orderTask"></BankingServiceView>
    <InsuranceServiceView v-else-if="isInsurance" v-bind:orderTask="orderTask"></InsuranceServiceView>
    <LegacyAccountingServiceView v-else-if="isLegacyAccounting" v-bind:orderTask="orderTask">
    </LegacyAccountingServiceView>
    <AmendmentServiceView v-else-if="isAmendment" v-bind:orderTask="orderTask"></AmendmentServiceView>
    <AnnualReportServiceView v-else-if="isAnnualReport" v-bind:orderTask="orderTask"></AnnualReportServiceView>
    <StandardGoodStanding v-else-if="isStandardGoodStanding" v-bind:orderTask="orderTask"></StandardGoodStanding>
    <DomainNameRegView v-else-if="isDomainReg" v-bind:orderTask="orderTask"></DomainNameRegView>
    <BasicEmailView v-else-if="isBasicEmail" v-bind:orderTask="orderTask" :edit="edit"></BasicEmailView>
    <ChangeOfRegisteredAgentView v-else-if="isChangeOfRegisteredAgent" v-bind:orderTask="orderTask">
    </ChangeOfRegisteredAgentView>
    <PublicationView v-else-if="isNYPublication" v-bind:orderTask="orderTask"></PublicationView>
    <EntityNameReservationView v-else-if="isEntityNameReservation" v-bind:orderTask="orderTask">
    </EntityNameReservationView>
    <DbaEntityView v-else-if="isDbaEntity" v-bind:orderTask="orderTask"></DbaEntityView>
    <DbaComplianceView v-else-if="isDbaCompliance" v-bind:orderTask="orderTask"></DbaComplianceView>
    <SCorpDesignationView v-else-if="isSCorpDesignation" v-bind:orderTask="orderTask"></SCorpDesignationView>
    <BusinessLicenseReportView v-else-if="isBusinessLicenseReport" v-bind:orderTask="orderTask">
    </BusinessLicenseReportView>
    <VirtualBusinessAddressView v-else-if="isVirtualBusinessAddress" v-bind:orderTask="orderTask">
    </VirtualBusinessAddressView>
    <GenericServiceView v-else-if="isGenericService" v-bind:orderTask="orderTask"></GenericServiceView>
    <DaoDesignation v-else-if="isDaoDesignation" v-bind:orderTask="orderTask"></DaoDesignation>
    <WFGInitialReportView v-else-if="isWFGInitialReportView" v-bind:orderTaskUuid="orderTask.order_task_uuid">
    </WFGInitialReportView>
    <OperatingAgreementServiceView v-else-if="isOperatingAgreementView" v-bind:orderTask="orderTask">
    </OperatingAgreementServiceView>
    <PersonalCreditMonitoringServiceView v-else-if="isPersonalCreditMonitoringView" v-bind:orderTask="orderTask">
    </PersonalCreditMonitoringServiceView>
    <BofView v-else-if="isBofView" v-bind:orderTask="orderTask"></BofView>

    <div v-else-if="isOther">
      <h1 class="heading">{{ orderTask | orderTaskType }}</h1>
      <br />
      <p>ZenGarden does not supported this order task type yet. Please request it.</p>
    </div>
  </div>
</template>

<script>
import LlcFormationView from './services/llc-formation-view';
import CorpFormationView from './services/corp-formation-view';
import EinCreationView from './services/ein-creation-view';
import RegisteredAgentServiceView from './services/registered-agent-service-view';
import ChangeOfRegisteredAgentView from './services/change-of-registered-agent.vue';
import StandardGoodStanding from './services/standard-good-standing';
import WorryFreeServiceView from './services/worry-free-service-view';
import BankingServiceView from './services/banking-service-view';
import InsuranceServiceView from './services/insurance-service-view';
import LegacyAccountingServiceView from './services/legacy-accounting-service-view';
import AmendmentServiceView from './services/amendment-service-view.vue';
import DomainNameRegView from './services/domain-name-reg.vue';
import BasicEmailView from './services/basic-email.vue';
import PublicationView from './services/ny-publication-view';
import EntityNameReservationView from './services/entity-name-reservation-view';
import DbaEntityView from './services/dba-entity-view';
import DbaComplianceView from './services/dba-compliance-view';
import AnnualReportServiceView from './services/annual-report-service-view';
import SCorpDesignationView from './services/s-corp-designation';
import BusinessLicenseReportView from './services/business-license-report-view';
import VirtualBusinessAddressView from './services/virtual-business-address-view';
import GenericServiceView from './services/generic-service-view.vue';
import WFGInitialReportView from './services/wfg-initial-report-view.vue'
import OperatingAgreementServiceView from './services/operating-agreement-service-view.vue'
import PersonalCreditMonitoringServiceView from './services/personal-credit-monitoring-service-view.vue'
import BofView from './services/bof-filing-view.vue';

import { orderTaskTypeMixin } from '@/components/mixins';
import DaoDesignation from './services/dao-designation'

export default {
  name: 'OrderServiceDataView',

  components: {
    DaoDesignation,
    AnnualReportServiceView,
    LlcFormationView,
    CorpFormationView,
    EinCreationView,
    RegisteredAgentServiceView,
    WorryFreeServiceView,
    BankingServiceView,
    InsuranceServiceView,
    LegacyAccountingServiceView,
    AmendmentServiceView,
    StandardGoodStanding,
    DomainNameRegView,
    BasicEmailView,
    ChangeOfRegisteredAgentView,
    PublicationView,
    EntityNameReservationView,
    DbaEntityView,
    DbaComplianceView,
    SCorpDesignationView,
    BusinessLicenseReportView,
    VirtualBusinessAddressView,
    GenericServiceView,
    WFGInitialReportView,
    OperatingAgreementServiceView,
    PersonalCreditMonitoringServiceView,
    BofView
  },

  props: {
    taskUuid: {
      type: String,
      required: true
    },
    edit: {
      type: Boolean,
      required: false
    }
  },

  mixins: [orderTaskTypeMixin],

  computed: {
    orderTask() {
      const orderTask = this.$store.state.orders.orderTasks.find(({ order_task_uuid: uuid }) => uuid === this.taskUuid);
      return orderTask;
    },
    accountUuid() {
      return this.$store.state.orders.order.account_uuid;
    },
    businessEntityUuid() {
      return this.$store.state.orders.businessEntity.business_entity_uuid;
    },
    isLlcFormation() {
      return this.orderTask && this.orderTask.order_task_type === 'llc_formation';
    },
    isCorpFormation() {
      return this.orderTask && this.orderTask.order_task_type === 'corp_formation';
    },
    isEinCreation() {
      return this.orderTask && this.orderTask.order_task_type === 'ein_creation';
    },
    isRegisteredAgent() {
      return this.orderTask && this.orderTask.order_task_type === 'registered_agent_service';
    },
    isStandardGoodStanding() {
      return this.orderTask && this.orderTask.order_task_type === 'standard_good_standing';
    },
    isWorryFree() {
      return this.orderTask && this.orderTask.order_task_type === 'worry_free_service';
    },
    isBanking() {
      return this.orderTask && this.orderTask.order_task_type === 'banking';
    },
    isBasicEmail() {
      return this.orderTask && this.orderTask.order_task_type === 'basic_email';
    },
    isChangeOfRegisteredAgent() {
      return this.orderTask && this.orderTask.order_task_type === 'change_of_registered_agent';
    },
    isInsurance() {
      return this.orderTask && this.orderTask.order_task_type === 'insurance';
    },
    isLegacyAccounting() {
      return this.orderTask && this.orderTask.order_task_type === 'accounting';
    },
    isAmendment() {
      const annualReportProducts = ['llc_amendment', 'corp_amendment'];
      return this.orderTask && annualReportProducts.includes(this.orderTask.order_task_type);
    },
    isAnnualReport() {
      const annualReportProducts = ['llc_annual_report', 'corp_annual_report'];
      return this.orderTask && annualReportProducts.includes(this.orderTask.order_task_type);
    },
    isDomainReg() {
      return this.orderTask && this.orderTask.order_task_type === 'domain_name_reg';
    },
    isNYPublication() {
      return this.orderTask && this.orderTask.order_task_type === 'ny_publication';
    },
    isEntityNameReservation() {
      return this.orderTask && this.orderTask.order_task_type === 'entity_name_reservation';
    },
    isDbaEntity() {
      return this.orderTask && this.orderTask.order_task_type === 'dba_entity';
    },
    isDbaCompliance() {
      return this.orderTask && this.orderTask.order_task_type === 'dba_compliance';
    },
    isSCorpDesignation() {
      return this.orderTask && this.orderTask.order_task_type === 's_corp_designation';
    },
    isBusinessLicenseReport() {
      return this.orderTask
        && this.orderTask.order_task_type === 'business_license_report'
        && 'industry_id' in this.orderTask.order_task_data.business_data;
    },
    isVirtualBusinessAddress() {
      return this.orderTask && this.orderTask.order_task_type === 'virtual_business_address';
    },
    isDaoDesignation() {
      return this.orderTask && this.orderTask.order_task_type === 'dao_designation';
    },
    isGenericService() {
      const genericServices = ['dissolution', 'resignation', 'reinstatement', 'adjustment', 'other'];
      return this.orderTask && genericServices.includes(this.orderTask.order_task_type);
    },
    isWFGInitialReportView() {
      return this.orderTask && this.orderTask.order_task_type === 'wfg_initial_report';
    },
    isOperatingAgreementView() {
      return this.orderTask && this.orderTask.order_task_type === 'operating_agreement';
    },
    isPersonalCreditMonitoringView() {
      return this.orderTask && this.orderTask.order_task_type === 'personal_credit_monitoring';
    },
    isBofView() {
      return this.orderTask && this.orderTask.order_task_type === 'beneficial_ownership_filing'
    },
    isOther() {
      return this.orderTask;
    }
  }
};
</script>

<style lang="scss">
.order-tasks {
  h1.heading {
    display: inline-block;
  }
}
</style>
