<template>
  <div class="container">
    <vue-headful title="Orders"></vue-headful>
    <div class="top25 mb-2">
      <h1>Orders</h1>
    </div>
    <table class="table table-sm">
      <thead>
          <tr>
            <th v-for="(header, i) in columnHeaders" :key="i">
              <a href="" @click.prevent="sortingOptions(header.column)">{{header.header}}</a>
              <span v-if="header.column === sortBy">
                <i :class="[reverse ? 'fas fa-arrow-down' : 'fas fa-arrow-up' ]"></i>
                <a href="" class="fa fa-times" @click.prevent="defaultSorting()" ></a>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <OrderListItem v-for="order in sortedData" :key="order.order_uuid" :order="order" />
        </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { sortingDataMixin } from '../mixins';
import OrderListItem from './order-list-item';

export default {
  name: 'OrderList',

  mixins: [sortingDataMixin],

  components: {
    OrderListItem
  },
  data() {
    return {
      sortBy: 'default',
      reverse: false,
      columnHeaders: [
        { column: 'purchase_date', header: 'Purchase Date' },
        { column: 'jurisdictions', header: 'Jurisdictions' },
        { column: 'order_status', header: 'Status' },
        { column: 'fulfillment_level', header: 'Priority' },
        { column: 'api_version', header: 'API Version' },
        { column: 'salesforce', header: 'Salesforce' }
      ]
    };
  },
  computed: {
    ...mapState('orders', ['orders']),
    sortedData() {
      return this.orders.concat().sort(this.sorting(this.sortBy, this.reverse));
    }
  },
  methods: {
    defaultSorting() {
      this.sortBy = 'default';
      this.reverse = false;
    },
    sortingOptions(sortBy) {
      if (this.sortBy === sortBy) {
        this.reverse = !this.reverse;
      } else {
        this.reverse = false;
      }

      this.sortBy = sortBy;
    }
  },
  mounted() {
    this.$store.dispatch('orders/fetchOrders');
  }
};
</script>

<style lang="scss">
  h1 {
    padding: 10px 0 0 10px;
    font-size: 20pt;
    display: inline;
  }
  .nav-link {
    display: inline;
  }
  span{
    cursor: pointer;
  }
</style>
