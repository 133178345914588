<template>
  <div>
    <h2 class="header">Zen Money Essential</h2>
    <div class="box">
      <div class="col-md-12">
        <form>
          <div class="form-row py-1">
            <div class="form-group col-md-6">
              <label for="zbmoneyessential-price">ZenBusiness Price</label>
              <input v-model="price" class="form-control" placeholder="0" name="Price" id="zbmoneyessential-price" disabled/>
            </div>
          </div>
          <div class="form-row py-1">
            <div class="form-group col-md-6">
              <label for="zbmoneyessential-period">Pricing Plan</label>
              <select v-model="period" class="form-control" id="zbmoneyessential-period" disabled>
                <option value="1">Annual</option>
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { orderCreateProductMixin } from '@/components/mixins';

export default {
  name: 'zenMoneyEssentialCreate',

  props: ['pricing', 'jurisdiction'],

  mixins: [orderCreateProductMixin],

  data() {
    return {
      productData: {
        order_data: {
          service: 'zb_money_essential',
          jurisdiction: this.jurisdiction
        },
        order_item: {
          service: 'zb_money_essential',
          jurisdiction: this.jurisdiction,
          price: this.pricing.price,
          fees: this.pricing.fees,
          term: this.pricing.term,
          period: this.pricing.period,
          shouldLinkPlaid: false,
          affiliate_pricing_uuid: null,
        }
      }
    };
  }
};
</script>
