<template>
  <div>
    <div class="row heading">
      <div class="col">
        <h2>Insurance Service</h2>
      </div>
      <div class="row">
        <div class="col">
          <p>This is a legacy product that is no longer offered to customers. There is no action in ZenGarden to support this order task. </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InsuranceServiceView'
};
</script>

<style scoped>

</style>
