<template>
  <div class="raw-container">
    <br>
    <div v-for="attribute in basicAttributes" v-bind:key="attribute.id">
      <p><b>{{ attribute }}: </b> {{order[attribute]}}</p>
    </div>
    <p><b>Order Data:</b> <vue-json-pretty :data="order.order_data"></vue-json-pretty></p>
    <p><b>Order Items:</b> <vue-json-pretty :data="order.order_items"></vue-json-pretty></p>
    <p><b>Order Tracking</b></p><br>
    <p><vue-json-pretty :data="getOrderTracking"></vue-json-pretty></p>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { hasPermissionMixin } from '@/components/mixins';

export default {
  name: 'OrderRawView',

  components: {
    VueJsonPretty
  },
  mixins: [
    hasPermissionMixin
  ],
  data() {
    return {
      basicAttributes: ['order_uuid', 'account_uuid', 'jurisdiction', 'fulfillment_level', 'order_status'],
      nestedAttributes: ['order_data', 'order_items']
    };
  },
  computed: {
    ...mapState('orders', ['order']),

    ...mapGetters({
      getOrderTracking: 'orders/getOrderTracking',
    }),
    canAccessRawData() {
      return this.hasPermission('access:developers');
    }
  }
};
</script>

<style lang="scss">
  .raw-container {
    p {
      float: none;
    }
  }
</style>
