<template>
  <tr>
    <td>
      <router-link :to="redirectAccordingToOrderTask(orderTask)">
        {{ orderTask|orderTaskType }}
      </router-link>
    </td>
    <td>{{ orderTask.order_task_jurisdiction }}</td>
    <td><span class="badge" v-bind:class="status">{{ orderTask.order_task_status }}</span></td>
    <td>{{ orderTask.delayed|yesno }}</td>
    <td><a :href="assetSalesforceUrl" target="_blank">Customer Asset</a></td>
  </tr>
</template>

<script>

import { mapGetters } from 'vuex';
import { yesNoMixin, orderTaskTypeMixin } from '@/components/mixins';
import manifest from '../../manifest';

export default {
  name: 'OrderTaskListItem',

  props: ['orderTask', 'uuid'],

  mixins: [yesNoMixin, orderTaskTypeMixin],

  computed: {
    ...mapGetters({
      accountUuid: 'accounts/getAccountUuid'
    }),
    status() {
      if (this.orderTask.order_task_status === 'incomplete') {
        return {
          'bg-secondary': true
        };
      } if (this.orderTask.order_task_status === 'canceled') {
        return {
          'bg-danger': true
        };
      }
      return {
        'bg-info': true
      };
    },
    assetSalesforceUrl() {
      return `${manifest.clients.salesforce.host}lightning/r/${this.orderTask.customer_asset_sf_id}/view`;
    }
  },

  methods: {
    refresh() {
      this.$store.dispatch('orders/fetchOrderTasks', { uuid: this.uuid });
    },
    redirectAccordingToOrderTask(orderTask) {
      if(orderTask && (orderTask.order_task_type=="zb_money_pro" || orderTask.order_task_type=="zb_money_essential")) {
        return { name: 'CustomerAssetsView', params: { accountUuid: this.accountUuid } }
      }
      return { name: 'OrderServiceDataView', params: { taskUuid: orderTask.order_task_uuid } }
    }
  }

};
</script>

<style lang="scss" scoped>
  .badge {
    text-transform: capitalize;
  }
</style>
