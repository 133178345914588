<template>
  <div>
    <h2 class="header">Basic Email - {{transOrRecurring}}</h2>
    <div class="box">
      <div class="col-md-12">
        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <div v-if="!isWebToolsSelected">
                <label>Pricing Plan</label>
                <select v-model="price" class="form-control" id="email-price">
                  <option disabled>Please select one</option>
                  <option :value="annual_price" selected>ZB Annual (${{annual_price}}/year)</option>
                  <option value="0">FREE ($0/year for qualifying premium customers)</option>
                </select>
                <label>Domain Selection</label>
                <select class="form-control" v-model="productData.order_data.domain_name" id="email-domain_name">
                  <option selected disabled value="undefined">Please select one</option>
                  <option v-for="domain in filteredDomains" v-bind:key="domain.domain_name" :value="domain.domain_name"> {{ domain.domain_name }} </option>
                </select>
              </div>
              Email Address
              <input
                v-model="productData.order_data.email_address"
                class="form-control"
                v-bind:class="{ 'is-invalid': !validateEmailAddress(productData.order_data.email_address) }"
                placeholder="nameyouremail"
                name="EmailAddress"
                id="emailAddress"/>
              <span v-if="!validateEmailAddress(productData.order_data.email_address)" class="invalid-feedback"><small>Invalid Email Address</small></span>
              <span v-if="!emailAddressAvailability(productData.order_data.email_address, productData.order_data.domain_name, emails)" style="color: #dc3545; font-size: .875em;">
                <small>Email Address Taken</small><br>
              </span>
              <span> <b>{{ productData.order_data.email_address }}@{{ productData.order_data.domain_name }} </b></span>
            </div>
          </div>
          <div class="form-row" v-if="period === 1 && price !== 0 && !isWebToolsSelected">
            <div class="form-group col-md-12">
              <div class="form-check">
                <input v-model="applyCoupon" class="form-check-input" type="checkbox" id="applyCoupon" @change="setCoupon">
                <label class="form-check-label" for="applyCoupon">
                  Apply First Year Free Coupon
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { orderCreateProductMixin, emailUserNameMixin, emailAddressAvailabilityMixin } from '@/components/mixins';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'basicEmailCreate',

  props: ['pricing', 'jurisdiction', 'coupons'],

  mixins: [orderCreateProductMixin, emailUserNameMixin, emailAddressAvailabilityMixin],

  data() {
    return {
      productData: {
        order_data: {
          service: 'basic_email',
          jurisdiction: this.jurisdiction,
          standalone: true,
          is_auto_included: false,
          email_address: null,
          domain_name: null,
          skip: false
        },
        order_item: {
          service: 'basic_email',
          jurisdiction: this.jurisdiction,
          price: this.pricing.price,
          fees: this.pricing.fees,
          term: this.pricing.term,
          period: this.pricing.period
        },
      },
      coupon: this.coupons.find((c) => c.external_coupon_id === '25_off_first_year')
    };
  },
  computed: {
    isWebToolsSelected: {
      get() {
        return this.$store.state.orderCreate.selectedProducts.includes('website_tools_bundle');
      }
    },
    ...mapGetters( {
      businessEntityUuid: 'businessEntities/getBusinessEntityUuid',
      domainNameSelected: 'orderCreate/getDomainSelected',
    }),
    ...mapState({
      domains: (state) => state.domains.domains.results,
      emails: (state) => state.domains.emails,
    }),
    filteredDomains: {
      get() {
        try {
          const domains_list = this.domains.filter((domain) => (!domain.is_external && domain.active));
          domains_list.unshift({domain_name: "not-domain-name"})  // This is intended to be used only for billing, don't sent' the partner request
          return domains_list;
        } catch (err) {
          return [];
        }
      }
    },
    isValid: function() {
      const validations = []
      const email_address = this.productData.order_data.email_address
      validations.push( email_address!== null && email_address !== '')
      validations.push(this.emailAddressAvailability(this.productData.order_data.email_address, this.productData.order_data.domain_name, this.emails));
      validations.push(this.validateEmailAddress(this.productData.order_data.email_address));
      return validations.every(Boolean)
    }
  },
  watch: {
    domainNameSelected(value) {
      if (value) {
        this.productData.order_data.domain_name = this.domainNameSelected;
      }
    },
  },
  created () {
    if(this.$store.state.orderCreate.selectedProducts.includes('website_tools_bundle')) {
      this.productData.order_item.price = 0
      this.productData.order_item.fees = 0
    }
  },
  methods: {
    ...mapActions({
      fetchDomains: 'domains/fetchDomains',
      fetchEmails: 'domains/fetchEmails',
    }),
  },
  mounted() {
    this.fetchDomains({ businessEntityUuid: this.businessEntityUuid });
    this.fetchEmails({ businessEntityUuid: this.businessEntityUuid });
  }
};
</script>


