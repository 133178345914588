<template>
  <div>
    <div v-if="hasInvalidChanges" id="invalid-changes-banner" class="changeNotAllowed">
      <span>
        Note: the customer has requested changes as part of this amendment or annual report which our system indicates
        is not allowed by this state. This can happen if the state has changed its rules recently around what changes
        are allowed as part of an AR/amendment, or if someone at ZenBusiness recently corrected the system's
        configuration. <br /> <br />

        If the fields highlighted in red can be changed as part of this order, ask Rita, Ayleen or Brett to
        update the configuration for this jurisdiction. <br />
        If the fields highlighted in red CANNOT be changed as part of this order, the customer should be informed that
        these changes cannot be submitted as part of this amendment or annual report.
      </span>
    </div>
    <h2 class="header">Changes</h2>
    <div class="box">
      <div class="row"
           id="name-changes"
           v-if="shouldShowField(nameChanged)" v-bind:class="classesForFilingChangeField(nameChanged, nameCanBeChanged)">
        <div class="col">
          <dt>Old Name</dt>
          <dd v-if="filingChange.old_data">{{ filingChange.old_data.business_entity_name }}</dd>
          <dd v-else>None on file.</dd>
        </div>
        <div class="col">
          <dt>New Name</dt>
          <dd v-if="newData && newData.business_entity_name">{{ newData.business_entity_name }}</dd>
          <dd v-else-if="filingChange.old_data">{{ filingChange.old_data.business_entity_name }}</dd>
          <dd v-else>None on file.</dd>
        </div>
      </div>
      <div class="row"
           id="ein-changes"
           v-if="shouldShowField(einChanged)" v-bind:class="classesForFilingChangeField(einChanged, einCanBeChanged)">
        <div class="col">
          <dt>Old EIN</dt>
          <dd v-if="filingChange.old_data.business_entity_data.ein">
            {{ filingChange.old_data.business_entity_data.ein }}
          </dd>
          <dd v-else>None on file.</dd>
        </div>
        <div class="col">
          <dt>New EIN</dt>
          <dd v-if="newData && newData.business_entity_data.ein">{{ newData.business_entity_data.ein }}</dd>
          <dd v-else-if="filingChange.old_data.business_entity_data.ein">
            {{ filingChange.old_data.business_entity_data.ein }}
          </dd>
          <dd v-else>None on file.</dd>
        </div>
      </div>
      <div class="row"
           id="purpose-changes"
           v-if="shouldShowField(purposeChanged)" v-bind:class="classesForFilingChangeField(purposeChanged, purposeCanBeChanged)">
        <div class="col">
          <dt>Old Business Purpose</dt>
          <dd v-if="filingChange.old_data">{{ filingChange.old_data.business_entity_data.business_purpose }}</dd>
          <dd v-else>None on file.</dd>
        </div>
        <div class="col">
          <dt>New Business Purpose</dt>
          <dd v-if="newData && newData.business_entity_data.business_purpose">{{ newData.business_entity_data.business_purpose }}</dd>
          <dd v-else-if="filingChange.old_data">{{ filingChange.old_data.business_entity_data.business_purpose }}</dd>
          <dd v-else>None on file.</dd>
        </div>
      </div>
      <div class="row"
           id="management-changes"
           v-if="shouldShowField(managementChanged)" v-bind:class="classesForFilingChangeField(managementChanged, managementCanBeChanged)">
        <div class="col">
          <dt>Old Management Structure</dt>
          <dd v-if="filingChange.old_data">{{ filingChange.old_data.business_entity_data.manager_type }}</dd>
          <dd v-else>None on file.</dd>
        </div>
        <div class="col">
          <dt>New Management Structure</dt>
          <dd v-if="newData && newData.business_entity_data.manager_type">{{ newData.business_entity_data.manager_type }}</dd>
          <dd v-else-if="filingChange.old_data">{{ filingChange.old_data.business_entity_data.manager_type }}</dd>
          <dd v-else>None on file.</dd>
        </div>
      </div>
      <div
        class="row"
        id="manager-changes"
        v-if="businessEntity.business_entity_type === 'llc' && shouldShowField(managersChanged)"
        v-bind:class="classesForFilingChangeField(managersChanged, managersCanBeChanged)"
      >
        <div class="col">
          <dt>Old Managers</dt>
          <dd v-if="filingChange.old_data.business_entity_data.managers.length > 0">
            <Person
              v-for="manager in filingChange.old_data.business_entity_data.managers"
              v-bind:key="manager.first_name"
              :person="manager"
              :jurisdictionCode="domesticJurisdictionCode"
              :isAnnualReport="isAnnualReport"
              :showIndividualName="true"
            />
          </dd>
          <dd v-else>No managers on file.</dd>
        </div>
        <div class="col">
          <dt>New Managers</dt>
          <dd v-if="newData && newData.business_entity_data.managers">
            <Person
              v-for="manager in newData.business_entity_data.managers"
              v-bind:key="manager.first_name"
              :person="manager"
              :jurisdictionCode="domesticJurisdictionCode"
              :isAnnualReport="isAnnualReport"
              :showIndividualName="true"
            />
          </dd>
          <dd v-else-if="filingChange.old_data.business_entity_data.managers.length > 0">
            <Person
              v-for="manager in filingChange.old_data.business_entity_data.managers"
              v-bind:key="manager.first_name"
              :person="manager"
              :jurisdictionCode="domesticJurisdictionCode"
              :isAnnualReport="isAnnualReport"
              :showIndividualName="true"
            />
          </dd>
          <dd v-else>No managers on file.</dd>
        </div>
      </div>
      <div
        class="row"
        id="member-changes"
        v-if="businessEntity.business_entity_type === 'llc' && shouldShowField(membersChanged)"
        v-bind:class="classesForFilingChangeField(membersChanged, membersCanBeChanged)"
      >
        <div class="col">
          <dt>Old Members</dt>
          <dd v-if="filingChange.old_data.business_entity_data.members.length > 0">
            <Person
              v-for="member in filingChange.old_data.business_entity_data.members"
              v-bind:key="member.first_name"
              :person="member"
              :jurisdictionCode="domesticJurisdictionCode"
              :isAnnualReport="isAnnualReport"
              :showIndividualName="true"
            />
          </dd>
          <dd v-else>No members on file.</dd>
        </div>
        <div class="col">
          <dt>New Members</dt>
          <dd v-if="newData && newData.business_entity_data.members">
            <Person
              v-for="member in newData.business_entity_data.members"
              v-bind:key="member.first_name"
              :person="member"
              :jurisdictionCode="domesticJurisdictionCode"
              :isAnnualReport="isAnnualReport"
              :showIndividualName="true"
            />
          </dd>
          <dd
            v-else-if="filingChange.old_data.business_entity_data.members.length > 0">
            <Person
              v-for="member in filingChange.old_data.business_entity_data.members"
              v-bind:key="member.first_name"
              :person="member"
              :jurisdictionCode="domesticJurisdictionCode"
              :isAnnualReport="isAnnualReport"
              :showIndividualName="true"
            />
          </dd>
          <dd v-else>No members on file.</dd>
        </div>
      </div>
      <div
        class="row"
        id="director-changes"
        v-if="businessEntity.business_entity_type === 'corp' && shouldShowField(directorsChanged)" v-bind:class="classesForFilingChangeField(directorsChanged, directorsCanBeChanged)">
        <div class="col">
          <dt>Old Directors</dt>
          <dd v-if="filingChange.old_data.business_entity_data.directors.length > 0">
            <Person v-for="director in filingChange.old_data.business_entity_data.directors" :person="director" v-bind:key="director.first_name"  :jurisdictionCode="domesticJurisdictionCode" :isAnnualReport="isAnnualReport" />
          </dd>
          <dd v-else>No directors on file.</dd>
        </div>
        <div class="col">
          <dt>New Directors</dt>
          <dd v-if="newData && newData.business_entity_data.directors">
            <Person v-for="director in newData.business_entity_data.directors" :person="director" v-bind:key="director.first_name"  :jurisdictionCode="domesticJurisdictionCode" :isAnnualReport="isAnnualReport" />
          </dd>
          <dd v-else-if="filingChange.old_data.business_entity_data.directors.length > 0">
            <Person v-for="director in filingChange.old_data.business_entity_data.directors" :person="director" v-bind:key="director.first_name"  :jurisdictionCode="domesticJurisdictionCode" :isAnnualReport="isAnnualReport" />
          </dd>
          <dd v-else>No directors on file.</dd>
        </div>
      </div>
      <div class="row"
           id="officer-changes"
           v-if="businessEntity.business_entity_type === 'corp' && shouldShowField(officersChanged)" v-bind:class="classesForFilingChangeField(officersChanged, officersCanBeChanged)">
        <div class="col">
          <dt>Old Officers</dt>
          <dd v-if="filingChange.old_data.business_entity_data.officers.length > 0">
            <Person v-for="officer in filingChange.old_data.business_entity_data.officers" :person="officer" v-bind:key="officer.first_name"  :jurisdictionCode="domesticJurisdictionCode" :isAnnualReport="isAnnualReport" />
          </dd>
          <dd v-else>No officers on file.</dd>
        </div>
        <div class="col">
          <dt>New Officers</dt>
          <dd v-if="newData && newData.business_entity_data.officers">
            <Person v-for="officer in newData.business_entity_data.officers" :person="officer" v-bind:key="officer.first_name"  :jurisdictionCode="domesticJurisdictionCode" :isAnnualReport="isAnnualReport" />
          </dd>
          <dd v-else-if="filingChange.old_data.business_entity_data.officers.length > 0">
            <Person v-for="officer in filingChange.old_data.business_entity_data.officers" :person="officer" v-bind:key="officer.first_name"  :jurisdictionCode="domesticJurisdictionCode" :isAnnualReport="isAnnualReport" />
          </dd>
          <dd v-else>No officers on file.</dd>
        </div>
      </div>
      <div class="row"
           id="stock-info-changes"
           v-if="businessEntity.business_entity_type === 'corp' && shouldShowField(stockInfoChanged)" v-bind:class="classesForFilingChangeField(stockInfoChanged, stockInfoCanBeChanged)">
        <div class="col">
          <dt>Old Stock Info</dt>
          <div class="row">
            <div class="col">
              <dt>Authorized Shares</dt>
              <dd v-if="filingChange.old_data.business_entity_data.authorized_shares">{{ filingChange.old_data.business_entity_data.authorized_shares }}</dd>
              <dd v-else>No info on file.</dd>
            </div>
            <div class="col">
              <dt>Par Value</dt>
              <dd v-if="filingChange.old_data.business_entity_data.par_value">{{ filingChange.old_data.business_entity_data.par_value }}</dd>
              <dd v-else>No info on file.</dd>
            </div>
            <div class="col">
              <dt>More than one class or series of shares</dt>
              <dd v-if="filingChange.old_data.business_entity_data.has_more_than_one_share">{{ filingChange.old_data.business_entity_data.has_more_than_one_share }}</dd>
              <dd v-else>No info on file.</dd>
            </div>
          </div>
        </div>
        <div class="col">
          <dt>New Stock Info</dt>
          <div class="row">
            <div class="col">
              <dt>Authorized Shares</dt>
              <dd v-if="newData && newData.business_entity_data.authorized_shares">{{ newData.business_entity_data.authorized_shares }}</dd>
              <dd v-else-if="filingChange.old_data.business_entity_data.authorized_shares">{{ filingChange.old_data.business_entity_data.authorized_shares }}</dd>
              <dd v-else>No info on file.</dd>
            </div>
            <div class="col">
              <dt>Par Value</dt>
              <dd v-if="newData && newData.business_entity_data.par_value">{{ newData.business_entity_data.par_value }}</dd>
              <dd v-else-if="filingChange.old_data.business_entity_data.par_value">{{ filingChange.old_data.business_entity_data.par_value }}</dd>
              <dd v-else>No info on file.</dd>
            </div>
          </div>
        </div>
      </div>
      <div class="row"
           id="physical-address-changes"
           v-if="shouldShowField(physicalAddressChanged)" v-bind:class="classesForFilingChangeField(physicalAddressChanged, addressesCanBeChanged)">
        <div class="col">
          <dt>Old Physical Address</dt>
          <dd v-if="filingChange.old_data.business_entity_data.address"><Person :person="filingChange.old_data.business_entity_data.address" /></dd>
          <dd v-else>None on file.</dd>
        </div>
        <div class="col">
          <dt>New Physical Address</dt>
          <dd v-if="newData && newData.business_entity_data.address"><Person :person="newData.business_entity_data.address" /></dd>
          <dd v-else-if="filingChange.old_data.business_entity_data.address"><Person :person="filingChange.old_data.business_entity_data.address" /></dd>
          <dd v-else>None on file.</dd>
        </div>
      </div>
      <div class="row"
           id="mailing-address-changes"
           v-if="shouldShowField(mailingAddressChanged)" v-bind:class="classesForFilingChangeField(mailingAddressChanged, addressesCanBeChanged)">
        <div class="col">
          <dt>Old Mailing Address</dt>
          <dd v-if="filingChange.old_data.business_entity_data.mailing_address"><Person :person="filingChange.old_data.business_entity_data.mailing_address" /></dd>
          <dd v-else>None on file.</dd>
        </div>
        <div class="col">
          <dt>New Mailing Address</dt>
          <dd v-if="newData && newData.business_entity_data.mailing_address"><Person :person="newData.business_entity_data.mailing_address" /></dd>
          <dd v-else-if="filingChange.old_data.business_entity_data.mailing_address"><Person :person="filingChange.old_data.business_entity_data.mailing_address" /></dd>
          <dd v-else>None on file.</dd>
        </div>
      </div>
      <div class="row"
           id="principal-address-changes"
           v-if="shouldShowField(principalAddressChanged)" v-bind:class="classesForFilingChangeField(principalAddressChanged, addressesCanBeChanged)">
        <div class="col">
          <dt>Old Principal Address</dt>
          <dd v-if="filingChange.old_data.business_entity_data.principal_address"><Person :person="filingChange.old_data.business_entity_data.principal_address" /></dd>
          <dd v-else>None on file.</dd>
        </div>
        <div class="col">
          <dt>New Principal Address</dt>
          <dd v-if="newData && newData.business_entity_data.principal_address"><Person :person="newData.business_entity_data.principal_address" /></dd>
          <dd v-else-if="filingChange.old_data.business_entity_data.principal_address"><Person :person="filingChange.old_data.business_entity_data.principal_address" /></dd>
          <dd v-else>None on file.</dd>
        </div>
      </div>
      <div class="row"
           id="authorized-signatory-changes"
           v-if="shouldShowField(authorizedSignatoryChanged)" v-bind:class="classesForFilingChangeField(authorizedSignatoryChanged, authorizedSignatoryCanBeChanged)">
        <div class="col">
          <dt>Old Authorized Signatory</dt>
          <dd v-if="filingChange.old_data.business_entity_data.authorized_signatory">
            {{ this.computeFullNameOfPerson(filingChange.old_data.business_entity_data.authorized_signatory) }}, {{ authorizedSignatoryTitle(filingChange.old_data.business_entity_data.authorized_signatory) }}
          </dd>
          <dd v-else>None on file.</dd>
        </div>
        <div class="col">
          <dt>New Authorized Signatory</dt>
          <dd v-if="newData && newData.business_entity_data.authorized_signatory">
            {{ this.computeFullNameOfPerson(newData.business_entity_data.authorized_signatory) }}, {{ authorizedSignatoryTitle(newData.business_entity_data.authorized_signatory) }}
          </dd>
          <dd v-else-if="filingChange.old_data.business_entity_data.authorized_signatory">
            {{ this.computeFullNameOfPerson(filingChange.old_data.business_entity_data.authorized_signatory) }}, {{ authorizedSignatoryTitle(filingChange.old_data.business_entity_data.authorized_signatory) }}
          </dd>
          <dd v-else>None on file.</dd>
        </div>
      </div>
      <!-- RA Contact Changed -->
      <div class="row"
           id="ra-contact-changes"
           v-if="shouldShowField(raContactChanged)" v-bind:class="classesForFilingChangeField(raContactChanged, raContactCanBeChanged)">
        <div class="col">
          <dt>Old RA Contact</dt>
          <dd v-if="filingChange.old_data.business_entity_data.registered_agent">
            <Person :person="filingChange.old_data.business_entity_data.registered_agent" />
          </dd>
          <dd v-else>None on file.</dd>
        </div>
        <div class="col">
          <dt>New RA Contact</dt>
          <dd v-if="newData && newData.business_entity_data.registered_agent">
            <Person :person="newData.business_entity_data.registered_agent" />
          </dd>
          <dd v-else-if="filingChange.old_data.business_entity_data.registered_agent">
            <Person :person="filingChange.old_data.business_entity_data.registered_agent" />
          </dd>
          <dd v-else>None on file.</dd>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Person from './person';
import { computeFullNameOfPersonMixin, entityFilingChangeMixin, yesNoMixin } from '../mixins';

export default {
  /**
   * Component to render business entity information needed to fulfill an amendment or an annual report.
   * This component shows a side-by-side comparison of the existing entity's information to the desired changes to the
   * entity.
   * The annual report view should always show all fields. If a given field on an entity has not changed, the existing
   * value should be shown in the 'new value' column.
   */
  name: 'EntityFilingChange',
  /*
  Props:
  * filingChange: filing change object describing the old data for the entity
  * businessEntity: the business entity for the order task
  * domesticJurisdictionCode: the two-letter code for the state that is the domestic jurisdiction of the entity (if known)
  * orderTask: the order task associated with the filing change
  * onlyShowChangedValues: a flag that indicates whether to only show fields that have changed, or all fields
   */
  props: ['filingChange', 'businessEntity', 'domesticJurisdictionCode', 'orderTask', 'onlyShowChangedValues', 'isAnnualReport'],
  components: {
    Person
  },
  mixins: [computeFullNameOfPersonMixin, entityFilingChangeMixin, yesNoMixin],
  computed: {
    ...mapState({
      reportFieldConfig: (state) => state.products.reportFieldConfig
    }),
    newData() {
      return this.orderTask.order_task_data.new_data;
    },
    nameChanged() {
      return Boolean(this.newData && this.newData.business_entity_name);
    },
    nameCanBeChanged() {
      return this.configAllowsChangesToField('business_entity_name');
    },
    einChanged() {
      return Boolean(this.newData && this.newData.business_entity_data.ein);
    },
    einCanBeChanged() {
      return this.configAllowsChangesToField('ein');
    },
    purposeChanged() {
      return Boolean(this.newData && this.newData.business_entity_data.business_purpose);
    },
    purposeCanBeChanged() {
      return this.configAllowsChangesToField('business_purpose');
    },
    managementChanged() {
      return Boolean(this.newData && this.newData.business_entity_data.manager_type);
    },
    managementCanBeChanged() {
      return this.configAllowsChangesToField('structure');
    },
    membersChanged() {
      return Boolean(this.newData && this.newData.business_entity_data.members);
    },
    membersCanBeChanged() {
      return this.configAllowsChangesToField('members');
    },
    managersChanged() {
      return Boolean(this.newData && this.newData.business_entity_data.managers);
    },
    managersCanBeChanged() {
      return this.configAllowsChangesToField('managers');
    },
    directorsChanged() {
      return Boolean(this.newData && this.newData.business_entity_data.directors);
    },
    directorsCanBeChanged() {
      return this.configAllowsChangesToField('directors');
    },
    officersChanged() {
      return Boolean(this.newData && this.newData.business_entity_data.officers);
    },
    officersCanBeChanged() {
      return this.configAllowsChangesToField('officers');
    },
    stockInfoChanged() {
      return Boolean(this.newData && this.newData.business_entity_data.authorized_shares)
        || Boolean(this.newData && this.newData.business_entity_data.par_value);
    },
    stockInfoCanBeChanged() {
      return this.configAllowsChangesToField('stock_info');
    },
    physicalAddressChanged() {
      return Boolean(this.newData && this.newData.business_entity_data.address);
    },
    mailingAddressChanged() {
      return Boolean(this.newData && this.newData.business_entity_data.mailing_address);
    },
    principalAddressChanged() {
      return Boolean(this.newData && this.newData.business_entity_data.principal_address);
    },
    addressesCanBeChanged() {
      return this.configAllowsChangesToField('addresses');
    },
    authorizedSignatoryChanged() {
      return Boolean(this.newData && this.newData.business_entity_data.authorized_signatory);
    },
    authorizedSignatoryCanBeChanged() {
      return this.configAllowsChangesToField('authorized_signatory');
    },
    raContactChanged() {
      return Boolean(this.newData && this.newData.business_entity_data.registered_agent);
    },
    raContactCanBeChanged() {
      // There is no config for whether the RA contact can be changed, because it can be changed in all jurisdictions
      return true;
    },
    hasInvalidChanges() {
      return (this.nameChanged && !this.nameCanBeChanged)
        || (this.einChanged && !this.einCanBeChanged)
        || (this.purposeChanged && !this.purposeCanBeChanged)
        || (this.managementChanged && !this.managementCanBeChanged)
        || (this.managersChanged && !this.managersCanBeChanged)
        || (this.membersChanged && !this.membersCanBeChanged)
        || (this.officersChanged && !this.officersCanBeChanged)
        || (this.directorsChanged && !this.directorsCanBeChanged)
        || (this.stockInfoChanged && !this.stockInfoCanBeChanged)
        || (this.physicalAddressChanged && !this.addressesCanBeChanged)
        || (this.mailingAddressChanged && !this.addressesCanBeChanged)
        || (this.principalAddressChanged && !this.addressesCanBeChanged)
        || (this.authorizedSignatoryChanged && !this.authorizedSignatoryCanBeChanged);
    }
  },
  methods: {
    configAllowsChangesToField(field) {
      try {
        const entityType = this.businessEntity.business_entity_type;
        const jurisdiction = this.domesticJurisdictionCode === this.orderTask.order_task_jurisdiction ? 'domestic' : 'foreign';
        const documentType = this.orderTask.order_task_type.includes('amendment') ? 'amendment' : 'annual_report';
        return this.reportFieldConfig[entityType][jurisdiction][documentType][field];
      } catch (e) {
        return false;
      }
    },
    shouldShowField(fieldHasChanged) {
      // Show this field if it has changed, or if the component should always show all fields
      return (!this.onlyShowChangedValues) || fieldHasChanged;
    },
    classesForFilingChangeField(fieldHasChanged, configAllowsChanges) {
      /*
      Determine what classes to apply to a filing change field, in order to change its background color.
      If the field value has changed, and the config indicates that this change is allowed for the given state/entity
      type/etc., then show this change in green.
      If the field has changed, and the config indicates that this change is not allowed, then show this in red --
      according to our configuration, the customer has requested a change to their entity that the state will not allow.
      Otherwise, show the field in white.
       */
      if (fieldHasChanged && configAllowsChanges) {
        return {
          changeAllowed: true,
          changeNotAllowed: false
        };
      } if (fieldHasChanged && !configAllowsChanges) {
        return {
          changeAllowed: false,
          changeNotAllowed: true
        };
      }
      return {};
    }
  },
  mounted() {
    this.$store.dispatch('businessEntities/fetchEntityFilingChanges', { uuid: this.orderTask.business_entity_uuid });
  }
};
</script>
<style lang="scss" scoped>
  dt {
    font-size: rem(17);
  }
  .changeAllowed {
    background-color: #d4edda;
  }
  .changeNotAllowed {
    background-color: #ffb2b0;
  }
  .col {
    margin-bottom: 20px;
  }
</style>
