<template>
  <div>
    <div class="row heading">
      <div class="col">
        <h2>DAO Designation</h2>
      </div>
      <div class="row">
        <div class="col">
          Smart Contract Managed ONLY: {{ this.daoDesignation['smart_contract_managed'] | yesno }}
        </div>
      </div>
      <div class="row">
        <div class="col">
          Notice Approved: {{ daoDesignation['notice_approved'] | yesno }}
        </div>
      </div>
      <div class="row">
        <div class="col">
          Smart Contract Link: {{ daoDesignation['smart_contract_link'] | '' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { yesNoMixin } from '@/components/mixins';

export default {
  name: 'DaoDesignation',
  props: {
    orderTask: {
      type: Object,
      required: true
    }
  },
  mixins: [yesNoMixin],
  computed: {
    daoDesignation() {
      if (!this.orderTask || !this.orderTask.fulfillment_data) {
        throw new Error('No orderTask or fulfillment_data found - DAO Designation');
      }
      return this.orderTask.fulfillment_data;
    }
  }
};
</script>

<style scoped>

</style>
