<template>
  <div>
    <h2 class="header">CORP Amendment - {{transOrRecurring}}</h2>
    <div class="box">
      <div class="col-md-12">
        <form>
          <div class="form-row">
            <div class="form-group col-md-12">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="waivePrice">
                <label class="form-check-label" for="defaultCheck1">
                  Waive ZenBusiness Fee (for qualifying WFG customers)
                </label>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="price">ZenBusiness Price</label>
              <input v-model="price" class="form-control" placeholder="10" name="Price" id="price" disabled/>
            </div>
            <div class="form-group col-md-6">
              <label for="fees">State Fees</label>
              <input v-model="fees" class="form-control" placeholder="50" name="Fees" id="fees"/>
            </div>
            <div class="form-group col-md-6">
              <label for="fees">Credits</label>
              <input v-model="productData.order_item.credits" class="form-control" placeholder="50" name="Fees" id="credits"/>
            </div>
            <div class="form-group col-md-6">
              <label for="fees">Available Credits (Worry Free Guarantee)</label>
              <p>{{availableCredits}}</p>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="fees">Amendment Type</label>
              <select v-model="productData.order_data.amendment_type" class="form-control" id="amendment-type">
                <option disabled>Please select one</option>
                <option>Business Name</option>
                <option>Business Address</option>
                <option>Mailing Address</option>
                <option>Officers or Directors</option>
                <option>Stock Amendment</option>
                <option>Change of Registered Agent</option>
                <option>Other</option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="fees">Amendment Description</label>
              <textarea class="form-control" v-model="productData.order_data.amendment_description" placeholder="Please enter a detailed description of the amendment request." name="Fees" id="description"></textarea>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { orderCreateProductMixin } from '@/components/mixins';

export default {
  name: 'corpAmendmentCreate',

  props: ['pricing', 'jurisdiction', 'wfgCreditBalance'],

  mixins: [orderCreateProductMixin],

  data() {
    return {
      productData: {
        order_data: {
          service: 'corp_amendment',
          jurisdiction: this.jurisdiction,
          formation_jurisdiction: this.jurisdiction,
          amendment_type: null,
          amendment_description: null
        },
        order_item: {
          service: 'corp_amendment',
          jurisdiction: this.jurisdiction,
          price: this.pricing.price,
          fees: this.pricing.fees,
          credits: 0,
          credit_balance_uuid: this.wfgCreditBalance !== undefined ? this.wfgCreditBalance.credit_balance_uuid : null
        }
      }
    };
  },

  computed: {
    ...mapState({
      businessEntityUUID: (state) => state.orderCreate.order.business_entity_uuid,
      creditBalances: (state) => state.businessEntities.creditBalances
    }),
    availableCredits() {
      return this.wfgCreditBalance !== undefined ? (this.wfgCreditBalance.subscription_credits + this.wfgCreditBalance.extra_credits) : 0;
    }
  }
};
</script>
