<template>
  <div>
    <h2 class="header">Expedite</h2>
    <div class="box">
      <div class="col-md-12">
        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="price">ZenBusiness Price</label>
              <input v-model="price" class="form-control" placeholder="0" name="Price" id="price">
            </div>
            <div class="form-group col-md-6">
              <label for="fees">State Fees</label>
              <input v-model="fees" class="form-control" placeholder="0" name="Fees" id="fees"/>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { orderCreateProductMixin } from '@/components/mixins';

export default {
  name: 'expediteCreate',

  props: ['pricing', 'jurisdiction'],

  mixins: [orderCreateProductMixin],

  data() {
    return {
      productData: {
        order_data: {
          service: 'expedite',
          jurisdiction: this.jurisdiction
        },
        order_item: {
          service: 'expedite',
          jurisdiction: this.jurisdiction,
          price: this.pricing.price,
          fees: this.pricing.fees
        }
      }
    };
  }
};
</script>
