<template>
  <div>
    <h2 class="header">{{ generic_service.service }}</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <dt>Fees</dt>
          <dd>{{ order_item.fees }}</dd>
        </div>
        <div class="col">
          <dt>Price</dt>
          <dd>{{ order_item.price }}</dd>
        </div>
        <div class="col">
            <dt>Status</dt>
            <dd>{{ order_item.status }}</dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Jurisdiction</dt>
          <dd>{{ generic_service.jurisdiction }}</dd>
        </div>
        <div class="col">
          <dt>Formation Jurisdiction</dt>
          <dd>{{ generic_service.formation_jurisdiction }}</dd>
        </div>
        <div class="col">
          <dt>Created by</dt>
          <dd>{{ generic_service.created_by }}</dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Additional Details</dt>
          <dd>{{ generic_service.service_description }}</dd>
        </div>
      </div>
    </div>
    <OrderTaskFulfillment 
            v-if="(['PA'].includes(jurisdictionCode) && orderTaskType === 'resignation') || 
                  (jurisdictionCode === 'CA' && orderTaskType === 'standard_good_standing')"
            :business_entity_uuid="businessEntityUuid"
            :order_task_status="orderTaskStatus" 
            :jurisdiction_code="jurisdictionCode"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import OrderTaskFulfillment from './order-task-fulfillment.vue'

export default {
  name: 'GenericServiceView',
  components: {
    OrderTaskFulfillment
  },
  props: ['orderTask'],
  computed: {
    ...mapState('orders', ['order']),
    generic_service() {
      return this.orderTask.order_task_data;
    },
    order_item() {
      return this.order.order_items.find((a) => a.service === this.generic_service.service);
    },
    businessEntityUuid () {
      return this.orderTask.business_entity_uuid;
    },
    jurisdictionCode () {
      return this.orderTask.order_task_jurisdiction;
    },
    orderTaskStatus () {
      return this.orderTask.order_task_status;
    },
    orderTaskType () {
      return this.orderTask.order_task_type;
    },
  }
};
</script>

<style lang="scss" scoped>

</style>
