<template>
  <div>
    <div class="row">
      <div class="col col-md-12" v-if="businessEntity">
        <dt>Business Entity</dt>
        {{ entityDisplayName }} <span class="badge bg-danger" v-on:click="clearEntityName">change</span>
      </div>
      <div class="col col-md-12" v-else>
        <dt>Select Business Entity</dt>
        <select class="form-control" v-model="businessEntityUUID" id="biz-type">
          <option selected disabled value="undefined">Please select one</option>
          <option v-for="businessEntity in sortedBusinessEntities" v-bind:key="businessEntity.business_entity_uuid" :value="businessEntity"> {{ businessEntity.business_entity_name }} ({{ businessEntity.business_entity_data.domestic_jurisdiction }}) </option>
        </select>
      </div>
      <div class="col col-md-12" v-if="businessEntity && entityJurisdiction">
        <dt>Jurisdiction</dt>
        {{ entityJurisdiction }} {{ entityJurisdiction === domesticJurisdiction ? '(Domestic)' : '' }} <span class="badge bg-danger" v-on:click="clearEntityJurisdiction">change</span>
      </div>
      <div class="col col-md-12" v-if="businessEntity && !entityJurisdiction">
        <dt>Select Jurisdiction</dt>
        <select class="form-control" v-model="jurisdiction">
          <option disabled value="">Please select one</option>
          <option v-for="jur in allJurisdictions" v-bind:key="jur" :value="jur">{{ jur }} {{ jur === domesticJurisdiction ? '(Domestic)' : '' }}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <dt>Select Products</dt>
        <div v-if="businessEntity && entityJurisdiction" class="form-check">
          <div v-if="hasPlanInCart">
            <div v-for="product in planOnlyProducts" v-bind:key="product.service">
              <input class="form-check-input" type="checkbox" :value="product.service" v-model="selectedProducts" :disabled="disableProduct(product)"/>
              <label class="form-check-label" :for="product.display_name">{{ product.display_name }}</label>
            </div>
          </div>
          <div v-if="!hasPlanInCart">
            <div v-for="product in products" v-bind:key="product.service">
              <input class="form-check-input" type="checkbox" :value="product.service" v-model="selectedProducts" :disabled="disableProduct(product)"/>
              <label class="form-check-label" :for="product.display_name">{{ product.display_name }}</label>
            </div>
          </div>
        </div>
        <div v-else>
          <span>First choose an entity</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col col-md-12">
        <ZgButton class="zg-btn-default fullwidth" :isEnable="isAllowed" :isSubmitting="isSubmitting" @click="createOrder">
          <template v-slot:title>
            Submit
          </template>
        </ZgButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import forEach from 'lodash/forEach';
import isNull from 'lodash/isNull';
import { US_JURISDICTIONS } from '@/assets/jurisdictions';
import ZgButton from '@/components/common/Generics/ZgButton.vue';
import {createInstance} from 'vuex-pagination'
import { sortingDataMixin } from '@/components/mixins';
import { ZB_MONEY_ORDER_TASK_TYPES } from '@/v2/zb-money/constants/zb-money-constants';

export default {
  components: { ZgButton },
  name: 'OrderCreateSelectItems',

  mixins: [sortingDataMixin],

  props: ['activeProductData'],

  computed: {
    ...mapState("orderCreate", [
      "annualReportEligibility",
      "jurisdiction",
      "plans",
      "products",
      "productPricing",
    ]),
    ...mapState({
      businessEntities: (state) => state.businessEntities.businessEntities,
      jurisdictions: (state) => state.businessEntities.jurisdictions,
      annualReportEligibility: (state) => state.orderCreate.annualReportEligibility,
      account: (state) => state.accounts.account,
    }),
    ...mapGetters({
      areValidAllProducts: 'orderCreate/getValidationAllProducts',
      getOrderTaskStatusArrayByType: 'orders/getOrderTaskStatusArrayByType',
      getOrderTasksByTypeArr: 'orders/getOrderTasksByTypeArr',
      getOrderTasksByType: 'orders/getOrderTasksByType',
      getAffiliateOrDefault: 'accounts/getAffiliateOrDefault',
    }),
    planOnlyProducts: {
      get() {
        const products = [];
        forEach(this.products, (value) => {
          if (value.service === 'plan') {
            products.push(value);
          }
        });
        return products;
      }
    },
    hasPlanInCart: {
      get() {
        return this.selectedProducts.includes('plan');
      }
    },
    selectedProducts: {
      get() {
        return this.$store.state.orderCreate.selectedProducts;
      },
      set(value) {
        const webToolsPRProducts = ['domain_name_reg','domain_name_privacy', 'basic_email']
        if (value === false) {
          this.$store.commit('orderCreate/setSelectedProducts', []);
        } else if (value.includes('plan')) {
          this.$store.commit('orderCreate/setSelectedProducts', []);
          this.$store.commit('orderCreate/setSelectedProducts', 'plan');
        }
        else if (value.includes('website_tools_bundle')) {
          value = [...new Set([...value, ...webToolsPRProducts])]
          this.$store.commit('orderCreate/setSelectedProducts', value);
        }
        else if (this.selectedProducts.includes('website_tools_bundle')) {
          const webToolsAndPRProducts = ['website_tools_bundle', ...webToolsPRProducts]
          value = this.selectedProducts.filter(product => !webToolsAndPRProducts.includes(product))
          this.$store.commit('orderCreate/setSelectedProducts', value);
        }
        else {
          this.$store.commit('orderCreate/setSelectedProducts', value);
        }
      }
    },
    businessEntityUUID: {
      get() {
        return this.$store.state.orderCreate.order.business_entity_uuid;
      },
      set(value) {
        this.$store.commit('orderCreate/setAccountUUID', value.account_uuid);
        this.$store.commit('orderCreate/setBusinessEntity', value);
        this.$store.commit('orderCreate/setBusinessEntityUUID', value.business_entity_uuid);
        this.$store.commit('orderCreate/setBusinessEntityName', value.business_entity_name);
        this.$store.commit('orderCreate/setBusinessEntityType', value.business_entity_type);
        this.entityDisplayName = value.business_entity_name;
        this.businessEntity = value;
        this.$store.dispatch('businessEntities/fetchBusinessEntity', {
          uuid: value.business_entity_uuid
        });
        this.$store.dispatch('businessEntities/fetchBusinessEntityCreditBalances', {
          businessEntityUuid: value.business_entity_uuid
        });
      }
    },
    jurisdiction: {
      get() {
        return this.$store.state.orderCreate.jurisdiction;
      },
      set(value) {
        this.checkAnnualReportEligibility({ entityType: this.businessEntity.business_entity_type, jurisdictionCode: value })
        this.entityJurisdiction = value;
        this.$store.commit('orderCreate/setJurisdiction', value);
      }
    },
    domesticJurisdiction() {
      const domesticJurisdiction = this.jurisdictions.find((jur) => jur.domestic_flag);
      return domesticJurisdiction ? domesticJurisdiction.jurisdiction_code : '';
    },
    allJurisdictions() {
      return US_JURISDICTIONS;
    },
    domainAvailable: {
      get() {
        return this.$store.state.orderCreate.domainAvailable;
      },
      set(value) {
        this.$store.commit('orderCreate/setdomainAvailable', value);
      }
    },
    hasSelectedAnnualReport() {
      return this.selectedProducts.includes('llc_annual_report') || this.selectedProducts.includes('corp_annual_report');
    },
    isAllowed() {
      return this.areValidAllProducts &&
             this.selectedProducts?this.selectedProducts.length>0:false &&
             (!this.hasSelectedAnnualReport || this.annualReportEligibility)
    },
    affiliatePricing: createInstance('affiliatePricing', {
      page: 1,
      pageSize: 999999,
      args() { return {} }
    }),
    sortedBusinessEntities() {
      return this.businessEntities.concat().sort(this.sorting('business_entity_name', false));
    },
  },

  methods: {
    ...mapActions('orderCreate', [
      'checkAnnualReportEligibility',
      'isNewBusinessEntity',
      'resetOrderData',
      'setBadOrderAlert',
      'clearBadOrderAlert',
      'clearProductFeesAndPrices',
    ]),
    createOrder() {
      let isValid = true;
      const that = this;
      this.clearBadOrderAlert();
      forEach(this.activeProductData, (value) => {
        if (isNull(value)) return; // continue

        if (value.order_item.price === null || value.order_item.fees === null) {
          isValid = false;
        }
        // These products need details or a description.
        const genericServices = ['dissolution', 'resignation', 'reinstatement', 'adjustment', 'other'];
        if (genericServices.includes(value.order_data.service)
          && value.order_data.service_description === null) {
          isValid = false;
        }
        // There's a required value on ny_publication
        if (value.order_data.service === 'ny_publication' && typeof value.order_item.fees !== 'number') {
          isValid = false;
        }
        // Domains must have something set as a primary to go through. Not yet handled by backend.
        if (value.order_data.service === 'domain_name_reg') {
          if (value.order_data.primary === null || value.order_data.primary === '') {
            isValid = false;
          }
          if (that.domainAvailable !== 'available') {
            isValid = false;
          }
        }

        // Add in affiliate pricing uuid
        // this is set ONLY for zb_money_pro at the moment, but should be easily expandable
          if (value.order_data.service === 'zb_money_pro' || value.order_data.service === 'zb_money_essential') {
            if (value.order_item.period === 0) {
              isValid = false;
            }
            // a skeleton to allow user-selectable affiliate when creating the order. may not be necessary
            const selected_affiliate = null  //value.affiliate?
            const orderAffiliate = selected_affiliate ? selected_affiliate : this.getAffiliateOrDefault;
            const periodToInterval = {
              1: 'annual',
              12: 'monthly'
            }
            let affiliates = this.affiliatePricing.items.filter(
              (a) => a.related_service_pricing.service === value.order_item.service
                && a.related_affiliate.name === orderAffiliate
                && a.related_service_pricing.interval === periodToInterval[value.order_item.period]
            )
            if (affiliates.length === 1) {
              value.order_item.affiliate_pricing_uuid = affiliates[0].uuid
            }
          }
      });
      if (isValid) {
        this.$store.dispatch('orderCreate/prepareOrder', { productData: this.activeProductData }).then(() => {
          this.$store.dispatch('orderCreate/createOrder').then(() => {
            this.doScrolling();
          });
        }).then(() => {
          this.resetOrderData();
          this.$store.commit('orderCreate/setdomainAvailable', null);
        });
      } else {
        this.setBadOrderAlert();
        this.doScrolling();
      }
    },
    clearEntityName() {
      this.businessEntity = null;
      this.entityDisplayName = null;
      this.clearEntityJurisdiction();
    },
    clearEntityJurisdiction() {
      this.entityJurisdiction = null;
      this.$store.commit('orderCreate/setJurisdiction', null);
      this.$store.commit('orderCreate/setSelectedProducts', []);
      this.clearProductFeesAndPrices();
    },
    doScrolling() {
      scroll({
        top: 0,
        behavior: 'smooth'
      });
    },
    disableProduct(product) {
      if (product.service.includes('llc_annual_report') || product.service.includes('corp_annual_report')) {
        return !this.annualReportEligibility
      }
      const activeMoneyOrders = this.getOrderTasksByTypeArr(ZB_MONEY_ORDER_TASK_TYPES);
      if (product.service.includes('zb_money_pro') &&
        this.getOrderTaskStatusArrayByType('zb_money_pro', ['active', 'incomplete'])) {
        return true
      }
      if (product.service.includes('zb_money_essential') && activeMoneyOrders?.length > 0) {
        return true
      }
      if(product.service.includes('zb_banking_preferred') && this.getOrderTasksByTypeArr(['zb_banking_preferred'])?.length>0)
      {
        return true
      }
      if (
        product.service === "ny_publication" &&
        !["GA", "NY", "PA"].includes(this.jurisdiction)
      ) {
        return true;
      }
      if ((product.service === 'domain_name_reg' ||
          product.service === 'static_website' ||
          product.service === 'domain_name_privacy' ||
          product.service === 'basic_email') &&
          this.selectedProducts.includes('website_tools_bundle')) {
            return true;
      }

      // Disable EIN if the is already a EIN order not canceled or if
      // there is no formation order
      const einOrders = this.getOrderTasksByType('ein_creation');
      const formationOrders = this.getOrderTasksByTypeArr(['corp_formation', 'llc_formation']);
      if(product.service === 'ein_creation' &&
        (einOrders.find((einOrder) => einOrder.order_task_status !== 'canceled') ||
        !formationOrders.find((formation) => formation.order_task_status !== 'canceled'))
      ) {
        return true;
      }

      return false
    },
  },

  data() {
    return {
      selected: false,
      entityDisplayName: null,
      businessEntity: null,
      entityJurisdiction: null,
      isSubmitting: false
    };
  },

  async beforeCreate() {
    this.$store.dispatch('orderCreate/fetchProducts');
    this.$store.dispatch('orderCreate/fetchProductPricing');
    this.$store.dispatch('orderCreate/fetchPlans');
    this.$store.dispatch('orderCreate/fetchCoupons');
  }

};
</script>

<style scoped>
  dt {
    font-size: 10pt;
    color: #a9a9a9;
  }
  .badge{
    margin-left: 5px;
    text-transform: lowercase;
  }
  .badge:hover{
    background-color: #bb2d3b;
  }

  .col{
    margin-top: 10px;
  }
</style>
