import { render, staticRenderFns } from "./repair-view.vue?vue&type=template&id=4a547788&scoped=true"
import script from "./repair-view.vue?vue&type=script&lang=js"
export * from "./repair-view.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a547788",
  null
  
)

export default component.exports