<template>
  <div>
    <div class="row heading">
      <div class="col">
        <h2>DBA Compliance</h2>
      </div>
      <div class="row">
        <div class="col">
          <p>There is no action in ZenGarden in support of this order task. </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { yesNoMixin } from '../../mixins';

export default {
  name: 'DbaComplianceView',
  mixins: [yesNoMixin],
  computed: {
    ...mapState('orders', ['order']),
    dbaCompliance() {
      return this.order.order_data.find((od) => od.service === 'dba_compliance');
    }
  }
};
</script>
<style lang="scss" scoped>
  dt {
    font-size: 10pt;
    color: #a9a9a9;
  }
</style>
