<template>
  <div>
    <h2 class="header">Plan Selection</h2>
    <div class="box">
      <div class="col-md-12">
        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Plan Type</label>
              <select class="form-control plan-name-cap" v-model="planService" id="plan-type">
                <option disabled>Please select one</option>
                  <optgroup label="Premium">
                    <option v-for="plan in plansByTier('premium', true)" v-bind:key="plan.key" v-bind:value="plan.key">{{plan.display}}</option>
                  </optgroup>
                  <optgroup label="Pro">
                    <option v-for="plan in plansByTier('pro', true)" v-bind:key="plan.key" v-bind:value="plan.key">{{ plan.display}}</option>
                  </optgroup>
                  <optgroup label="Starter">
                    <option v-for="plan in plansByTier('starter', true)" v-bind:key="plan.key" v-bind:value="plan.key">{{ plan.display}}</option>
                  </optgroup>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label>Pricing Plan</label>
              <select v-if="planService" v-model="period" class="form-control" id="plan-period">
                <option disabled>Please select one</option>
                <option value="1">Annual (${{ plans[planService]['llc']['prices']['1'] }}/year)</option>
                <option value="12" v-if="planService === 'starter_plan_v8' || planService === 'pro_plan_v11'">Monthly (${{ plans[planService]['llc']['prices']['12'] }}/month)</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="change-subscription" v-model="productData.setTrialEnd"/>
                <label class="form-check-label" for="change-subscription">Change Subscription Start Time</label>
              </div>
              <div class="form-text" v-if="productData.setTrialEnd">
                <datetime name="subscription"
                  :value-zone="datetimePicker.zone"
                  :zone="datetimePicker.zone"
                  v-model.trim="productData.trialEnd"
                  :format="datetimePicker.format"
                  :type="datetimePicker.type"
                  :input-id="datetimePicker.input_id"
                  :input-class="datetimePicker.input_class">
                </datetime>
              </div>
              <div v-if="planCoupon"  class="form-check">
                <input class="form-check-input" type="checkbox" id="waive-coupon" v-model="productData.order_item.waive_coupon"/>
                <label class="form-check-label" for="waive-coupon">Waive coupon ({{ planCoupon.external_coupon_id.replace(/_/g, ' ') }})</label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>

import { orderCreateProductMixin } from '@/components/mixins';
import { Datetime } from 'vue-datetime';

import 'vue-datetime/dist/vue-datetime.min.css'

export default {
  name: 'planCreate',

  props: ['jurisdiction', 'plans', 'supportedPlans', 'products', 'coupons'],

  components: {
    Datetime
  },

  mixins: [orderCreateProductMixin],

  data() {
    return {
      datetimePicker: {
        type: 'datetime',
        input_id: 'trial-end',
        input_class: "form-control",
        format: "yyyy-MM-dd HH:mm:ssZZ",
        zone: "local"
      },
      productData: {
        setTrialEnd: false,
        trialEnd: null,
        isPlan: true,
        order_data: {
          service: null,
          jurisdiction: this.jurisdiction,
          formation_jurisdiction: this.jurisdiction
        },
        order_item: {
          service: null,
          jurisdiction: this.jurisdiction,
          price: null,
          fees: 0,
          term: 12,
          period: null,
          waive_coupon: false
        }
      },
      pricing: null
    };
  },

  computed: {
    planCoupon() {
      if (this.planService) {
        const planProduct = this.products.find((p) => p.service === this.planService);
        return planProduct.default_coupon ? this.coupons.find((c) => c.coupon_uuid === planProduct.default_coupon) : undefined;
      }
      return undefined;
    },
    supportedPlansByType() {
      return Object.keys(this.plans).filter(key => this.supportedPlans.includes(key))
    }
  },

  methods: {
    formatPlanName(plan) {
      return plan.replaceAll('_', ' ')
    },
    plansByTier(tier='', sort=true) {
      let base = this.supportedPlansByType
      if (tier) {
        base = this.supportedPlansByType.filter(plan => plan.includes(tier))
      }

      if (sort) {
        const byType = {}
        base.forEach((plan) => {
          let splitPlan = plan.split('_')
          splitPlan.reverse()
          let planType = ''
          let part = splitPlan.pop()
          while (part && part.toLowerCase() !== 'plan') {
            planType = planType + part
            part = splitPlan.pop()
          }
          if (!(planType in byType)) {byType[planType] = []}
          byType[planType].push(plan)
        })

        let sortedPlans = []
        const planTypes = Object.keys(byType).sort()
        planTypes.forEach((t) => {
          let sorted = byType[t].sort((plan1, plan2) => {
            const version1 = Number(plan1.split('_v')[1])
            const version2 = Number(plan2.split('_v')[1])
            if (version1 <= version2) { return -1}
            return 1;
          })
          sorted.reverse()
          sortedPlans.push(...sorted)
        })
        base = sortedPlans
      }
      return base.map((plan) => {
        return {
          'key': plan,
          'display': this.formatPlanName(plan)
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>

.plan-name-cap {
  text-transform: capitalize;
}

</style>
