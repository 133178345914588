<template>
  <div>
    <h2 class="header">Order Notes</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <table class="table table-sm table-borderless">
            <thead>
            <tr>
              <th>Created By</th>
              <th>Created Date</th>
              <th>Note</th>
            </tr>
            </thead>
            <tbody>
            <OrderNoteListItem v-for="note in order.order_notes" v-bind:key="note.order_note_created_datetime" :note="note"></OrderNoteListItem>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import OrderNoteListItem from '@/components/orders/order-note-list_item.vue';

export default {
  name: 'OrderNotesView',

  computed: mapState('orders', ['order']),

  components: {
    OrderNoteListItem
  }
};
</script>

<style scoped>

</style>
