<template>
  <transition name="fade" appear>
    <div class="automation mb-2 mt-2" :class="statusClass">
      <div class="automation__icon" v-if="isAutomationRunning"><icon-spinner /></div>
      <div class="automation__item">
        <strong>Automated Filing Status: </strong> 
        <em v-if="getAutomatedFilingStatus(isTest).status">{{ makeSentenceCase(getAutomatedFilingStatus(isTest).status) }}</em>
        <em v-else>Not started</em>
      </div>
      <div class="automation__item" v-if="getAutomatedFilingStatus(isTest).subStatus">
        <strong>Sub Status: </strong> <em>{{ makeSentenceCase(getAutomatedFilingStatus(isTest).subStatus) }}</em>
      </div>
      <div class="automation__item" v-if="getAutomatedFilingStatus(isTest).type">
        <strong>Type:</strong> <em>{{ makeSentenceCase(getAutomatedFilingStatus(isTest).type) }}</em>
      </div>
      <div class="automation__item" v-if="getAutomatedFilingStatus(isTest).numTries">
        <strong>Attempt: </strong> <em>{{ getAutomatedFilingStatus(isTest).numTries }}</em>
      </div>
      <div class="automation__item automation__item--last" v-if="getAutomatedFilingStatus(isTest).test">
        <em>Test</em>
      </div>
    </div>
  </transition>
</template>

<script>
import { AUTOMATION_STATUSES } from '@/assets/constants/automations'
import { makeSentenceCase } from '@/assets/utils/text-utils';
import { mapActions, mapGetters } from 'vuex'
import IconSpinner from '@/components/common/images/IconSpinner'

const failureStatueses = [...AUTOMATION_STATUSES.failureStatuses, ...AUTOMATION_STATUSES.retryStatuses]
const retryStatuses = AUTOMATION_STATUSES.retryStatuses
const runningStatuses = [...AUTOMATION_STATUSES.initialStatuses, ...AUTOMATION_STATUSES.runningStatuses]
const successStatuses = AUTOMATION_STATUSES.successStatuses

export default {
  name: 'AutomatedFilingStatusIndicator',
  props: ['orderUuid', 'isTest'],
  components: {
    IconSpinner
  },
  data () {
    return {
      initialAttemptCount: 0,
      intervalCounter: 0,
      isAutomationRunning: false,
      intervalUpdater: null
    }
  },
  computed: {
    ...mapGetters('orders', ['getAutomatedFilingStatus']),
    statusClass () {
      let className = 'is-base'
      if (successStatuses.includes(this.getAutomatedFilingStatus(this.isTest).status)) {
        className = 'is-success'
      } else if (runningStatuses.includes(this.getAutomatedFilingStatus(this.isTest).status)) {
        className = 'is-warning'
      } else if (failureStatueses.includes(this.getAutomatedFilingStatus(this.isTest).status)) {
        className = 'is-danger'
      }
      return className 
    }
  },
  methods: {
    ...mapActions('orders', ['fetchSOSStatus']),
    makeSentenceCase,
    cleanupIntervalChecker () {
      if (this.intervalUpdater && 
          (this.intervalCounter > 89 || this.getAutomatedFilingStatus(this.isTest).completedDatetime
            || successStatuses.includes(this.getAutomatedFilingStatus(this.isTest).status))) {
        /* stop checker after 20 minutes or if complete */
        this.destroyIntervalChecker()
      }

      if (this.getAutomatedFilingStatus(this.isTest).numTry <= this.initialAttemptCount + 1 &&
          retryStatuses.includes(this.getAutomatedFilingStatus(this.isTest).status)) {
        /* retry if second attempt */
        this.initIntervalChecker()
      }
    },
    destroyIntervalChecker () {
      clearInterval(this.intervalUpdater)
      this.isAutomationRunning = false
    },
    initIntervalChecker () {
      this.isAutomationRunning = true
      this.intervalCounter = 0
      this.intervalUpdater = setInterval(() => {
        this.updateAutomatedFilingStatus()
        this.intervalCounter++
      }, 20000)
    },
    async updateAutomatedFilingStatus () {
      await this.fetchSOSStatus({ uuid: this.orderUuid })
    },
  },
  async mounted () {
    await this.updateAutomatedFilingStatus()
    this.initialAttemptCount = this.getAutomatedFilingStatus(this.isTest).numTries
    if (this.getAutomatedFilingStatus(this.isTest).status 
        && !this.getAutomatedFilingStatus(this.isTest).completedDatetime
        && !successStatuses.includes(this.getAutomatedFilingStatus(this.isTest).status)) {
      this.initIntervalChecker()
    }
  },
  updated () {
    this.cleanupIntervalChecker()
  },
  beforeDestroy () {
    this.destroyIntervalChecker()
  }
}
</script>

<style lang="scss" scoped>
.automation {
  $a: &;
  color: $clr-font-primary;
  font-size: rem(13.3333);
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 $clr-shadow;
  display: flex;
  padding: em(4, 13.333) em(6, 13.333) em(3, 13.333);
  #{$a}__icon {
    position: relative;
  }
  &.is {
    &-base {
      border: 1px solid $stone-4;
    }
    &-danger {
      color: $clr-white;
      background: $clr-danger;
      border: 1px solid $clr-danger;
      #{$a}__icon {
        color: $clr-white;
      }
    }
    &-success {
      background: $clr-success;
      border: 2px solid $clr-success;
      #{$a}__icon {
        color: $clr-white;
      }
    }
    &-warning {
      background: $clr-warning;
      #{$a}__icon {
        color: $clr-font-primary;
      }
    }
  }
  &__icon {
    margin-top: -3px;
  }
  &__item {
    margin-left: 5px;
    margin-right: 5px;
    &--last {
      margin-left: auto;
    }
  }
}
.fade {
  &-enter-active {
    transition: background-color 11000ms $easeOutExpo, color 400ms ease 300ms;
  }
  &-leave {
    transition: background-color 9000ms $easeInExpo, color 300ms ease;
  }
}
</style>