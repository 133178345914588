
<template>
  <div>
    <div class="row">
      <div class="col">
        <form>
          <label for="shared_ageny_login_accounts">Select Shared Agency Login Accounts</label>
          <multiselect 
            v-model="updatedSharedAgencyAccountLogins"
            name="shared_ageny_login_accounts"
            label="username"
            placeholder="Select Shared Agency Login Accounts"
            track-by="uuid"
            :close-on-select="false"
            :clear-on-select="false"
            :hide-selected="true"
            :multiple="true"
            :options="allAgencyLoginAccounts('sos')">
            <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} options selected</span></template>
          </multiselect>
          <ZgButton class="zg-btn-default zg-text-sm" @click="saveSharedAgencyLoginAccounts()" type="button">
            <template v-slot:title><i class="fa fa-save"></i>&nbsp;Save</template>
          </ZgButton>        
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h6 class="my-2">Shared Agency Login Accounts</h6>
        <ul>
          <li v-for="ala in sharedAgencyLoginAccounts" :key="ala.agency_login_account_uuid" >{{ala.username}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import ZgButton from '../../common/Generics/ZgButton.vue'

export default {
  name: 'SharedAgencyLoginAccounts',
  components: {
    Multiselect,
    ZgButton
  },
  props: {
    jurisdiction: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      updatedSharedAgencyAccountLogins: []
    }
  },
  computed: {
    ...mapGetters({
      allAgencyLoginAccounts: 'fulfillment/getAllAgencyLoginAccounts',
      sharedAgencyLoginAccounts: 'fulfillment/getSharedAgencyLoginAccounts'
    })
  },

  methods: {
    ...mapActions({
      addOrRemoveSharedAgencyLoginAccounts: 'fulfillment/addOrRemoveSharedAgencyLoginAccounts',
      fetchSharedAgencyLoginAccounts: 'fulfillment/fetchSharedAgencyLoginAccounts'
    }),
    loadInformation() {
      this.fetchSharedAgencyLoginAccounts({ jurisdictionUuid: this.jurisdiction.jurisdiction_uuid })
      .then( response => {
        const normalized = response.map( r => {
          return {
            username: r.username,
            uuid: r.agency_login_account_uuid
          }
        })
        this.updatedSharedAgencyAccountLogins = normalized
      })
    },
    saveSharedAgencyLoginAccounts() {
      const selectedALAS = new Set(this.updatedSharedAgencyAccountLogins.map(element => element.uuid));
      const currentALAS = new Set(this.sharedAgencyLoginAccounts.map(element => element.agency_login_account_uuid));
      const allALAS = new Set([...selectedALAS, ...currentALAS]);
      const addALAS = [];
      const removeALAS = [];
      [...allALAS].map((account) => {
        if(selectedALAS.has(account) && !currentALAS.has(account)) {
          addALAS.push(account);
        } else if(!selectedALAS.has(account) && currentALAS.has(account)) {
          removeALAS.push(account);
        }
      });
      this.addOrRemoveSharedAgencyLoginAccounts({
        addUuids: addALAS,
        removeUuids: removeALAS,
        jurisdictionUuid: this.jurisdiction.jurisdiction_uuid
      }).finally(() => {
        this.loadInformation();
      });
    },
  },
  
  created () {
    this.loadInformation();
  }

};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style lang="scss" scoped>
  h6 {
    color: $clr-nav-primary;
    font-weight: bold;
    font-size: .875em;
  }
  label {
    color: $clr-nav-primary;
    font-weight: bold;
    font-size: .875em;
    grid-column: 1/2;
    grid-row: 1;
  }
  .multiselect {
    grid-column: 1;
    grid-row: 2;
  }
  .zg-btn {
    grid-column: 2;
    grid-row: 2;
    max-height: 3.25em;
  }
  form {
    display: grid;
    grid-template-columns: 85% 15%;
    grid-template-rows: 1fr auto;
    grid-row-gap: 0.5em;
    margin-left: 0.25em;
  }
</style>

