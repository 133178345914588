<template>
  <div>
    <h2 class="header">S Corp Designation - {{transOrRecurring}}</h2>
    <div class="box">
      <div class="col-md-12">
        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="price">ZenBusiness Price</label>
              <zg-numeric-input class="form-control" placeholder=200 name="Price"
                id="price"
                label="price"
                v-model="productData.order_item.price"  />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import ZgNumericInput from '@/components/common/Generics/ZgNumericInput.vue';
import { orderCreateProductMixin } from '@/components/mixins';

export default {
  components: { ZgNumericInput },
  name: 'sCorpDesignationCreate',

  props: ['pricing', 'jurisdiction'],

  mixins: [orderCreateProductMixin],

  data() {
    return {
      productData: {
        order_data: {
          service: 's_corp_designation',
          jurisdiction: this.jurisdiction,
          formation_jurisdiction: this.jurisdiction
        },
        order_item: {
          service: 's_corp_designation',
          jurisdiction: this.jurisdiction,
          price: this.pricing.price,
          fees: this.pricing.fees
        }
      }
    };
  }
};
</script>
