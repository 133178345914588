<template>
  <button type="button" class="btn btn-warning top2" @click="handleClick">
    <slot name="title">{{ buttonTitle }}</slot>
  </button>
</template>

<script>
export default {
  name: 'UpdateAddressOrContactButton',
  props: ['entity', 'subEntity', 'recordType', 'recordUuid'],
  data() {
    return {
      buttonDataParam: {
        entity: this.entity,
        subEntity: this.subEntity,
        recordUuid: this.recordUuid,
        recordType: this.recordType,
      },
    };
  },
  computed: {
    buttonTitle() {
        return `Update ${this.subEntity}`;
    }
  },
  methods: {
    handleClick() {
      this.$emit('openJourneyModal', this.buttonDataParam);
    },
  },
};
</script>

<style lang="scss" scoped>
  .top2{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
</style>
