<template>
  <div>
    <ZgButton class="zg-btn-default zg-text-md my-1"
      v-if="annualReportOrderTask && onProd && isAutomationAdmin"
      :disabled="isAnnualReportBtnDisabled"
      @click="handleAutomatedReportAsTestClick"
    >
      <template v-slot:title>
        <i class="fa fa-user-lock" :content="`${getAdminRetryMessage}`" v-tippy v-if="isAutomationAdmin"/>
        Start Automated Annual Report AS TEST
      </template>
    </ZgButton>
    <ZgButton class="zg-btn-default zg-text-md my-1"
      v-if="annualReportOrderTask && isAutomationAdminOrProcessor"
      :disabled="isAnnualReportBtnDisabled || isAutomatedFilingSuccessful"
      @click="handleAutomatedReportClick">
      <template v-slot:title>
        <i class="fa fa-user-lock" :content="`${getAdminRetryMessage}`" v-tippy v-if="isAutomationAdmin" />
        Start Automated Annual Report
      </template>
    </ZgButton>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'
import ZgButton from '../common/Generics/ZgButton.vue';
import { hasPermissionMixin, automatedFilingMixin, orderMixin } from '@/components/mixins';
import {getClient} from '../../client/ldClient';
import { tx2024ChangesEnabled } from '@/assets/utils/feature-utils.js'

export default {
  name: 'AnnualReportAutomationAction',

  props: ['annualReportOrderTask', 'automatedFiling'],

  components: {
    ZgButton,
  },

  mixins: [
    hasPermissionMixin,
    automatedFilingMixin,
    orderMixin
  ],

  data() {
    return {
      featureFlagClient: null,
      isTxChangesEnabled: false
    };
  },

  computed: {
    ...mapState({
      complianceEvents: (state) => {
        return state.businessEntities.complianceEvents
      },
    }),
    ...mapGetters('orders', ['getRefundReasons']),
    ...mapGetters('automation', ['getAutomationMessages']),
    ...mapGetters("businessEntities", ["getContacts"]),
    getAdminRetryMessage() {
      return this.adminRetryMessage;
    },
    canAutomateReport() {
      return (
        (this.hasService("llc_annual_report") &&
          this.hasAutomatedPermission("llc_annual_report")) ||
        (this.hasService("corp_annual_report") &&
          this.hasAutomatedPermission("corp_annual_report"))
      );
    },
    isAnnualReportBtnDisabled() {
      const LLCMessages = this.getAutomationMessages(
        this.annualReportOrderTask,
        this.getContacts
      );
      const CorpMessages = this.getAutomationMessages(
        this.annualReportOrderTask,
        this.getContacts
      );
      return (LLCMessages && LLCMessages.length > 0) || (CorpMessages && CorpMessages.length > 0);
    },
    isAutomatedFilingSuccessful() {
      return Array.isArray(this.automatedFiling)
        && this.automatedFiling.length > 0
        && this.automatedFiling.some((a) => a.status === 'sos_filing_successful' && a.is_test === false);
    }
  },

  methods: {
    ...mapActions({
      startAutomatedFiling: 'orders/startAutomatedFiling',
      fetchAutomationPayloadToValidate: 'automation/fetchAutomationPayloadToValidate',
    }),
    handleAutomatedReportClick() {
      const uuid = this.annualReportOrderTask?.order_task_uuid;
      this.startAutomatedFiling({ uuid, test: false, reloadPage: true });
    },
    handleAutomatedReportAsTestClick() {
      const uuid = this.annualReportOrderTask?.order_task_uuid;
      this.startAutomatedFiling({ uuid, test: true, reloadPage: true });
    },
  },
  created() {
    if(this.annualReportOrderTask) {
      this.fetchAutomationPayloadToValidate(this.annualReportOrderTask);
    }
  },
  async beforeMount() {
    await this.$store.dispatch('businessEntities/fetchComplianceEventsByEntity', { businessEntityUuid: this.annualReportOrderTask.business_entity_uuid });
    this.featureFlagClient = await getClient();
    this.isTxChangesEnabled = tx2024ChangesEnabled(this.complianceEvents, this.annualReportOrderTask, this.featureFlagClient)
  }
};
</script>

<style lang="scss" scoped>
  .zg-btn-default {
    width: 100%;
    margin-top: 0.625rem !important;
    margin-bottom: 0rem !important;
  }
</style>
