<template>
  <div>
    <div class="menu menu-h50perc">
      <h2>Create Order</h2>
      <order-create-select-items :activeProductData="activeProductData" />
    </div>
    <div class="menu">
      <h2>Active Services</h2><br/>
      <div v-for="entity in businessEntities" v-bind:key="entity.business_entity_uuid">
        <dt>{{ entity.business_entity_name}}</dt>
        <h3 v-if="entity.has_ra">Registered Agent</h3>
        <h3 v-if="entity.has_wfg">Worry Free Guarantee</h3><br/>
      </div>
      <h3></h3>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import OrderCreateSelectItems from './order-create-select-items';

export default {
  name: 'OrderCreateSidebar',

  props: ['activeProductData'],

  components: {
    OrderCreateSelectItems
  },

  computed: {
    ...mapState({
      account: (state) => state.accounts.account,
      businessEntities: (state) => state.businessEntities.businessEntities
    })
  }
};
</script>

<style scoped>
  h3 {
    font-size: 10pt;
    margin: 5px 0 0 0;
  }
  h2 {
    font-size: 15pt;
    margin: 5px 0 0 0;
  }
  .menu {
    border: solid 1px #dee2e6;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    box-shadow: 3px 3px 3px 0 #f8f8f8;
  }
  dt {
    font-size: 10pt;
    color: #a9a9a9;
  }
  .menu-h50perc {
    height: 50%
  }
</style>
