<template>
  <div>
    <h2 class="header">Personal Credit Monitoring</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <dt>Account UUID</dt>
          <dd>{{ businessEntityUuid }}</dd>
        </div>
        <div class="col">
          <dt>Experian Subscriber ID</dt>
          <dd>{{ experianSubscriber }}</dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Security Questions</dt>
          <dd>{{ answeredQuestions }}</dd>
        </div>
        <div class="col">
          <dt>Locked Out At</dt>
          <dd>{{ lockedOutAt }}</dd>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PersonalCreditMonitoringServiceView',
  props: ['orderTask'],
  components: {
  },
  computed: {
    ...mapGetters('creditMonitoring', [
      'experianSubscriber',
      "answeredQuestions",
      "lockedOutAt"
    ]),
    businessEntityUuid() {
      return this.orderTask.business_entity_uuid ? this.orderTask.business_entity_uuid : "N/A";
    }
  },
  methods: {
    ...mapActions({
      fetchPersonalCreditMonitoring: 'creditMonitoring/fetchPersonalCreditMonitoring',
    })
  },
  created() {
    this.fetchPersonalCreditMonitoring({ uuid: this.businessEntityUuid });
  }
};
</script>

<style lang="scss" scoped>
</style>
