<template>
  <div>
    <h2 class="header">Standard Good Standing</h2>
    <div class="box">
      <div class="row">
        <div class="col" v-if="standard_good_standing">
          <dt>Entity Name</dt>
          <dd>
            {{ standard_good_standing.business_entity_name }} {{ standard_good_standing.business_entity_type }}
          </dd>
          <dt>Jurisdiction For Good Standing</dt>
          <dd>{{ standard_good_standing.jurisdiction }}</dd>
          <dt>Domestic Jurisdiction</dt>
          <dd>{{ standard_good_standing.formation_jurisdiction }}</dd>
        </div>
      </div>
    </div>
    <automated-filing v-if="isSupportedByAutomation" :jurisdiction="jurisdictionCode" :automatedFiling="getAutomatedFiling(false)"
      :orderUuid="getUuidOrder" :filingType="'standard_good_standing'" :isTest="false"></automated-filing>
    <automated-filing v-if="isSupportedByAutomation" :jurisdiction="jurisdictionCode" :automatedFiling="getAutomatedFiling(true)"
      :orderUuid="getUuidOrder" :isTest="true"></automated-filing>
    <OrderTaskFulfillment v-if="isSupportedByAutomation"
          :business_entity_uuid="businessEntityUuid"
          :order_task_status="orderTaskStatus" 
          :jurisdiction_code="jurisdictionCode"/>
  </div>
</template>

<script>
import AutomatedFiling from '../../common/automated-filing';
import OrderTaskFulfillment from './order-task-fulfillment.vue'
import { mapGetters } from 'vuex';
export default {
  name: 'StandardGoodStandingView',
  components: {
    AutomatedFiling,
    OrderTaskFulfillment
  },
  props: ['orderTask'],
  computed: {
    ...mapGetters({
      getAutomatedFiling: 'orders/getAutomatedFiling',
      getUuidOrder: 'orders/getUuidOrder',
    }),
    standard_good_standing() {
      return this.orderTask.order_task_data;
    },
    businessEntityUuid() {
      return this.orderTask.business_entity_uuid;
    },
    businessEntityType() {
      return this.standard_good_standing.business_entity_type
    },
    jurisdictionCode() {
      return this.orderTask.order_task_jurisdiction;
    },
    goodStanding() {
      if (!this.orderTask || !this.orderTask.order_task_data) {
        return '';
      }
      return this.orderTask.order_task_data;
    },
    orderTaskStatus() {
      return this.orderTask.order_task_status;
    },
    orderTaskType() {
      return this.orderTask.order_task_type;
    },
    isSupportedByAutomation() {
      // Which pattern should we implement to grow the validation/array 
      // Of the automations that are currently supported by us, by SH or redirected via a feature flag
      const supportedJurisdictions = ['CA'];
      const isJurisdictionSupported = supportedJurisdictions.includes(this.jurisdictionCode);
      const isEntityTypeSupported = this.businessEntityType === 'LLC';
      return isJurisdictionSupported && isEntityTypeSupported;
    },
  }
};
</script>

<style lang="scss" scoped></style>
