<template>
  <div>
    <h1 class="heading">Basic Email</h1>
    <h2 class="header">Details</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <dt>User Chose to Skip Email</dt>
          <dd v-if="!editing">{{ basicEmail.skip | yesno }}</dd>
          <div v-else class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="skipEmail" v-model="basicEmail.skip" />
          </div>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col">
          <dt>
            Email Address
            <i class="fa fa-info-circle" :content="'This field only requires username'" v-tippy />
          </dt>
          <dd v-if="!editing">{{basicEmail.email_address ? basicEmail.email_address : "No address given"}}</dd>
          <input type="email" v-else v-model="basicEmail.email_address" class="form-control" />
        </div>
        <div class="col">
          <dt>Domain Name</dt>
          <dd>{{ emailDomainName }}</dd>
        </div>
      </div>
      <br />
      <div>
        <button class="btn btn-info btn-sm" v-if="!editing" @click="handleEditClick" >Edit</button>
        <button class="btn btn-success btn-sm" v-if="editing" @click="handleSaveClick">Save</button>
        <button class="btn btn-danger btn-sm" v-if="editing" @click="handleCancelEditClick">Cancel</button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { yesNoMixin } from '@/components/mixins';

export default {
  name: 'BasicEmailView',
  props: ['orderTask', 'edit'],
  data() {
    return {
      editing: this.edit
    };
  },
  mixins: [yesNoMixin],
  computed: {
    ...mapState('orders', ['order']),
    basicEmail() {
      return this.orderTask.order_task_data;
    },
    emailDomainName() {
      const basicEmail = this.orderTask.order_task_data;
      if (basicEmail.full_email_address) {
        return basicEmail.full_email_address.substring(basicEmail.full_email_address.lastIndexOf('@') + 1);
      }
      return basicEmail.domain_name ? basicEmail.domain_name : 'See Domain Order';
    }
  },
  methods: {
    handleSaveClick() {
      this.editing = false;
      this.$store.dispatch('orders/updateOrderTask', { orderTask: this.orderTask });
    },
    handleCancelEditClick() {
      this.editing = false;
    },
    handleEditClick() {
      this.editing = true;
    }
  }
};
</script>
<style lang="scss" scoped>
  dt {
    font-size: 10pt;
    color: #a9a9a9;
  }
</style>
