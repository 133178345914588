<template>
  <div class="modal" v-if="partnerRequestDomains.length">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="getCurrentDomain.domain">Exchange partner request for domain <b>{{ getCurrentDomain.domain.domain_name }} - {{ getCurrentDomain.domain.active ? 'Active' : 'Inactive' }}</b> with</h5>
          <h5 v-else>This partner request has no domain record</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">
          <div v-for="remainingDomain in getRemainingDomains" v-bind:key="remainingDomain.domain.domain_uuid" class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios"
              :id="'domain_' + remainingDomain.domain.domain_name"
              :value="remainingDomain.partner_request_uuid"
              v-model="selectedPartnerRequestUUID">
            <label class="form-check-label" :for="'domain_' + remainingDomain.domain.domain_name">
              <h6>{{ remainingDomain.domain.domain_name }} - {{ remainingDomain.domain.active ? 'Active' : 'Inactive' }}</h6>
            </label>
          </div>
          <h6 class="text-center" v-if="!getRemainingDomains.length">No available domains to exchange</h6>
          <p class="mt-2">This domain will be exchanged with the Order task of the selected domain</p>
        </div>
        <div class="modal-footer">
          <button v-if="!confirmedExchange"
            :disabled="partnerRequest.partner_request_uuid === selectedPartnerRequestUUID"
            class="btn btn-warning action" @click="confirmedExchange = true">Exchange
          </button>
          <div v-if="confirmedExchange" class="btn btn-secondary action" @click="$emit('close')">Cancel</div>
          <div v-if="confirmedExchange" class="btn btn-warning action" @click="handleExchangePartnerRequestDomain">Are you sure?</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ExchangePartnerRequestModal',

  props: ['partnerRequest', 'businessEntity', 'partnerRequestDomains', 'uuid'],

  data() {
    return {
      selectedPartnerRequestUUID: this.partnerRequest.partner_request_uuid,
      confirmedExchange: false
    };
  },

  computed: {
    getCurrentDomain() {
      return this.partnerRequestDomains.find((partnerRequestDomain) => partnerRequestDomain.partner_request_uuid === this.partnerRequest.partner_request_uuid);
    },
    getRemainingDomains() {
      return this.partnerRequestDomains.filter((partnerRequestDomain) => partnerRequestDomain.partner_request_uuid !== this.partnerRequest.partner_request_uuid && partnerRequestDomain.domain);
    }
  },

  methods: {
    handleExchangePartnerRequestDomain() {
      if (this.partnerRequest.partner_request_uuid !== this.selectedPartnerRequestUUID) {
        this.$store.dispatch('partnerRequest/processExchangePartnerRequest',
          { partnerRequestUUID1: this.partnerRequest.partner_request_uuid, partnerRequestUUID2: this.selectedPartnerRequestUUID }).then(() => {
          this.$store.dispatch('partnerRequest/fetchPartnerRequests', { uuid: this.uuid });
        });
      }
      this.$emit('close');
    }
  },

  mounted() {
    this.$store.dispatch('partnerRequest/fetchPartnerRequestsDomain', { uuid: this.businessEntity.business_entity_uuid });
  }
};
</script>

<style lang="scss" scoped>
  .badge {
    text-transform: capitalize;
  }
</style>
