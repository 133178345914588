import { render, staticRenderFns } from "./ReasonCollector.vue?vue&type=template&id=81c75946&scoped=true"
import script from "./ReasonCollector.vue?vue&type=script&lang=js"
export * from "./ReasonCollector.vue?vue&type=script&lang=js"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./ReasonCollector.vue?vue&type=style&index=1&id=81c75946&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81c75946",
  null
  
)

export default component.exports