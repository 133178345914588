<template>
  <article v-if="messages && Array.isArray(messages) && messages.length > 0">
    <div v-for="(msg, key) in messages" :key="key">
      <template v-if="typeof(msg) === 'object'">
        <p>{{ msg.main }}</p>
        <ul>
          <li v-for="(subMessage, subKey) in msg.items" :key="subKey">{{ subMessage }}</li>
        </ul>
        <p v-if="msg.secondary">{{ msg.secondary }}</p>
      </template>
      <template v-else>
        <p>{{ msg }}</p>
      </template>
    </div>
  </article>
</template>

<script>
export default {
  name: 'ReportValidationMessages',
  props: ['messages'],
  data() {
    return  {};
  },
}
</script>

<style scoped>
  p {
    margin-bottom: revert;
  }
</style>