<template>
  <div>
    <h2 class="header">{{ny_publication.domestic_jurisdiction}} Publication</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <dt>Publication County</dt>
          <dd>
            {{ ny_publication.ny_publication_county }}
          </dd>
        </div>
        <div class="col">
          <dt>Domestic Jurisdiction</dt>
          <dd>
            {{ ny_publication.domestic_jurisdiction }}<br/>
          </dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Domestic Filing Date</dt>
          <dd>{{ ny_publication.domestic_filing_date }}</dd>
        </div>
        <div class="col">
          <dt>Foreign Jurisdictions</dt>
          <dd>
            <span v-for="jurisdiction in ny_publication.foreign_jurisdictions" v-bind:key="jurisdiction">{{ jurisdiction }}<br/></span>
          </dd>
        </div>
      </div>
    </div>

    <h2 v-if="ny_publication.contact" class="header">Contact</h2>
    <div v-if="ny_publication.contact" class="box">
      <div class="row">
        <div class="col">
          <person :person="ny_publication.contact"></person>
        </div>
      </div>
    </div>
    <h2 v-if="ny_publication.members" class="header">Management Structure</h2>
    <div v-if="ny_publication.members" class="box">
      <div class="row">
        <div class="col">
          <person v-for="member in ny_publication.members" :person="member" v-bind:key="member.email"></person>
          <br/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Person from '../../common/person';

export default {
  name: 'PublicationView',

  components: {
    Person
  },

  props: ['orderTask'],

  computed: {
    ny_publication() {
      return this.orderTask.order_task_data;
    }
  }

};
</script>

<style lang="scss" scoped>

</style>
