<template>
  <div>
    <h2 class="header">Addresses</h2>
    <div class="box">
      <div class="row">
        <div class="col-6" v-for="address in addresses" v-bind:key="address.displayName">
          <dt>{{address.displayName}}</dt>
          <Person v-if="address.address" :person="address.address" :address-type="getAddressType(address)" />
          <div v-else>None on file.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Person from './person'

export default {
  name: 'MultiAddressBlock',
  props: ['addresses'],
  components: {
    Person
  },
  computed: {

  },
  methods: {
    getAddressType(address) {
      return address.displayName.toLowerCase().includes('virtual') ? 'virtual' : ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
