<template>
  <div>
    <vue-headful :title="pageTitle"></vue-headful>
    <div class="row heading">
      <div class="col-md-3">
        <OrderSideBar
          :uuid="uuid" />
      </div>
      <div class="col-md-9">
        <div class="row">
          <div class="col-md-12">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <button class="nav-link btn btn-link" v-bind:class="isTasks" @click="handleTasksClick">Tasks</button>
              </li>
              <li class="nav-item" v-if="!isUnisearch">
                <button class="nav-link btn btn-link" v-bind:class="isBilling" @click="handleBillingClick">Billing</button>
              </li>
              <li class="nav-item" v-if="!isUnisearch">
                <button class="nav-link btn btn-link" v-bind:class="isNotes" @click="handleNotesClick">Notes</button>
              </li>
              <li class="nav-item">
                <button class="nav-link btn btn-link" v-bind:class="isRaw" @click="handleRawClick">Raw Data</button>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import OrderSideBar from './order-side-bar'
import { hasPermissionMixin, automatedFilingMixin } from '@/components/mixins'

export default {
  name: 'Order',

  components: {
    OrderSideBar,
  },

  mixins: [
    hasPermissionMixin,
    automatedFilingMixin
  ],

  props: ['uuid'],

  data() {
    return {
      view: 'tasks'
    };
  },

  computed: {
    ...mapState({
      order: state => state.orders.order,
      businessEntity: state => state.orders.businessEntity,
      orderTasks: state => state.orders.orderTasks,
      account: state => state.accounts.account,
    }),
    ...mapGetters({
      getAutomatedFiling: 'orders/getAutomatedFiling',
      getOrderTracking: 'orders/getOrderTracking'
    }),
    isTasks() {
      return {
        active: this.view === 'tasks'
      };
    },
    isBilling() {
      return {
        active: this.view === 'billing'
      };
    },
    isNotes() {
      return {
        active: this.view === 'notes'
      };
    },
    isRaw() {
      return {
        active: this.view === 'raw'
      };
    },
    pageTitle() {
      return `Order - ${this.order.order_entity_name || this.order.order_uuid}`;
    },
    canAccessRawData() {
      return this.hasPermission('access:developers');
    },
    isUnisearch () {
      return this.hasPermission('partner:unisearch')
    }
  },

  methods: {
    ...mapActions('orders', ['fetchSOSStatus', 'fetchOrderTracking']),
    handleTasksClick () {
      if (this.$router.history.current.name !== 'OrderTasksView') {
        this.view = 'tasks';
        this.$router.push({ name: 'OrderTasksView', params: { uuid: this.uuid } });
      }
    },
    handleBillingClick() {
      if (this.$router.history.current.name !== 'OrderBillingView') {
        this.view = 'billing';
        this.$router.push({ name: 'OrderBillingView', params: { uuid: this.uuid } });
      }
    },
    handleNotesClick() {
      if (this.$router.history.current.name !== 'OrderNotesView') {
        this.view = 'notes';
        this.$router.push({ name: 'OrderNotesView', params: { uuid: this.uuid } });
      }
    },
    handleRawClick() {
      if (this.$router.history.current.name !== 'OrderRawView') {
        this.view = 'raw';
        this.$router.push({ name: 'OrderRawView', params: { uuid: this.uuid } });
      }
    },
  },

  created() {
    this.$store.dispatch('orders/fetchOrder', { uuid: this.uuid }).then(() => {
      this.$store.dispatch('accounts/fetchAccount', { accountUuid: this.order.account_uuid });
    });
    this.fetchOrderTracking({ uuid: this.uuid });
    this.$store.dispatch('orders/fetchOrderTasks', { uuid: this.uuid }).then((orderTasks) => {
      const blr = orderTasks?.find((ot) => ot.order_task_type === 'business_license_report');
      if (blr) {
        this.$store.dispatch('orders/fetchBusinessLicenseReport', {
          uuid: blr.business_entity_uuid
        });
      }
    })
    this.$store.commit('partnerRequest/loadingPartnerRequests', true)
    this.$store.dispatch('partnerRequest/fetchPartnerRequests', { uuid: this.uuid })
      .finally(() => {
        this.$store.commit('partnerRequest/loadingPartnerRequests', false)
      })
    this.$store.dispatch('orders/checkCanGenerateFormation', { uuid: this.uuid })
    this.$store.dispatch('orders/fetchPlans')
    this.$store.dispatch('orderCreate/fetchProducts')
    this.$store.dispatch('orderCreate/fetchCoupons')
    if(this.isAutomationAdminOrProcessor){
       this.fetchSOSStatus({ uuid: this.uuid })
    }
  }

};
</script>

<style lang="scss">
  dt {
    font-size: 10pt;
    color: #a9a9a9;
  }
  .nav-tabs{
    margin-bottom: 20px;
  }
</style>
