<template>
    <div v-if="formationOrderTask && canAutomateFiling">
      <ZgButton class="zg-btn-default zg-text-md my-1"
        v-if="isAutomationAdmin" 
        :disabled="isAutomatedFormationTestBtnDisabled"
        @click="handleAutomatedFilingAsTestClick"
        >
        <template v-slot:title>
        <i class="fa fa-user-lock" :content="`${getAdminRetryMessage}`" v-tippy v-if="isAutomationAdmin" />
          Start Automated Formation AS TEST
        </template>
      </ZgButton>
      <ZgButton class="zg-btn-default zg-text-md my-1" 
        :disabled="isAutomatedFormationBtnDisabled || isAutomatedFilingSuccessful"
        @click="handleAutomatedFilingClick">
        <template v-slot:title>
          <i class="fa fa-user-lock" :content="`${getAdminRetryMessage}`" v-tippy v-if="isAutomationAdmin" />
           Start Automated Formation
        </template>
      </ZgButton>
      <RaAdressConflictBypassModal v-if="addressConflictBypassClicked"
                                   @close="addressConflictBypassClicked = false"
                                   v-bind:reportType="reportType"    
                                   v-bind:formationOrderTask="formationOrderTask"
                                   />    
    </div>
</template>
  
<script>
  
  import { mapActions, mapGetters } from 'vuex';
  import ZgButton from '../common/Generics/ZgButton.vue';
  import RaAdressConflictBypassModal from './ra-address-conflict-bypass-modal';
  import { hasPermissionMixin, automatedFilingMixin, orderMixin } from '@/components/mixins';
  import messages from '../../assets/messages';
  
  export default {
    name: 'FormationAutomationAction',
  
    props: ['formationOrderTask', 'automatedFiling'],
  
    components: {
      ZgButton,
      RaAdressConflictBypassModal,
    },
  
    mixins: [
      hasPermissionMixin,
      automatedFilingMixin,
      orderMixin
    ],
  
    data() {
      return {
          addressConflictBypassClicked: false,
          waitingRetryResponse: false
      };
    },
  
    computed: {
        ...mapGetters('orders', ['getAutomatedFiling']),
        ...mapGetters('automation', ['getAutomationMessages',
        ]),
        ...mapGetters('businessEntities', ['getContacts']),
        ...mapGetters({jurisdiction: 'orders/getJurisdiction'}),
        getAdminRetryMessage() {
          return this.adminRetryMessage;
        },
        allowAutomationRetry() {
          return this.isAutomationAdmin || (this.allowAutomatedFiling);
        },
        llcMessages() {
          return this.getAutomationMessages(
            this.formationOrderTask,
            this.getContacts
          );
        },
        isAutomatedFormationBtnDisabled() {
          if (!this.allowAutomationRetry || this.waitingRetryResponse) return true;
          return this.automatedFormationBtnStatus || (this.llcMessages && this.llcMessages.length > 0);
        },
         isAutomatedFilingSuccessful() {
          return Array.isArray(this.automatedFiling)
            && this.automatedFiling.length > 0
            && this.automatedFiling.some((a) => a.status === 'sos_filing_successful' && a.is_test === false);
          
      },
        isAutomatedFormationTestBtnDisabled() {
          if (!this.allowAutomationRetry || this.waitingRetryResponse) return true;
          return this.automatedFormationTestBtnStatus ||  (this.llcMessages && this.llcMessages.length > 0);
        },
        automatedFormationBtnStatus () {
          const isDev = this.onProd === false;
          return this.setAutomatedFormationBtnStatus(isDev);

        },
        automatedFormationTestBtnStatus () {
          return this.setAutomatedFormationBtnStatus(true);
        },
        canAutomateFiling () {
          return this.hasAutomatedPermission('llc_formation');
        },
      },
    
      methods: {
        ...mapActions({
            startAutomatedFiling: 'orders/startAutomatedFiling',
            fetchAutomationPayloadToValidate: 'automation/fetchAutomationPayloadToValidate',
        }),
        handleAutomatedFilingClick() {
            const automatedFiling = this.automatedFiling.find((af) => af.is_test === false);
            const uuid = this.formationOrderTask.order_task_uuid;
            this.reportType = false;
            this.waitingRetryResponse = true;
            if (!this.onProd) {
              this.reportType  = true;
            }
            if(uuid == null){
              this.setAlertMessage({ message: messages.orderTaskMessage.error });
            }else{
              if (automatedFiling?.status === 'ra_conflict_data' && this.jurisdiction === 'VA' ){
                this.addressConflictBypassClicked = true;
              }else{
                this.startAutomatedFiling({ uuid, test: this.reportType, bypassRaAddressConflict:false, reloadPage: true });
              }
            }
      },
      handleAutomatedFilingAsTestClick() {
          const automatedFiling = this.automatedFiling.find((af) => af.is_test === true);
          const uuid = this.formationOrderTask.order_task_uuid;
          this.reportType = true;
          if(uuid == null){
              this.setAlertMessage({ message: messages.orderTaskMessage.error });
          }else{
              if (automatedFiling?.status === 'ra_conflict_data' && this.jurisdiction === 'VA' ) {
                  this.addressConflictBypassClicked = true;
              } else {
                  this.startAutomatedFiling({ uuid, test: this.reportType, bypassRaAddressConflict:false, reloadPage: true });
              }   
            }
      },
      setAutomatedFormationBtnStatus (isTestBtn) {
          const disableBtnStatuses = ['created', 'started', 'sent_to_partner']
          const automatedFiling = this.automatedFiling.find((af) => af.is_test === isTestBtn)
          const isTest = automatedFiling?.filing_data?.test || automatedFiling?.filing_data?.is_test
          if (!isTest && !isTestBtn) disableBtnStatuses.push('sos_filing_successful')

          let isBtnDisabled = false
          if (this.formationOrderTask &&
              this.formationOrderTask.order_task_status === 'processing') {
            isBtnDisabled = true
          } else if (automatedFiling &&
              Object.prototype.hasOwnProperty.call(automatedFiling, 'status')) {
            isBtnDisabled = disableBtnStatuses.includes(automatedFiling.status)
          }
          return isBtnDisabled
      },
    },
    created() {
      if(this.formationOrderTask) {
        this.addressConflictBypassClicked = false;
        this.fetchAutomationPayloadToValidate(this.formationOrderTask);
      }
    },
  };
</script>
  
<style lang="scss" scoped>
  .zg-btn-default {
    width: 100%;
    margin-top: 0.625rem !important;
    margin-bottom: 0rem !important;
  }
  .zg-btn-default.focus, .zg-btn-default:focus, .zg-btn-default:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
  }
</style>
