<template>
  <Modal @closeModal="$emit('closeJourneyModal')" :showOverflow="true">
    <template v-slot:title>
      <transition name="fade" mode="out-in">
        <span key="initial" v-if="isJourneyUpdateSuccess" >Are you sure you would like to request the following information from the customer?</span>
      </transition>
    </template>
    <template v-slot:body>
      <transition name="fade" mode="out-in">
        <p><strong>{{ modalDataParam.entity }}</strong> {{ updateSummary }}</p>
      </transition>
    </template>
    <template v-slot:footer>
      <transition name="fade" mode="out-in">
      <span key="initial" v-if="isJourneyUpdateSuccess">
           <button @click="$emit('closeJourneyModal')" class="btn btn-outline-secondary action" :disabled="isJourneyUpdateSubmitting">Cancel</button>
           <button @click="updateJourneyService" class="btn btn-warning action" :disabled="isJourneyUpdateSubmitting">
            Request Update
            <transition name="btn-thinking" appear v-if="isJourneyUpdateSubmitting">
              <span class="btn__icon" key="icon"><IconSpinner /></span>
            </transition>
          </button>
        </span>
      </transition>
    </template>
  </Modal>
</template>


<script>

import Modal from "@/components/common/Modal.vue";
import IconSpinner from '@/components/common/images/IconSpinner';

export default {
  name: 'UpdateAddressOrContactModal',

  props: ['updateAddressOrContactModalOpen', 'modalData', 'isJourneyUpdateSuccess','isJourneyUpdateSubmitting'],
  components: {
    Modal,
    IconSpinner
  },
  data () {
    return {
      modalDataParam: this.modalData
    }
  },
  computed: {
    updateSummary() {
      if (this.modalDataParam?.entity !== this.modalDataParam?.subEntity) {
        return `${this.modalDataParam?.recordType} [${this.modalDataParam?.subEntity}]`;
      }

      return `${this.modalDataParam?.recordType}`;
    }
  },
  methods: {
    updateJourneyService() {
      this.$emit('updateJourneyService', this.modalDataParam)
    }
  }
};
</script>

<style lang="scss" scoped>
.badge {
  text-transform: capitalize;
}
.modal {
  &-content {
    padding: 2rem;
  }
  &-header {
    background: rgba(0, 0, 0, 0.25);;
  }
  &__grp {
    align-items: center;
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
  }
  &__item {
    margin-left: 10px;
    margin-right: 10px;
  }
  &-footer {
    span {
      display: flex;
      justify-content: space-between;
      .btn {
        margin: 0 0.25em;
      }
    }
  }
  p {
    float: none; /* bootstrap override */
    padding-left: 0;
    padding-right: 0;
  }
  label {
    font-size: rem(14);
    font-weight: 700;
    margin-bottom: 0;
  }
}
</style>
