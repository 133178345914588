<template>
  <div>
    <h2 class="header" v-bind:class="getHeaderClass">Entity Status</h2>
    <div class="box">
      <div class="row">
        <div class="col-md-9" v-if="hasPreviousProcesses">
          <dd>{{getProcessResults.message}}</dd>
          <dd class="last-checked">Last checked <span data-bs-toggle="tooltip" data-bs-placement="bottom" :title="formattedStartDatetime"><b>{{humanizedStartDatetime}}</b></span> by {{getLastProcess.started_by}}</dd>
        </div>
        <div class="col-md-9" v-else>
          <dd>{{businessEntityName}}'s status has not yet been confirmed with the {{jurisdiction}} SOS. Please click the button to check.</dd>
        </div>
        <div class="col-md-3">
          <zg-button class="zg-btn-default" @click="handleCheckStatus" :disabled="getProcessResults.status === 'started'" :isSubmiting="isChecking" type="submit">
            <template v-slot:title>Check Status</template>
          </zg-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZgButton from './Generics/ZgButton.vue'
import { mapGetters, mapActions } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

export default {
  data() {
    return {
      isChecking: false,
      unwatchFilingNumber: null
    };
  },

  components: { ZgButton },

  name: 'AutomatedChecking',

  props: ['businessEntityName', 'businessEntityType', 'filingNumber', 'orderTaskUuid', 'jurisdiction'],

  computed: {
    ...mapGetters({
      entityStatusAtSOS: 'orders/entityStatusAtSOS',
    }),
    humanizedStartDatetime () {
      return moment(this.getLastProcess.started_datetime).fromNow()
    },
    formattedStartDatetime() {
      return moment(this.getLastProcess.started_datetime).format('MMMM DD YYYY, h:mm:ss a');
    },
    getLastProcess() {
      return this.entityStatusAtSOS && this.entityStatusAtSOS[0];
    },
    getProcessResults () {
      let status = 'pending';
      let message = '';

      if (!this.hasPreviousProcesses)
        return {}
      
      const lastProcessStatus = this.getLastProcess.process_status.toLowerCase();

      if (['failed', 'timeout'].includes(lastProcessStatus)) {
        status = 'failed';
        message = "Status check failed, please retry.";
      }

      if (['started'].includes(lastProcessStatus)) {
        status = 'started';
        message = `Retrieving ${this.businessEntityName}'s status from the ${this.jurisdiction} SOS. Please wait.`;
      }

      if (['successful'].includes(lastProcessStatus)) {
        const entity_name = this.getLastProcess.processed_data[0].entity_name;
        const entity_status = this.getLastProcess.processed_data[0].status;

        const mainMessage = `The ${this.jurisdiction} SOS has confirmed that ${entity_name} is ${entity_status}`;
        if (entity_status.toLowerCase() !== 'active') {
          status = 'failed';
          message = mainMessage + '. Please ensure to complete all required steps before proceeding with the filing.';
        } else {
          status = 'successful';
          message = mainMessage + '. Please proceed with the filing.';
        }
      }
      
      return {
        status,
        message
      }
    },
    hasPreviousProcesses () {
      return !isEmpty(this.getLastProcess);
    },
    getHeaderClass () {
      return {
        [this.getProcessResults.status]: true
      };
    }
  },

  methods: {
    ...mapActions({
      fetchEntityStatusAtSOS: 'orders/fetchEntityStatusAtSOS',
      checkEntityStatusAtSOS: 'orders/checkEntityStatusAtSOS'
    }),
    handleCheckStatus () {
      this.isChecking = true;

      this.checkEntityStatusAtSOS({
        orderTaskUuid: this.orderTaskUuid,
        filingNumber: this.filingNumber,
        jurisdiction: this.jurisdiction,
      }).then(() => {
        this.isChecking = false;
        if(this.unwatchFilingNumber) {
          this.unwatchFilingNumber();
          this.unwatchFilingNumber = null;
        }
      });
    }
  },

  created () {
    this.unwatchFilingNumber = this.$watch('filingNumber', (newValue) => {
      if (newValue && !this.hasPreviousProcesses) {
        this.handleCheckStatus()
      }
    });
  },

  mounted() {
    this.fetchEntityStatusAtSOS({ orderTaskUuid: this.orderTaskUuid }).then(() => {
      if(this.hasPreviousProcesses) {
        this.unwatchFilingNumber();
      }
    });
  }
};
</script>

<style lang="scss" scoped>
  .pending {
    background-color: $zb-background;
  }
  .started {
    background-color: $clr-warning;
  }
  .successful {
    background-color: $clr-success;
  }
  .failed {
    background-color: $clr-danger;
  }
  .last-checked {
    font-size: smaller;
  }
</style>