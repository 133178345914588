<template>
  <tr>
    <td>{{ note.order_note_created_by }}</td>
    <td>{{ note.order_note_created_datetime| moment("MMMM DD YYYY") }}</td>
    <td>{{ note.order_note_content }}</td>
  </tr>
</template>

<script>
export default {
  name: 'OrderNoteListItem',

  props: ['note']
};
</script>

<style lang="scss" scoped>
</style>
