export const VGS_VAULT_ID_PROD = 'tntank0u2ap';
export const VGS_VAULT_ID_DEV = 'tntsfojlhgg';

export const isProduction = () => window.zgInit.DEPLOY_ENV.toLowerCase() === 'production';

export const getVgsVaultId = () => {
  const isProd = isProduction()
  return isProd ? VGS_VAULT_ID_PROD : VGS_VAULT_ID_DEV;
};

export const isToken = (token) => {
  const isProd = isProduction()
  if(token?.startsWith(`tok_${isProd ? 'live_' : 'sandbox_'}`)){
    return true;
  }
  return false;
}
