<template>
  <div>
    <h2 class="header">Business License Report</h2>
    <div class="box">
      <div class="row separate">
        <div class="col">
          <dt>What is your business address?</dt>
          <dd>
            <Person v-if="getAddressOfType('physical')" :person="getAddressOfType('physical')" />
          </dd>
        </div>
      </div>
      <div class="row separate">
        <div class="col">
          <dt>What is your industry?</dt>
          <dd>{{ orderTask.order_task_data.business_data.industry_label }} ({{ orderTask.order_task_data.business_data.industry_id }})</dd>
        </div>
      </div>
      <div class="row separate">
        <div class="col">
          <dt>What type of entity will the business operate under?</dt>
          <dd>{{ businessEntityType }}</dd>
        </div>
      </div>
      <div class="row separate">
        <div class="col">
          <dt>In which state was the business incorporated?</dt>
          <dd>{{ orderTask.order_task_data.jurisdiction }}</dd>
        </div>
      </div>
      <div class="row separate">
        <div class="col">
          <dt>Will your business be registering under an FBN or DBA?</dt>
          <dd>{{ orderTask.order_task_data.business_data.has_trade_names | yesno }}</dd>
        </div>
      </div>
      <div class="row separate">
        <div class="col">
          <dt>Will the business be based out of a residential or commercial location?</dt>
          <dd>{{ orderTask.order_task_data.business_data.out_of_home ? 'Commercial' : 'Residential' }}</dd>
        </div>
      </div>
      <div class="row separate">
        <div class="col">
          <dt>Will the business have W-2 employees within the next six months?</dt>
          <dd>
            {{ orderTask.order_task_data.business_data.has_employees | yesno }}
            <span v-if="orderTask.order_task_data.business_data.has_employees">({{ orderTask.order_task_data.business_data.employees_count }})</span>
          </dd>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Person from '../../common/person';
import { yesNoMixin } from '@/components/mixins';

export default {
  name: 'BusinessLicenseReportView',

  components: { Person },

  props: ['orderTask'],

  mixins: [yesNoMixin],

  computed: {
    ...mapGetters({
      businessEntityType: 'businessEntities/getBusinessEntityType',
      getAddressOfType: 'businessEntities/getAddressOfType'
    })
  },
  methods: {
    ...mapActions({
      fetchBusinessEntity: 'businessEntities/fetchBusinessEntity'
    })
  },
  created() {
    this.fetchBusinessEntity({ uuid: this.orderTask.business_entity_uuid });
  }
};
</script>

<style lang="scss" scoped>
  dt {
    font-size: rem(14);
    color: $stone-4;
  }
  dd {
    margin-bottom: 1em;
  }
</style>
