<template>
  <div>
    <h2 class="header">Change of Registered Agent</h2>
    <div class="box">
      <div class="col-md-12">
        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="price">ZenBusiness Price</label>
              <select
                class="form-control"
                id="select-price"
                v-model="localPrice"
                @change="setOrderItem"
              >
                <option disabled selected>Please select one</option>
                <option
                  v-for="optionPrice in localPrices"
                  :key="optionPrice.uuid"
                  :value="optionPrice"
                >
                  {{
                    optionPrice.related_affiliate.name
                      ? optionPrice.related_affiliate.name
                      : "ZB"
                  }}
                  CORA (${{ optionPrice.related_service_pricing.amount }}/{{
                    optionPrice.related_service_pricing.interval
                  }})
                </option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="fee">State Fee</label>
              <select
                class="form-control"
                id="select-fee"
                v-model="localFee"
                @change="setOrderItem"
              >
                <option disabled selected>Please select one</option>
                <option
                  v-for="optionFee in localFees"
                  :key="optionFee.service_fee_uuid"
                  :value="optionFee"
                >
                  {{ optionFee.filing_type }}
                  {{ optionFee.entity_type.toUpperCase() }} (${{
                    optionFee.amount
                  }})
                </option>
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { orderCreateProductMixin } from "@/components/mixins";

export default {
  name: 'changeOfRegisteredAgentCreate',

  props: ['pricing', 'jurisdiction', 'businessEntityType', 'filingType'],

  mixins: [orderCreateProductMixin],

  data() {
    return {
      productData: {
        order_data: {
          service: 'change_of_registered_agent',
          jurisdiction: this.jurisdiction,
          formation_jurisdiction: this.jurisdiction
        },
        order_item: {
          service: 'change_of_registered_agent',
          jurisdiction: this.jurisdiction,
          price: undefined,
          fees: undefined,
        }
      },
      localPrice: undefined,
      localPrices: [],
      localFee: undefined,
      localFees: [],
    };
  },
  computed: {
    isDomestic() {
      const domesticJur = this.getJurisdictions().find((j) => j.domestic_flag);
      return !!domesticJur && domesticJur.jurisdiction_code === this.jurisdiction;
    },
  },
  methods: {
    ...mapActions("orderCreate", [
      "fetchProductFees",
      "fetchProductPrices",
    ]),
    ...mapGetters({
      getAffiliateOrDefault: "accounts/getAffiliateOrDefault",
      getJurisdictions: "businessEntities/getJurisdictions",
    }),
    setOrderItem() {
      if (this.localPrice) {
        this.productData.order_item.price = this.localPrice.related_service_pricing.amount;
      }
      if (this.localFee) {
        this.productData.order_item.fees = parseFloat(this.localFee.amount);
      }
    },
  },
  created() {
    const product = "change_of_registered_agent";
    this.fetchProductPrices({ product, affiliate: this.getAffiliateOrDefault() }).then(
      (data) => {
        if (data.success) {
          this.localPrices = data.prices;
          if (data.prices.length === 1) {
            this.localPrice = data.prices[0];
            this.setOrderItem();
          }
        }
      }
    );
    this.fetchProductFees({ product, jurisdiction: this.jurisdiction }).then(
      (data) => {
        if (data.success) {
          const filteredFees = data.fees.filter(
            (fee) =>
              this.isDomestic === (fee.filing_type === "domestic") &&
              this.businessEntityType === fee.entity_type
          );
          this.localFees = filteredFees;
          if (filteredFees.length === 1) {
            this.localFee = filteredFees[0];
            this.setOrderItem();
          }
        }
      }
    );
  },
};
</script>
<style lang="scss" scoped>
  .form-group {
    margin-bottom: 1.5em;
  }
</style>
