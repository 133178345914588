<template>
  <div>
    <h1 class="heading">Domain Name Registration</h1>
    <h2 class="header">Details</h2>
    <div class="box">
      <div class="row">
        <div v-if="domain.skip" class="col">
          <dt>User chose to Skip Domain</dt>
          <dd>{{ domain.skip|yesno }}</dd>
        </div>
      </div>
      <br>
      <div class="row">
        <div v-if="domain.primary" class="col">
          <dt>Domain Name</dt>
          <dd>{{ domain.primary }}</dd>
        </div>
        <div v-else class="col">
          <dt>Domain Name</dt>
          <dd>None provided</dd>
        </div>
        <div v-if="domain.alternate" class="col">
          <dt>Alternates</dt>
          <dd v-for="alternate in domain.alternates" v-bind:key="alternate">{{ alternate }}</dd>
        </div>
        <div v-else class="col">
          <dt>Alternates</dt>
          <dd>None provided</dd>
        </div>
      </div>
      <div class="row">
        <div v-if="domain.domain_verified" class="col">
          <dt>Domain Verified</dt>
          <dd>{{ domain.domain_verified }}</dd>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { yesNoMixin } from '../../mixins';

export default {
  name: 'DomainNameRegView',
  props: ['orderTask'],
  mixins: [yesNoMixin],
  computed: {
    domain() {
      return this.orderTask.order_task_data;
    }
  }
};
</script>
<style lang="scss" scoped>
  dt {
    font-size: 10pt;
    color: #a9a9a9;
  }
</style>
