<template>
  <div>
    <h2 class="header">DBA Compliance - {{transOrRecurring}}</h2>
    <div class="box">
      <div class="col-md-12">
        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Pricing Plan</label>
              <select v-model="period" class="form-control" id="ra-period">
                <option disabled>Please select one</option>
                <option value="1">Annual (${{ annual_price }}/year)</option>
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { orderCreateProductMixin } from '@/components/mixins';

export default {
  name: 'dbaComplianceCreate',

  props: ['pricing', 'jurisdiction'],

  mixins: [orderCreateProductMixin],

  data() {
    return {
      productData: {
        order_data: {
          service: 'dba_compliance',
          jurisdiction: this.jurisdiction,
          is_new_business: false
        },
        order_item: {
          service: 'dba_compliance',
          jurisdiction: this.jurisdiction,
          price: this.pricing.price,
          fees: this.pricing.fees,
          term: this.pricing.term,
          period: this.pricing.period
        }
      }
    };
  }
};
</script>
