<template>
  <div>
    <h2 class="header">Static Website - {{transOrRecurring}}</h2>
    <div class="box">
      <div class="col-md-12">
        <div v-if="hasActiveWebsite">
          <p class="mb-2"><strong>This business entity already has an active website product!</strong></p>
          <div class="row">
            <div class="col">
              <dt>Website URL</dt>
              <dd><a target="_blank" :href="websiteLinkURL">{{ websiteLinkURL }}</a></dd>
            </div>
            <div class="col">
              <dt>Website URI</dt>
              <dd>{{ websiteURL }}</dd>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <dt>{{ websiteDomainURL }}</dt>
              <dd>{{ website.zenbusiness_dns | yesno }}</dd>
            </div>
            <div class="col">
              <dt>Active</dt>
              <dd>{{ website.active | yesno }}</dd>
            </div>
          </div>
          <br />
        </div>
        <div v-if="canBuy">
          <p>Website activates on order completion.</p>
          <div class="col-md-12" v-if="!isWebToolsSelected">
            <form>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="ra-period">ZenBusiness Price</label>
                  <select
                    class="form-control"
                    id="ra-period"
                    v-model="localPrice"
                    @change="setOrderItem"
                  >
                    <option disabled selected>Please select one</option>
                    <option v-for="optionPrice in localPrices" :key="optionPrice.uuid" :value="optionPrice">
                      {{optionPrice.related_affiliate.name ? optionPrice.related_affiliate.name : 'ZB' }} Website
                      (${{optionPrice.related_service_pricing.amount}}/{{optionPrice.related_service_pricing.interval}})
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-row" v-if="period === 1">
                <div class="form-group col-md-12">
                  <div class="form-check">
                    <input v-model="applyCoupon" class="form-check-input" type="checkbox" id="applyCoupon" @change="setAnnualWebsiteCoupon">
                    <label class="form-check-label" for="applyCoupon">
                      Apply First Year Free Coupon
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-row" v-if="period === 12">
                <div class="form-group col-md-12">
                  <div class="form-check">
                    <input v-model="applyCoupon" class="form-check-input" type="checkbox" id="applyCoupon" @change="setMonthlyWebsiteCoupon">
                    <label class="form-check-label" for="applyCoupon">
                      Apply First Month Free Coupon
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { yesNoMixin } from '@/components/mixins';
import { orderCreateProductMixin } from '@/components/mixins';

export default {
  name: 'staticWebsite',

  props: ['pricing', 'jurisdiction', 'coupons'],

  mixins: [orderCreateProductMixin, yesNoMixin],

  data() {
    return {
      productData: {
        order_data: {
          service: 'static_website',
          standalone: true,
          is_auto_included: false,
          jurisdiction: this.jurisdiction,
        },
        order_item: {
          service: 'static_website',
          jurisdiction: this.jurisdiction,
          price: undefined,
          fees: undefined,
          term: undefined,
          period: undefined,
        }
      },
      coupon: this.coupons.find((c) => c.external_coupon_id === '129_off_first_year'),
      localPrice: undefined,
      localPrices: [],
      localFees: []
    };
  },
  computed: {
    ...mapState({
      orderCreate: (state) => state.orderCreate,
      website: (state) => {
        return state.webServices.website
      },
    }),
    annualPrice() {
      if (this.localPrices) {
        return this.localPrices[0]?.related_service_pricing?.amount;
      }
      return "";
    },
    websiteURL() {
      return this.website.website_url
    },
    websiteLinkURL() {
      return 'https://' + this.website.website_url + (this.onProd ? '' : '.dev') + '.zensmb.com'
    },
    websiteDomainURL() {
      return (this.website.website_url == null) ? '' : ('Domain linked to ' + (this.onProd ? '' : '.dev') + '.zensmb.com')
    },
    hasActiveWebsite() {
      return this.website.active === true
    },
    canBuy() {
      return !this.website?.active || this.website?.website_labels?.includes('tryBeforeYouBuy')
    },
    isWebToolsSelected: {
      get() {
        return this.$store.state.orderCreate.selectedProducts.includes('website_tools_bundle');
      }
    },
  },
  methods: {
    ...mapActions('orderCreate', [
      'fetchProductFees',
      'fetchProductPrices',
    ]),
    ...mapActions({
      fetchBusinessEntityWebSites: 'webServices/fetchBusinessEntityWebSites',
    }),
    ...mapGetters({
      getAffiliateOrDefault: 'accounts/getAffiliateOrDefault',
    }),
    setAnnualWebsiteCoupon() {
      this.coupon = this.coupons.find((c) => c.external_coupon_id === '129_off_first_year');
      this.setCoupon();
    },
    setMonthlyWebsiteCoupon() {
      this.coupon = this.coupons.find((c) => c.external_coupon_id === '15_off_first_month');
      this.setCoupon();
    },
    setOrderItem() {
      if(this.localPrice) {
        this.productData.order_item.price = this.localPrice.related_service_pricing.amount;
        const intervalLabel = this.localPrice.related_service_pricing.interval;
        const { term, period } = this.getTermAndPeriodAccordingIntervalLabel(intervalLabel);
        this.productData.order_item.term = term;
        this.productData.order_item.period = period;
      }

    },
  },
  created() {
    const product = "static_website";
    this.fetchProductPrices({ product, affiliate: this.getAffiliateOrDefault() }).then(
      (data) => {
        if (data.success) {
          this.localPrices = data.prices;
          if (data.prices.length === 1) {
            this.localPrice = data.prices[0];
            this.setOrderItem();
          }
        }
      }
    );
    this.fetchProductFees({ product, jurisdiction: this.jurisdiction }).then(
      (data) => {
        if (data.success) {
          this.localFees = data.fees;
          const fee = this.localFees[0];
          this.productData.order_item.fees = parseFloat(fee.amount);
        }
      }
    );
    this.fetchBusinessEntityWebSites({ uuid: this.orderCreate.businessEntity.business_entity_uuid, account_uuid: this.orderCreate.businessEntity.account_uuid });
  },
};
</script>
