<template>
  <div>
    <h2 class="header">{{ serviceDisplayName }}</h2>
    <div class="box">
      <div class="col-md-12">
        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="price">ZenBusiness Price</label>
              <input v-model="price" class="form-control" placeholder="10" name="Price" id="price" />
            </div>
            <div class="form-group col-md-6">
              <label for="fees">State Fees</label>
              <input v-model="fees" class="form-control" placeholder="0" name="Fees" id="fees"/>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Select Year</label>
              <input v-model="productData.order_data.tax_year" class="form-control" name="TaxYear" id="taxYear" type="number"/>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { orderCreateProductMixin } from '@/components/mixins';

export default {
  name: 'taxServiceCreate',

  props: ['pricing', 'jurisdiction', 'serviceName', 'serviceDisplayName'],

  mixins: [orderCreateProductMixin],

  data() {
    return {
      productData: {
        order_data: {
          service: this.serviceName,
          jurisdiction: this.jurisdiction,
          tax_year: 2020
        },
        order_item: {
          service: this.serviceName,
          jurisdiction: this.jurisdiction,
          price: this.pricing.price,
          fees: this.pricing.fees
        }
      }
    };
  },

  methods: {
  }
};
</script>
