
<template>
  <input ref="numberInput" type="text" class="zg-input"
    @input="handleInput"
    @change="handleChange"
    @paste="handlePaste"
		:min="min"
		:max="max"
		:step="step"
    :value="displayString"
    :id="id"
    :name="name"
    :disabled="disabled"
    :placeholder="placeholder"
    :aria-labelledby="!hideLabel ? labelId : false"
    :aria-label="hideLabel ? label : false"
  />
</template>

<script>
	export default {
    name: 'ZgNumericInput',
		props: {
			value: { type: Number, default: 0 },
			id: { type: String, default: "zg-numeric-input" },
			name: { type: String, default: "zg-numeric-input" },
			label: { type: String, required: true },
			placeholder: { type: String, default: "" },
			min: { type: Number, default: 0 },
			max: { type: Number, default: Number.POSITIVE_INFINITY },
			step: { type: Number },
			isValid: { type: Boolean, default: false, required: false },
			disabled: { type: Boolean, default: false },
			locale: { type: String, default: null },
			currency: { type: String, default: null },
			minDecimalPlaces: { type: Number, default: 0 },
			maxDecimalPlaces: { type: Number, default: 2 },
			integerOnly: { type: Boolean, default: false },		
			hideLabel: { type: Boolean, default: false },
			labelClass: { type: String, default: null },
		},
		data: () => {
			return {
				internalValue: null,
				ctrlActive: false,
				shiftActive: false,
				internalLocale: "en-US"
			}
		},
		computed: {
			labelId() {
				return this.id + '-label'
			},
			displayString() {
				if (this.internalValueIsNotDefined) {
					if (this.value) {
						this.setInternalValue(this.value);
					} else {
						this.setToDefaultValue();
					}
				}
				let minDecimals = 0, maxDecimals = 0;
				if (!this.integerOnly) {
					minDecimals = this.minDecimalPlaces;
					maxDecimals = this.maxDecimalPlaces;
				}
				return this.internalValue.toLocaleString(this.internalLocale, {
					style: this.currency ? "currency" : "decimal",
					currency: this.currency || undefined,
					minimumFractionDigits: minDecimals,
					maximumFractionDigits: maxDecimals
				});
			},
			internalValueIsNotDefined() {
				return this.internalValue == null || Number.isNaN(this.internalValue);
			},
			isError() {
				let error = false;
				if (this.internalValue == null || this.internalValue > this.max || this.internalValue < this.min) {
					error = true;
				}
				this.$emit('update:isValid', !error); //this.isValidComputed = !error;
				return error;
			},
		},
		methods: {
			getDefaultValue() {
				if (this.min != Number.NEGATIVE_INFINITY) {
					return this.min;
				}
				return 0;
			},
			handlePaste(e) {
				const clipboardData = e.clipboardData || window.clipboardData;
				const pastedData = clipboardData.getData("Text");
				if (pastedData) {
					e.stopPropagation();
					e.preventDefault();
					this.valueChanged(pastedData, null);
				}
			},
			handleInput(e) {
				let target = e.target;
				this.valueChanged(target.value, e.data);
			},
			handleChange(e) {
				let target = e.target;
				this.valueChanged(target.value, null, true);
			},
			valueChanged(newValue, newInput, strictValidation = false, possibleRecurse = true) {
				const decimalNumbersRegex = /[+-]?\d+(\.\d+)?/g;
				const normalisedInput = this.normaliseInput(newValue, this.internalLocale);
				// Match to find any numbers.
				const matches = normalisedInput.match(decimalNumbersRegex);
				let result = null;
				let isValidNonNumeric = false;
				if (!strictValidation && (this.isEmptyInput(newValue) || this.isStartingSignedInput(newValue) || this.isAddingDecimalPlaces(newValue))) {
					isValidNonNumeric = true;
				} else if (matches != null && matches.length > 0) {
					// Parse the first match.
					result = parseFloat(matches[0]);
				} else {
					if (possibleRecurse && newValue.length > 0) {
						this.valueChanged(`${newValue[0]}`, newInput, strictValidation, false);
						return;
					}
					// Manually clear the invalid input to cover edge cases where computed properties don't update because the internal value hasn't changed value.
					(this.$refs.numberInput).value = null;
				}
				// Don't reset to 0 when we have a valid non-numeric edge case.
				if (!isValidNonNumeric) {
					if (this.integerOnly) {
						result = Math.round(result);
					}
					if (newInput === "-") {
						result = -result;
					} else if (newInput === "+") {
						result = Math.abs(result);
					}
					this.setInternalValue(result);
				}
			},
			normaliseInput(value, locale) {
				const example = Intl.NumberFormat(locale).format(1.1);
				const cleanRegExp = new RegExp(`[^-+0-9${example.charAt(1)}]`, "g");
				const cleanValue = value.replace(cleanRegExp, "");
				const normalised = cleanValue.replace(example.charAt(1), ".");
				return normalised;
			},
			isStartingSignedInput(input) {
				return input.length === 1 && (input === "+" || input === "-");
			},
			isEmptyInput(input) {
				return input.length === 0;
			},
			isAddingDecimalPlaces(input) {
				return input.endsWith(".") || input.endsWith(",") || input.endsWith(" ");
			},
			setToDefaultValue() {
				let newVal = 0;
				if (this.min != Number.NEGATIVE_INFINITY) {
					newVal = this.min;
				}
				this.setInternalValue(newVal);
			},
			setInternalValue(val) {
				// Wipe out the value to force an update even if the value hasn't changed - ensures extra characters that don't affect the parsed value are removed from display.
				this.internalValue = null;
				this.internalValue = val;
				this.$emit('input', this.internalValue);
				this.$emit('change', this.internalValue);
			},
			keychange(e) {
				this.ctrlActive = e.ctrlKey;
				this.shiftActive = e.shiftKey;
			},
		},
		created() {
			if (this.locale === null) {
				if (typeof window !== 'undefined' && window) {
					this.internalLocale = window.navigator.language;
					document.addEventListener("keydown", this.keychange);
					document.addEventListener("keyup", this.keychange);
				}
			} else {
				this.internalLocale = this.locale;
			}
		},
		beforeDestroy() {
			document.removeEventListener('keydown', this.keychange);
			document.removeEventListener('keyup', this.keychange);
		},
		watch: {
			value(newVal) {
				this.internalValue = newVal;
			}
		},
	};
</script>

<style lang="scss" scoped>
  .zg-input {
    width: 100%;
    height: em(54, 20);
    font-size: em(15);
    padding-left: em(18, 20);
    border: 2px solid $stone-5;
    border-radius: em(4, 20);
		:disabled {
			opacity: 0.4;
			pointer-events: none;
		}
		:active {
			border-color: rgba(0, 0, 0, 0.4);
			background: $clr-white;
		}
		:focus {
			border-color: $blue-40;
			background: $clr-white;
			color: rgba(0, 0, 0, 0.8);
		}
  }
</style>