<template>
  <div>
    <h2 class="header">Desired Domain Name Registration - {{transOrRecurring}}</h2>
    <div class="box">
      <div class="col-md-12">
        <form v-if="canBuy || !isWebToolsSelected">
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="primary">Primary
                <small>
                  <br/><b>Please validate domain name before registering</b>
                  <br/><b class="text-danger">Domain name is required.</b>
                </small>
              </label>
              <input
                v-model="productData.order_data.primary"
                class="form-control"
                v-bind:class="{ 'is-invalid': !validateDomain(productData.order_data.primary) }"
                placeholder="nameyourbiz.com"
                name="Primary"
                id="primary"/>
              <span v-if="!validateDomain(productData.order_data.primary)" class="invalid-feedback"><small>Invalid Domain Name</small></span>
              <span> <b>www.{{ productData.order_data.primary }} </b></span>
              <span class="badge bg-secondary" v-if="this.domainAvailable === 'pending'">Checking domain availability...please wait</span>
              <span class="badge bg-danger" v-if="this.domainAvailable === 'unavailable'">Domain Taken</span>
              <span class="badge bg-success" v-if="this.domainAvailable === 'available'">Domain Available</span>
            </div>
            <div class="form-group col-md-6 pt-2">
              <span class="btn btn-primary btn-sm mt-5" @click="checkDomainAvailability">Check Domain Availability</span>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="alternate">Alternates</label>
              <input v-model="productData.order_data.alternates[0]" class="form-control" placeholder="alt1.com" name="Primary" id="alternate" /><br/>
              <input v-model="productData.order_data.alternates[1]" class="form-control" placeholder="alt2.com" name="Primary" id="alternate2" /><br/>
            </div>
          </div>
          <div v-if="!validateTempDomain(productData.order_data.primary)">
              <label for="automatic_sending">Activate Automatic Partner Request Sending</label><br/>
            <input
                type="checkbox"
                v-model="productData.order_data.automatic_sending"
                name="AutomaticSending"
                id="automatic_sending"/>
              <div><br/></div>
          </div>
          <span v-if="validateTempDomain(productData.order_data.primary)"><b>Deactivated Automatic Partner Request Sending</b><div><br/></div></span>
          <div class="form-row">
            <div class="form-group col-md-6" v-if="!isWebToolsSelected">
              <label>Pricing Plan</label>
              <select v-model="period" class="form-control" id="ra-period">
                <option disabled>Please select one</option>
                <option value="1">Annual (${{ annual_price }}/year)</option>
              </select>
              <div class="form-check">
                <input v-model="applyCoupon" class="form-check-input" type="checkbox" id="applyCoupon" @change="setCoupon">
                <label class="form-check-label" for="applyCoupon">
                  Apply First Year Free Coupon
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { orderCreateProductMixin, domainNameMixin, tempDomainNameMixin } from '@/components/mixins';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'domainRegistrationCreate',

  props: ['pricing', 'jurisdiction', 'coupons'],

  mixins: [orderCreateProductMixin, domainNameMixin, tempDomainNameMixin],

  computed: {
    ...mapState({
      orderCreate: (state) => state.orderCreate,
      website: (state) => {
        return state.webServices.website
      },
    }),
    domainAvailable: {
      get() {
        return this.$store.state.orderCreate.domainAvailable;
      },
      set(value) {
        this.$store.commit('orderCreate/setdomainAvailable', value);
      }
    },
    isWebToolsSelected: {
      get() {
        return this.$store.state.orderCreate.selectedProducts.includes('website_tools_bundle');
      }
    },
    canBuy: {
      get() {
        return !this.website?.active || this.website?.website_labels?.includes('tryBeforeYouBuy')
      }
    },
    isValid: function() {
      const validations = []
      const primary = this.productData.order_data.primary
      validations.push( primary!== null && primary !== '')
      validations.push(this.domainAvailable === 'available');
      return validations.every(Boolean)
    }
  },

  data() {
    return {
      zenbusinessCheck: true,
      productData: {
        order_data: {
          service: 'domain_name_reg',
          jurisdiction: this.jurisdiction,
          primary: null,
          automatic_sending: true,
          alternates: [],
          standalone: true,
          is_auto_included: false,
          skip: false
        },
        order_item: {
          service: 'domain_name_reg',
          jurisdiction: this.jurisdiction,
          price: this.pricing.price,
          fees: this.pricing.fees,
          term: this.pricing.term,
          period: this.pricing.period
        }
      },
      coupon: this.coupons.find((c) => c.external_coupon_id === '25_off_first_year')
    };
  },
  methods: {
    ...mapActions({
      fetchBusinessEntityWebSites: 'webServices/fetchBusinessEntityWebSites',
    }),
    checkDomainAvailability() {
      this.$store.commit('orderCreate/setdomainAvailable', 'pending');
      this.$store.dispatch('orderCreate/checkDomainAvailability', { domainName: this.productData.order_data.primary })
        .then(() => {
        });
    }
  },
  created () {
    if(this.$store.state.orderCreate.selectedProducts.includes('website_tools_bundle')) {
      this.productData.order_item.price = 0
      this.productData.order_item.fees = 0
    }
    this.fetchBusinessEntityWebSites({ uuid: this.orderCreate.businessEntity.business_entity_uuid, account_uuid: this.orderCreate.businessEntity.account_uuid });
  },
  watch: {
      'productData.order_data.primary'(newValue) {
        if (this.validateTempDomain(newValue)) {
          this.productData.order_data.automatic_sending = false;
        }
			}
		},
};
</script>
