<template>
  <div>
    <AnnualReportAutomationAction
      v-if="llcAnnualReportOrderTask"
      :automatedFiling="automatedFiling"
      :annualReportOrderTask="llcAnnualReportOrderTask"
    />
    <FormationAutomationAction 
      v-if="llcFormationOrderTask"
      :automatedFiling="automatedFiling"
      :formationOrderTask="llcFormationOrderTask"
    />
    <GoodStandingAutomationAction 
      v-if="validStandardGoodStandingOrderTask"
      :automatedFiling="automatedFiling"
      :goodStandingOrderTask="validStandardGoodStandingOrderTask"
    />
  </div>
</template>

<script>

import AnnualReportAutomationAction from "./AnnualReportAutomationAction.vue";
import FormationAutomationAction from './FormationAutomationAction.vue';
import GoodStandingAutomationAction from './GoodStandingAutomationAction.vue';
import {
  hasPermissionMixin,
  automatedFilingMixin,
  orderMixin,
} from "@/components/mixins";

export default {
  name: "AutomationActions",

  props: ["orderTasksWithAutomation", "automatedFiling"],

  components: {
    AnnualReportAutomationAction,
    FormationAutomationAction,
    GoodStandingAutomationAction
  },

  mixins: [hasPermissionMixin, automatedFilingMixin, orderMixin],

  data() {
    return {
      payloadLLCAnnualReport: null,
    };
  },
  computed: {
    llcAnnualReportOrderTask() {
      return this.orderTasksWithAutomation
        ? this.orderTasksWithAutomation.find(
          ({ order_task_type }) => order_task_type === "llc_annual_report"
        )
        : null;
    },
    llcFormationOrderTask() {
      return this.orderTasksWithAutomation
        ? this.orderTasksWithAutomation.find(
          ({ order_task_type }) => order_task_type === "llc_formation"
        )
        : null;
    },
    validStandardGoodStandingOrderTask() {
      return this.orderTasksWithAutomation?.find(
        ({ order_task_type, order_task_jurisdiction, order_task_data }) =>
          order_task_type === "standard_good_standing" && 
          order_task_jurisdiction === "CA" && 
          order_task_data?.entity_type === 'LLC'
      ) || null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
