<template>
  <div>
    <h2 class="header">DBA Entity - {{transOrRecurring}}</h2>
    <div class="box">
      <div class="col-md-12">
        <form>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="service-description">DBA Name</label>
              <input v-model="productData.order_data.dba_name" class="form-control" placeholder="My DBA Name" name="ServiceDescription" id="service-description" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="price">ZenBusiness Price</label>
              <input v-model="price" class="form-control" placeholder="100" name="Price" id="price" disabled>
            </div>
            <div class="form-group col-md-6">
              <label for="fees">State Fees</label>
              <select v-if="jurisdiction === 'CA'" v-model="fees" class="form-control" name="Fees" id="fees">
                <option disabled>Please select one</option>
                <option v-for="county in Object.keys(pricing.fees).sort()" :value="pricing.fees[county].fees" v-bind:key="county">{{ county }} (${{ pricing.fees[county].fees }})</option>
              </select>
              <input v-else v-model="fees" class="form-control" placeholder="15" name="Fees" id="fees"/>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { orderCreateProductMixin } from '@/components/mixins';

export default {
  name: 'dbaEntityCreate',

  props: ['pricing', 'jurisdiction'],

  mixins: [orderCreateProductMixin],

  data() {
    return {
      productData: {
        order_data: {
          service: 'dba_entity',
          jurisdiction: this.jurisdiction,
          dba_name: null,
          city: '',
          county: ''
        },
        order_item: {
          service: 'dba_entity',
          jurisdiction: this.jurisdiction,
          price: this.pricing.price,
          fees: this.pricing.fees
        }
      }
    };
  }
};
</script>
