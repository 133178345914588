<template>
  <div v-if="goodStandingOrderTask && canAutomateFiling">
    <ZgButton class="zg-btn-default zg-text-md my-1" v-if="isAutomationAdmin"
      :disabled="isAutomatedGoodStandingTestBtnDisabled" @click="handleAutomatedFilingAsTestClick">
      <template v-slot:title>
        <i class="fa fa-user-lock" :content="`${getAdminRetryMessage}`" v-tippy v-if="isAutomationAdmin" />
        Start Automated Good Standing AS TEST
      </template>
    </ZgButton>
    <ZgButton class="zg-btn-default zg-text-md my-1"
      :disabled="isAutomatedGoodStandingBtnDisabled || isAutomatedFilingSuccessful" @click="handleAutomatedFilingClick">
      <template v-slot:title>
        <i class="fa fa-user-lock" :content="`${getAdminRetryMessage}`" v-tippy v-if="isAutomationAdmin" />
        Start Automated Good Standing
      </template>
    </ZgButton>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import ZgButton from '../common/Generics/ZgButton.vue';
import { hasPermissionMixin, automatedFilingMixin, orderMixin } from '@/components/mixins';
import messages from '../../assets/messages';

export default {
  name: 'GoodStandingAutomationAction',
  props: ['goodStandingOrderTask', 'automatedFiling'],

  components: {
    ZgButton,
  },

  mixins: [
    hasPermissionMixin,
    automatedFilingMixin,
    orderMixin
  ],

  data() {
    return {
      waitingRetryResponse: false
    };
  },

  computed: {
    ...mapGetters('orders', ['getAutomatedFiling']),
    ...mapGetters('automation', ['getAutomationMessages',
    ]),
    ...mapGetters('businessEntities', ['getContacts']),
    ...mapGetters({ jurisdiction: 'orders/getJurisdiction' }),
    getAdminRetryMessage() {
      return this.adminRetryMessage;
    },
    allowAutomationRetry() {
      return this.isAutomationAdmin || (this.allowAutomatedFiling);
    },
    automationMessages() {
      return this.getAutomationMessages(
        this.goodStandingOrderTask,
        this.getContacts
      );
    },
    isAutomatedGoodStandingBtnDisabled() {
      if (!this.allowAutomationRetry || this.waitingRetryResponse) return true;
      return this.automatedGoodStandingBtnStatus || (this.automationMessages && this.automationMessages.length > 0);
    },
    isAutomatedFilingSuccessful() {
      return Array.isArray(this.automatedFiling)
        && this.automatedFiling.length > 0
        && this.automatedFiling.some((a) => a.status === 'sos_filing_successful' && a.is_test === false);
    },
    isAutomatedGoodStandingTestBtnDisabled() {
      if (!this.allowAutomationRetry || this.waitingRetryResponse) return true;
      return this.automatedGoodStandingTestBtnStatus || (this.automationMessages && this.automationMessages.length > 0);
    },
    automatedGoodStandingBtnStatus() {
      const isDev = this.onProd === false;
      return this.setautomatedGoodStandingBtnStatus(isDev);
    },
    automatedGoodStandingTestBtnStatus() {
      return this.setautomatedGoodStandingBtnStatus(true);
    },
    canAutomateFiling() {
      return this.hasAutomatedPermission('standard_good_standing');
    },
    isDeveloper(){
      return this.hasPermission('access:developers');
    }
  },

  methods: {
    ...mapActions({
      startAutomatedFiling: 'orders/startAutomatedFiling',
      fetchAutomationPayloadToValidate: 'automation/fetchAutomationPayloadToValidate',
    }),
    handleAutomatedFilingClick() {
      const uuid = this.goodStandingOrderTask.order_task_uuid;
      this.reportType = false;
      this.waitingRetryResponse = true;
      if (!this.onProd) {
        this.reportType = true;
      }
      if (uuid == null) {
        this.setAlertMessage({ message: messages.orderTaskMessage.error });
      }else {
        this.startAutomatedFiling({ uuid, test: this.reportType, bypassRaAddressConflict: false, reloadPage: true });
      }
    },
    handleAutomatedFilingAsTestClick() {
      const uuid = this.goodStandingOrderTask.order_task_uuid;
      this.reportType = true;
      if (uuid == null) {
        this.setAlertMessage({ message: messages.orderTaskMessage.error });
      } else {
        this.startAutomatedFiling({ uuid, test: this.reportType, bypassRaAddressConflict: false, reloadPage: true });
      }
    },
    setautomatedGoodStandingBtnStatus(isTestBtn) {
      const disableBtnStatuses = ['created', 'started', 'sent_to_partner']
      const automatedFiling = this.automatedFiling.find((af) => af.is_test === isTestBtn)
      const isTest = automatedFiling?.filing_data?.test || automatedFiling?.filing_data?.is_test
      if (!isTest && !isTestBtn) disableBtnStatuses.push('sos_filing_successful')

      let isBtnDisabled = false
      if (this.goodStandingOrderTask &&
        this.goodStandingOrderTask.order_task_status === 'processing') {
        isBtnDisabled = true
      } else if (automatedFiling &&
        Object.prototype.hasOwnProperty.call(automatedFiling, 'status')) {
        isBtnDisabled = disableBtnStatuses.includes(automatedFiling.status)
      }
      return isBtnDisabled
    },
  },
  created() {
    if (this.goodStandingOrderTask) {
      this.fetchAutomationPayloadToValidate(this.goodStandingOrderTask);
    }
  },
};
</script>

<style lang="scss" scoped>
.zg-btn-default {
  width: 100%;
  margin-top: 0.625rem !important;
  margin-bottom: 0rem !important;
}

.zg-btn-default.focus,
.zg-btn-default:focus,
.zg-btn-default:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
</style>
