 <template>
  <div>
    <h2 class="header">Standard Good Standing - {{transOrRecurring}}</h2>
    <div class="box">
      <div class="col-md-12">
        <form>
          <div class="form-row">
            <div class="form-group col-md-12">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="waiveFeeCheck" v-model="waivePrice">
                <label class="form-check-label" for="waiveFeeCheck">
                  Waive ZenBusiness Fee (for qualifying WFG customers)
                </label>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="price">ZenBusiness Price</label>
              <input v-model="productData.order_item.price" class="form-control" placeholder="100" name="Price" id="price" disabled/>
            </div>
            <div class="form-group col-md-6">
              <label for="fees">State Fees</label>
              <input v-model="fees" class="form-control" placeholder="15" name="Fees" id="fees"/>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { orderCreateProductMixin } from '@/components/mixins';

export default {
  name: 'standardGoodStandingCreate',

  props: ['pricing', 'jurisdiction'],

  mixins: [orderCreateProductMixin],

  data() {
    return {
      productData: {
        order_data: {
          service: 'standard_good_standing',
          jurisdiction: this.jurisdiction,
          formation_jurisdiction: this.jurisdiction
        },
        order_item: {
          service: 'standard_good_standing',
          jurisdiction: this.jurisdiction,
          price: this.pricing.price,
          fees: this.pricing.fees
        }
      }
    };
  }
};
</script>
