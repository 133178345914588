<template>
  <div>
    <h2 class="header">Future File Date</h2>
    <div class="box">
      <div class="col-md-12">
        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="price">ZenBusiness Price</label>
              <input v-model="price" class="form-control" placeholder="10" name="Price" id="price" disabled/>
            </div>
            <div class="form-group col-md-6">
              <label for="fees">State Fees</label>
              <input v-model="fees" class="form-control" placeholder="0" name="Fees" id="fees"/>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Select Future Filing Date</label>
              <datepicker v-model="futureFilingDate" @selected="setFutureFilingDate"></datepicker>
            </div>
            <div class="form-group col-md-6">
              <label>Filing Action</label>
              <select v-model="productData.order_data.filing_action" class="form-control" id="amendment-type">
                <option disabled>Please select one</option>
                <option value="immediate">Immediate (File papework now with the effective future filing date)</option>
                <option value="hold">Hold (Do not file papework until future filing date)</option>
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker';
import { orderCreateProductMixin } from '@/components/mixins';

export default {
  name: 'futureFileDateCreate',

  props: ['pricing', 'jurisdiction'],

  mixins: [orderCreateProductMixin],

  components: {
    Datepicker
  },

  data() {
    return {
      futureFilingDate: null,
      productData: {
        order_data: {
          service: 'future_file_date',
          jurisdiction: this.jurisdiction,
          future_filing_date: null,
          filing_action: null,
          entity_name: this.entityName
        },
        order_item: {
          service: 'future_file_date',
          jurisdiction: this.jurisdiction,
          price: this.pricing.price,
          fees: this.pricing.fees
        }
      }
    };
  },

  methods: {
    setFutureFilingDate(datePicked) {
      this.productData.order_data.future_filing_date = datePicked.toISOString().split('T')[0];
    }
  }
};
</script>
