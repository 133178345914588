<template>
  <div>
    <h2 class="header">Worry Free Service - {{transOrRecurring}}</h2>
    <div class="box">
      <div class="col-md-12">
        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Pricing Plan</label>
              <select v-model="period" class="form-control" id="wfg-period">
                <option disabled>Please select one</option>
                <option value="1">Annual (${{annual_price}}/year)</option>
                <option value="12">Monthly (${{monthly_price}}/month)</option>
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { orderCreateProductMixin } from '@/components/mixins';
import { mapActions } from 'vuex';

const ANNUAL_WFC_VALUE = '1';

export default {
  name: 'worryFreeServiceCreate',

  props: ['pricing', 'jurisdiction'],

  mixins: [orderCreateProductMixin],

  data() {
    return {
      productData: {
        order_data: {
          service: 'worry_free_service',
          jurisdiction: this.jurisdiction,
          formation_jurisdiction: this.jurisdiction
        },
        order_item: {
          service: 'worry_free_service',
          jurisdiction: this.jurisdiction,
          price: this.pricing.price,
          fees: this.pricing.fees,
          term: this.pricing.term,
          period: this.pricing.period
        },
      },
      localPrice: undefined,
      localPrices: []
    };
  },
  computed: {
    period: {
      get() {
        return this.productData.order_item.period;
      },
      set(value) {
        this.productData.order_item.period = ~~value;
        // if pricing was provided as an initialization parameter then this is not a plan
        if (this.pricing) {
          if (this.localPrice && value === ANNUAL_WFC_VALUE) {
            this.productData.order_item.price = this.localPrice.related_service_pricing.amount;
          } else {
            const { prices } = this.pricing;
            if (value in prices) {
              this.price = prices[value];
            } else {
              // Hacky mechanism to add a pricing plan. The value field of an option must be set to the desired price. The
              // period defaults to 1. The base issue here is that by re-activating the $99 price in the DB, the RA price
              // for zenbusiness.com and zenreg is affected.
              // todo: Need have pricing specifically for ZenGarden, price_group not the likely solution.
              this.price = value;
              this.productData.order_item.period = 1;
            }
          }
        } else {
          // otherwise, its a plan which gets pricing from a different place
          if (this.plans[this.planService].llc.prices) {
            this.price = this.plans[this.planService].llc.prices[value];
          } else {
            this.price = this.plans[this.planService].llc.price;
          }
        }
      }
    },
    annual_price: {
      get() {
        if (this.localPrice?.related_service_pricing?.amount) {
          return this.localPrice.related_service_pricing.amount
        }

        return this.pricing.prices['1'];
      }
    },
  },
  methods: {
    ...mapActions('orderCreate', [
      'fetchProductPrices',
    ]),
    setOrderItem() {
      if(this.localPrice) {
        this.productData.order_item.price = this.localPrice.related_service_pricing.amount;

        // Logic for setting term and period.
        const intervalLabel = this.localPrice.related_service_pricing.interval;
        const { term, period } = this.getTermAndPeriodAccordingIntervalLabel(intervalLabel);
        this.productData.order_item.term = term;
        this.productData.order_item.period = period;
      }
    }
  },
  created() {
    const product = 'worry_free_service';
    this.fetchProductPrices({ product, affiliate: 'pf' })
      .then((data) => {
        if (data.success) {
          this.localPrices = data.prices;

          if (this.localPrices?.length === 1) {
            this.localPrice = this.localPrices[0];
            this.setOrderItem();
          }
        }
      });
  }

};
</script>
