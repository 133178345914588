<template>
    <div>
      <h2 class='header'>EIN Creation</h2>
      <div class='box'>
        <div class='col-md-12'>
          <form>
            <div class='form-row py-1'>
              <div class='form-group col-md-6'>
                <label for='ein-price'>ZenBusiness Price</label>
                <input v-model='price' class='form-control' placeholder='0' name='Price' id='ein-price' />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { orderCreateProductMixin } from '@/components/mixins';
  export default {
    name: 'ein',
    props: ['pricing', 'jurisdiction', 'domesticJurisdiction', 'businessEntity'],
    mixins: [orderCreateProductMixin],
    data() {
      return {
        productData: {
          isEinCreation: true,
          order_data: {
            service: 'ein_creation',
            standalone: true,
            jurisdiction: this.jurisdiction,
            domestic_jurisdiction: this.domesticJurisdiction,
            type: this.businessEntity?.business_entity_type,
            entity_type: this.businessEntity?.business_entity_type,
            is_new_business: true,
            reason: 'Started New Business',
            member_count: null,
            business_purpose: '',
            address: {
              address_1: '',
              address_2: '',
              city: '',
              county: '',
              state: '',
              country: '',
              zip: '',
              lat: 0,
              lng: 0,
              address_is_google: false,
              email: '',
              phone: ''
            },
            mailing_address: {
              address_1: '',
              address_2: '',
              city: '',
              county: '',
              state: '',
              country: '',
              zip: '',
              lat: 0,
              lng: 0,
              address_is_google: false,
              email: '',
              phone: ''
            },
            principal_address: {
              address_1: '',
              address_2: '',
              city: '',
              county: '',
              state: '',
              country: '',
              zip: '',
              lat: 0,
              lng: 0,
              address_is_google: false,
              email: '',
              phone: ''
            },
            county: '',
            phone: '',
            trade_names: '',
            tax_info: {},
            business_data: {
                activity: {
                    level_1: '',
                    level_2: '',
                    level_3: '',
                    level_4: '',
                    level_5: '',
                    specify: ''
                },
                corp_care_of: '',
                corp_closing_month: 'December'
            },
            responsible_party: {},
            contact: {
                address_1: '',
                address_2: '',
                city: '',
                county: '',
                state: '',
                country: '',
                zip: '',
                lat: 0,
                lng: 0,
                address_is_google: false,
                email: '',
                phone: '',
                first_name: '',
                middle_name: '',
                last_name: '',
                suffix: '',
                company_name: '',
                care_of: '',
                outstanding_judgments: null,
                is_principal: true
            },
            entity_name: this.businessEntity?.business_entity_name,
            completed_action: 'Complete Products: Set Start EIN',
            completed_url: 'start-ein',
            completed_action_index: 5200
          },
          order_item: {
            service: 'ein_creation',
            jurisdiction: this.jurisdiction,
            price: this.pricing.price,
            coupon: 0,
            fees: this.pricing.fees,
            is_add_on: false,
            affiliate_pricing_uuid: null
          }
        }
      };
    },
    methods: {
    }
  };
  </script>
