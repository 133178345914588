<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <div id="business-entity-menu" class="menu">
          <h3>Actions</h3>
          <div class="btn btn-info" @click="handleSaveClick">Save</div>
          <div class="btn btn-dark" @click="handleCancelClick">Cancel</div>
        </div>
      </div>
      <div class="col-md-8">
        <h2 class="heading"></h2>
        <form>
          <div class="row">
            <div class="col-md-12">
              <dt v-bind:class="{'text-danger': !validateEmail(createdBy)}">Created By</dt>
              <dd><input class="form-control" placeholder="jane.doe@zenbusiness.com (REQUIRED)" v-model="createdBy"/></dd>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <dt>Note Content</dt>
              <dd><textarea class="form-control" v-model="noteContent"/></dd>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import { inputValidationsMixin } from '../mixins';

export default {
  name: 'OrderNoteCreate',

  mixins: [inputValidationsMixin],

  props: ['uuid'],

  computed: {
    createdBy: {
      get() {
        return this.$store.state.orders.newOrderNote.order_note_created_by;
      },
      set(value) {
        this.$store.commit('orders/setOrderNoteCreatedBy', value);
      }
    },
    noteContent: {
      get() {
        return this.$store.state.orders.newOrderNote.order_note_content;
      },
      set(value) {
        this.$store.commit('orders/setOrderNoteContent', value);
      }
    }
  },

  methods: {
    handleSaveClick() {
      if (this.validateEmail(this.createdBy)) {
        this.$store.dispatch('orders/createOrderNote', { uuid: this.uuid })
          .then(() => {
            this.$store.dispatch('orders/fetchOrder', { uuid: this.uuid });
            this.$router.push({ name: 'OrderNotesView', params: { uuid: this.uuid } });
          });
      }
    },
    handleCancelClick() {
      this.$store.dispatch('orders/fetchOrder', { uuid: this.uuid })
        .then(() => {
          this.$router.push({ name: 'OrderNotesView', params: { uuid: this.uuid } });
        });
    }
  }
};
</script>
<style lang="scss" scoped>
  h3 {
    font-size: 10pt;
    margin: 5px 0 0 0;
  }
  #business-entity-menu {
    margin-top: 20px;
  }
  .menu {
    border: solid 1px #dee2e6;
    border-radius: 5px;
    padding: 10px;
  }
  div .btn {
    width: 100%;
    margin-top: 10px;
  }
</style>
