<template>
    <div class="modal">
      <div v-if="!showSuccessScreen && !isSubmitError" class="modal-dialog modal-dialog-centered">
        <div class="modal-content min-width-fit-content">
          <div class="modal-header">
            <h5 class="modal-title">Undo the Cancellation of {{ service }}?</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="handleCloseModal"></button>
          </div>
          <div class="modal-body">
            This will resume the ${{ price }} {{ period | readableRecurringPeriod }} subscription of {{ service }}.
          </div>
          <div class="modal-body">
            <input type="checkbox" v-model="confirmCancelChecked" />
            Confirm undo cancellation? 
          </div>
          <div class="modal-footer">
            <button class="btn btn-danger action" @click="handleConfirmUndoCancel" :disabled="!confirmCancelChecked">
                Yes, undo cancellation
                <transition name="btn-thinking" v-if="isSubmitting" appear>
                    <span class="btn__icon">
                        <icon-spinner />
                    </span>
                </transition>
            </button>
        </div>
        </div>
      </div>
      <div v-if="showSuccessScreen" class="modal-dialog modal-dialog-centered">
        <div class="modal-content min-width-fit-content">
          <div class="modal-header">
            <h5 class="modal-title">Undo Cancellation Confirmed</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="handleCloseModal"></button>
          </div>
          <div class="modal-body">
            Your request was successful. Please note that the subscription's status may be 'active_pending' for a few minutes after the request has been made. If the status does not update to be 'active' after 15 minutes, please make a ticket.
          </div>
          <div class="modal-footer">
            <button class="btn btn-info" @click="handleCloseModal">Ok</button>
          </div>
        </div>
      </div>
      <div v-if="isSubmitError" class="modal-dialog modal-dialog-centered">
        <div class="modal-content min-width-fit-content">
          <div class="modal-header">
            <h5>Oops!</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="handleCloseModal"></button>
          </div>
          <div class="modal-body">
            <p>There has been an error, please try again. If the error persists, please open an issue.</p><br>
            <p>Error Code: {{ error.status }}</p>
            <p>Message: {{ error.statusText }}</p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-info" @click="handleCloseModal">Ok</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
import { readableRecurringPeriodMixin } from '../../mixins';
import IconSpinner from '@/components/common/images/IconSpinner'

  export default {
    name: 'ConfirmUndoCancellationModal',
    mixins: [readableRecurringPeriodMixin],
    props: ['service', 'price', 'period', 'uuid'],
    components: {
        IconSpinner
    },
    data() {
      return {
        confirmCancelChecked: false,
        isSubmitting: false,
        isSubmitError: false,
        showSuccessScreen: false,
        error: ""
      }
    },

    methods: {
        handleConfirmUndoCancel() {
        this.isSubmitting = true
        this.$store.dispatch('orders/submitOrderBillingItemChange', { uuid: this.uuid, changeType: 'active' })
        .then((response) => {
            this.isSubmitting = false
            this.$emit('refresh');
            if(response.status === 202){
                this.showSuccessScreen = true
            } else {
                this.error = response
                this.isSubmitError = true
            }
        });
      },
      handleCloseModal(){
        this.$emit('close')
      }  
    }
  
  };
  </script>
  
  <style src="vue-multiselect/dist/vue-multiselect.min.css"/>
  
  <style lang="scss" scoped>
    .badge {
      text-transform: capitalize;
    }
  
    .invalid-input-message {
      color: red;
    }
  
    input:invalid{
      border: 1px solid red;
    }
  </style>
  