import { render, staticRenderFns } from "./amendment-service-view.vue?vue&type=template&id=e790a948&scoped=true"
import script from "./amendment-service-view.vue?vue&type=script&lang=js"
export * from "./amendment-service-view.vue?vue&type=script&lang=js"
import style0 from "./amendment-service-view.vue?vue&type=style&index=0&id=e790a948&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e790a948",
  null
  
)

export default component.exports