import { render, staticRenderFns } from "./UpdateAddressOrContactModal.vue?vue&type=template&id=2c5d0dea&scoped=true"
import script from "./UpdateAddressOrContactModal.vue?vue&type=script&lang=js"
export * from "./UpdateAddressOrContactModal.vue?vue&type=script&lang=js"
import style0 from "./UpdateAddressOrContactModal.vue?vue&type=style&index=0&id=2c5d0dea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c5d0dea",
  null
  
)

export default component.exports