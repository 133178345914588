<template>
  <div>
    <div class="row heading">
      <div class="col">
        <h2>Worry Free Service Guarantee</h2>
      </div>
      <div class="row">
        <div class="col">
          <p>No data required from client - processor must check State records to Activate</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WorryFreeServiceView'
};
</script>

<style scoped>

</style>
