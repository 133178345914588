<template>
  <Modal>
    <template v-slot:title><h5>Confirmation</h5></template>
    <template v-slot:body>
      <div>The Automated filing status is currently "ra_data_conflict". Restarting automation means that the order will be placed with the data the state has on file. Do you want to proceed?</div>
    </template>
    <template v-slot:footer>
      <div class="fullwidth d-flex justify-content-end">
        <ZgButton @click.once="$emit('close')" class="btn btn-sm btn-danger action"><template v-slot:title> No </template></ZgButton>
        <ZgButton 
        @click.once="confirmRaConflictBypass" 
        class="btn btn-sm btn-success action ml-2"
        >
        <template v-slot:title> Yes, restart it. </template>
      </ZgButton>
      </div>
    </template>
  </Modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import 'vue-datetime/dist/vue-datetime.min.css'
import ZgButton from '../common/Generics/ZgButton.vue';
import Modal from '@/components/common/Modal';
export default {
  name: 'RaAdressConflictBypassModal',
  props: ['reportType', 'formationOrderTask'],
  components: {
    ZgButton,
    Modal
  },
  computed: {
    ...mapGetters({
      orderTasks: 'orders/getOrderTasks'
    }),
  },
  methods: {
    ...mapActions({
      startAutomatedFiling: 'orders/startAutomatedFiling',
    }),
    confirmRaConflictBypass(){
      const uuid = this.formationOrderTask.order_task_uuid;
      this.startAutomatedFiling({ uuid, test: this.reportType, bypassRaAddressConflict:true, reloadPage: true });
    }
  }
};
</script>
<style>
  .ml-2 { 
    margin-left:.5rem!important;
  }
</style>