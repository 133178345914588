<template>
  <div class="row">
    <div class="col-md-12 col-lg-8">
      <form>
        <label for="jurisdiction_pin">Agency Account Entity Access PIN</label>
        <input name="jurisdiction_pin" class="form-control" v-model.trim="jurisdictionPin" maxlength="12" :disabled="pinSubmitted"/>
        <ZgButton v-if="!pinSubmitted" @click="updatePin" class="zg-btn-default zg-text-sm" :disabled="isPinSubmitting || jurisdictionPin.length==0" type="button">
          <template v-slot:title><i class="fa fa-save"></i>&nbsp;Save</template>
        </ZgButton>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ZgButton from '@/components/common/Generics/ZgButton.vue'

export default {
  name: 'BusinessEntityJurisdictionPinEntry',
  components: {ZgButton},
  props: {
    jurisdiction: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      jurisdictionPin: '',
      jurisdictionFulfillmentUuid: null,
      isPinSubmitting: false,
      pinSubmitted: false
    };
  },
  created () {
    this.fetchBusinessEntityJurisdictionFulfillment({'jurisdiction': this.jurisdiction})
      .then(() => {
        let currentRecord = this.currentFulfillmentRecord(this.jurisdiction.jurisdiction_uuid)
        if (currentRecord) {
          this.jurisdictionPin = currentRecord['agency_account_entity_access_pin']
          this.jurisdictionFulfillmentUuid = currentRecord['jurisdiction_fulfillment_uuid']
        }
      })
  },
  computed: {
    ...mapGetters({
        currentFulfillmentRecord: 'fulfillment/getBusinessEntityJurisdictionFulfillment'
    }),
  },
  methods: {
    ...mapActions({
      'fetchBusinessEntityJurisdictionFulfillment': 'fulfillment/fetchBusinessEntityJurisdictionFulfillment',
      'updateBusinessEntityJurisdictionPin': 'fulfillment/updateBusinessEntityJurisdictionFulfillment',
      'createBusinessEntityJurisdictionPin': 'fulfillment/createBusinessEntityJurisdictionFulfillment'
    }),
    updatePin () {
      const postProcess = () => {
        this.isPinSubmitting = true
        this.pinSubmitted = true
        const currentRecord = this.currentFulfillmentRecord(this.jurisdiction.jurisdiction_uuid)
        if (currentRecord) {
          this.jurisdictionPin = currentRecord['agency_account_entity_access_pin']
          this.jurisdictionFulfillmentUuid = currentRecord['jurisdiction_fulfillment_uuid']
        }
      }
      this.isPinSubmitting = true;
      // Update via actual update
      if (this.jurisdictionFulfillmentUuid) {
        const args = {
          'jurisdiction': this.jurisdiction,
          'jurisdictionFulfillmentUuid': this.jurisdictionFulfillmentUuid,
          'newPin': this.jurisdictionPin
        };
        this.updateBusinessEntityJurisdictionPin(args).then(postProcess);
      }
      // Update via creation
      else {
        const args = {
          'jurisdiction': this.jurisdiction,
          'newPin': this.jurisdictionPin
        };
        this.createBusinessEntityJurisdictionPin(args).then(postProcess);
      }
    }
  }

};
</script>

<style lang="scss" scoped>
  label {
    color: $clr-nav-primary;
    font-weight: bold;
    font-size: .875em;
    grid-column: 1/2;
    grid-row: 1;
  }
  input {
    grid-column: 1;
    grid-row: 2;
  }
  .zg-btn {
    grid-column: 2;
    grid-row: 2;
    min-height: 100%;
  }
  form {
    display: grid;
    grid-template-columns: 85% 15%;
    grid-template-rows: auto 1fr;
    grid-row-gap: 0.5em;
    margin-left: 0.25em;
  }
</style>
