<template>
  <div class="mt-2">
    <div class="mt-4 position-relative" v-if="isLoading">
      <LoadingWrapper
        size="sm"
        :isLoading="isLoading" /> 
    </div>
    <template v-else>
      <h1 class="heading">DBA Entity</h1>
      <h2 class="header">Order Details</h2>
      <div class="box">
        <div class="row">
          <div class="col">
            <dt>DBA Name</dt>
            <dd>{{ dbaEntity.name }}</dd>
          </div>
          <div class="col">
            <dt>City</dt>
            <dd>{{ dbaOrderTask.city }}</dd>
          </div>
          <div class="col">
            <dt>County</dt>
            <dd>{{ dbaOrderTask.county }}</dd>
          </div>
          <div class="col">
            <dt>Jurisdiction</dt>
            <dd>{{ dbaOrderTask.jurisdiction }}</dd>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <dt>Industry</dt>
            <dd>{{ getBusinessIndustry }}</dd>
          </div>
          <div class="col">
            <dt>Filing Number</dt>
            <dd>{{ jurisdiction.jurisdiction_filing_number }}</dd>
          </div>
          <div class="col">
            <dt>Filing Date</dt>
            <dd>{{ jurisdiction.jurisdiction_filing_date }}</dd>
          </div>
        </div>
      </div>
      <hr/>
      <h1 class="header">Entity Details</h1>
      <BusinessEntityView />
    </template>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import BusinessEntityView from '../../business-entities/business-entity-view';
import LoadingWrapper from '@/components/common/LoadingWrapper';

export default {
  name: 'DbaEntityView',

  props: ['orderTask'],

  components: { 
    BusinessEntityView,
    LoadingWrapper
  },

  data () {
    return {
      isLoading: true
    }
  },

  computed: {
    ...mapState({
      dbas: (state) => state.dba.dbas,
      jurisdictions: (state) => state.businessEntities.jurisdictions
    }),
    ...mapGetters({
      getAddressOfType: 'businessEntities/getAddressOfType',
      getBusinessIndustry: 'businessEntities/getBusinessIndustry',
      getUuidBusinessEntity: 'orders/getUuidBusinessEntity'
    }),
    physicalAddress() {
      return this.getAddressOfType('physical');
    },
    dbaEntity() {
      return this.dbas.find((dba) => dba.order_task_uuid === this.orderTask.order_task_uuid) || {};
    },
    jurisdiction() {
      return this.jurisdictions.find((jur) => jur.jurisdiction_uuid === this.dbaEntity.jurisdiction_uuid) || {};
    },
    dbaOrderTask() {
      return this.orderTask.order_task_data;
    }
  },
  methods: {
    ...mapActions({
      fetchBusinessEntity: 'businessEntities/fetchBusinessEntity',
      fetchDbas: 'dba/fetchDbas'
    })
  },
  async created() {
    await this.fetchBusinessEntity({ uuid: this.getUuidBusinessEntity });
    await this.fetchDbas({ uuid: this.getUuidBusinessEntity });
    this.isLoading = false;
  }
};
</script>
<style lang="scss" scoped>
  dt {
    font-size: em(13);
    color: $clr-font-secondary
  }
</style>
