<template>
  <div>
    <h2 class="header">Domain Name Privacy - {{transOrRecurring}}</h2>
    <div class="box">
      <div class="col-md-12" v-if="!isWebToolsSelected">
        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Pricing Plan</label>
              <select v-model="period" class="form-control" id="ra-period">
                <option disabled>Please select one</option>
                <option value="1">Annual (${{ annual_price }}/year)</option>
              </select>
              <label>Domain Selection</label>
              <select class="form-control" v-model="productData.order_data.domain_name" id="email-domain_name">
                <option selected disabled value="undefined">Please select one</option>
                <option v-for="domain in filteredDomains" v-bind:key="domain.domain_name" :value="domain.domain_name"> {{ domain.domain_name }} </option>
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { orderCreateProductMixin } from '@/components/mixins';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'domainPrivacyCreate',

  props: ['pricing', 'jurisdiction'],

  mixins: [orderCreateProductMixin],

  data() {
    return {
      productData: {
        order_data: {
          service: 'domain_name_privacy',
          jurisdiction: this.jurisdiction,
          standalone: false,
          is_auto_included: false,
          domain_name: null
        },
        order_item: {
          service: 'domain_name_privacy',
          jurisdiction: this.jurisdiction,
          price: this.pricing.price,
          fees: this.pricing.fees,
          term: this.pricing.term,
          period: this.pricing.period
        }
      }
    };
  },
  computed: {
    isWebToolsSelected: {
      get() {
        return this.$store.state.orderCreate.selectedProducts.includes('website_tools_bundle');
      }
    },
    ...mapGetters( {
      businessEntityUuid: 'businessEntities/getBusinessEntityUuid',
      domainNameSelected: 'orderCreate/getDomainSelected',
    }),
    ...mapState({
      domains: (state) => state.domains.domains.results,
    }),
    filteredDomains: {
      get() {
        try {
          return this.domains.filter((domain) => (!domain.is_external && domain.active));
        } catch (err) {
          return [];
        }
      }
    },
    isValid: function() {
      const domain_name = this.productData.order_data.domain_name
      return (domain_name!== null && domain_name !== '') || this.isWebToolsSelected
    }
  },
  watch: {
    domainNameSelected(value) {
      if (value) {
        this.productData.order_data.domain_name = this.domainNameSelected;
      }
    },
  },
  created () {
    if(this.$store.state.orderCreate.selectedProducts.includes('website_tools_bundle')) {
      this.productData.order_item.price = 0
      this.productData.order_item.fees = 0
    }
  },
  methods: {
    ...mapActions({
      fetchDomains: 'domains/fetchDomains',
    }),
  },
  mounted() {
    this.fetchDomains({ businessEntityUuid: this.businessEntityUuid });
  }
};
</script>
