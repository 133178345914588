import { render, staticRenderFns } from "./dao-designation.vue?vue&type=template&id=58033a07&scoped=true"
import script from "./dao-designation.vue?vue&type=script&lang=js"
export * from "./dao-designation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58033a07",
  null
  
)

export default component.exports