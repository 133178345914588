export default {
  AK: 'https://www.commerce.alaska.gov/web/',
  AL: 'http://www.sos.state.al.us/',
  AZ: 'https://www.azsos.gov/',
  AR: 'http://www.sos.arkansas.gov/',
  CA: 'http://www.sos.ca.gov/',
  CO: 'http://www.sos.state.co.us/',
  CT: 'https://portal.ct.gov/',
  DE: 'https://corp.delaware.gov/',
  FL: 'http://www.dos.state.fl.us/',
  GA: 'http://sos.ga.gov/',
  HI: 'http://www.ehawaii.gov/dakine/index.html',
  ID: 'http://www.sos.idaho.gov/',
  IL: 'http://www.cyberdriveillinois.com/',
  IN: 'http://www.in.gov/sos/',
  IA: 'http://www.sos.state.ia.us/',
  KS: 'http://www.kssos.org/main.html',
  KY: 'http://www.sos.ky.gov/',
  LA: 'http://www.sos.louisiana.gov/',
  ME: 'http://www.state.me.us/sos/',
  MD: 'http://www.sos.state.md.us/',
  MA: 'http://www.sec.state.ma.us/index.htm',
  MI: 'http://www.michigan.gov/lara/0,4601,7-154-61343---,00.html',
  MN: 'http://www.sos.state.mn.us/index.aspx?page=1',
  MS: 'http://www.sos.ms.gov/Pages/default.aspx',
  MO: 'http://www.sos.mo.gov/Default.asp',
  MT: 'http://sos.mt.gov/',
  NE: 'http://www.sos.state.ne.us/dyindex.html',
  NV: 'https://www.nvsos.gov/sos',
  NH: 'http://sos.nh.gov/',
  NJ: 'http://www.nj.gov/state/',
  NM: 'http://www.sos.state.nm.us/',
  NY: 'https://appext20.dos.ny.gov/ecorp_public/f?p=2201:17:0::NO',
  NC: 'https://www.sosnc.gov/',
  ND: 'http://www.nd.gov/sos/',
  OH: 'https://www.sos.state.oh.us/#gref',
  OK: 'https://www.sos.ok.gov/',
  OR: 'http://www.sos.state.or.us/',
  PA: 'http://www.dos.pa.gov/Pages/default.aspx#.VcIkXxNVhBc',
  RI: 'http://sos.ri.gov/',
  SC: 'http://www.scsos.com/',
  SD: 'https://sdsos.gov/default.aspx',
  TN: 'https://sos.tn.gov/',
  TX: 'http://www.sos.state.tx.us/index.html',
  UT: 'http://corporations.utah.gov/',
  VT: 'http://www.sec.state.vt.us/',
  VA: 'http://www.scc.virginia.gov/clk/index.aspx',
  WA: 'http://www.sos.wa.gov/Default.aspx',
  WV: 'https://sos.wv.gov/Pages/default.aspx',
  WI: 'http://www.sos.state.wi.us/',
  WY: 'http://soswy.state.wy.us/'
};
