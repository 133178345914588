<template>
  <div>
    <h2 class="header">Registered Agent - {{transOrRecurring}}</h2>
    <div class="box">
      <div class="col-md-12">
        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Pricing Plan</label>
              <select v-model="localPrice"
                class="form-control" id="ra-price"
                @change="setOrderItem"
              >
                <option disabled :value="undefined">Please select one</option>
                <option v-for="optionPrice in localPrices" v-bind:key="optionPrice.uuid" :value="optionPrice">
                  {{ optionPrice.related_affiliate.name | capitalizeAllWordsAndReplaceUnderscore }} {{ optionPrice.related_service_pricing.interval | capitalizeAllWordsAndReplaceUnderscore }} {{ `($${optionPrice.related_service_pricing.amount}/${optionPrice.related_service_pricing.interval==='annual'?'year':'month'})` }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-row mt-1">
            <div class="form-group col-md-12">
              <div class="form-check">
                <input v-model="applyCoupon" class="form-check-input" type="checkbox" id="applyCoupon" @change="setCoupon">
                <label class="form-check-label" for="applyCoupon">
                  Apply $100 off first year of subscription.
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { orderCreateProductMixin } from '@/components/mixins';
import { capitalizeAndReplaceUnderscoreMixin } from '@/components/mixins';

export default {
  name: 'registeredAgentServiceCreate',

  props: ['pricing', 'jurisdiction', 'coupons'],

  mixins: [orderCreateProductMixin, capitalizeAndReplaceUnderscoreMixin],

  data() {
    return {
      productData: {
        order_data: {
          service: 'registered_agent_service',
          jurisdiction: this.jurisdiction,
          formation_jurisdiction: this.jurisdiction
        },
        order_item: {
          service: 'registered_agent_service',
          jurisdiction: this.jurisdiction,
          price: undefined,
          fees: undefined,
          term: undefined,
          period: undefined,
          coupon_uuid: undefined,
        }
      },
      coupon: this.coupons.find((c) => c.external_coupon_id === '100_off_first_year'),
      localPrice: undefined,
      localPrices: [],
      localFees: []
    };
  },
  computed: {
    ...mapGetters({
      getAffiliateOrDefault: 'accounts/getAffiliateOrDefault',
    }),
  },
  methods: {
    ...mapActions('orderCreate', [
      'fetchProductFees',
      'fetchProductPrices',
      'clearProductFeesAndPrices',
    ]),
    setOrderItem() {
      if(this.localPrice) {
        this.productData.order_item.price = this.localPrice.related_service_pricing.amount;

        // Logic for setting term and period.
        // According to https://github.com/zenbusiness/zenapi/blob/48af3c3c4563300f80f4ef19bee4d96428bd437b/service_products/services.py#L449
        const intervalLabel = this.localPrice.related_service_pricing.interval;
        const old_period = this.localPrice.related_service_pricing.period;

        const { term, period } = this.getTermAndPeriodAccordingIntervalLabel(intervalLabel);

        this.productData.order_item.term = term;

        if(intervalLabel === 'annual') {
          this.productData.order_item.period = period;
        } else {
          this.productData.order_item.period = old_period || period;
        }

      }
    }
  },
  created() {
    const product = 'registered_agent_service';
    this.fetchProductPrices({ product, affiliate: this.getAffiliateOrDefault })
      .then((data) => {
        if (data.success) {
          this.localPrices = data.prices;
          if (this.localPrices?.length === 1) {
            this.localPrice = this.localPrices[0];
            this.setOrderItem();
          }
        }
      });
    this.fetchProductFees({ product, jurisdiction: this.jurisdiction })
      .then((data) => {
        if (data.success) {
          this.localFees = data.fees;
          const fee = this.localFees[0];
          this.productData.order_item.fees = parseFloat(fee.amount);
        }
      });
  }
};
</script>
