<template>
  <tr>
    <td>{{ billingInvoice.date | moment("MMMM DD YYYY") }}</td>
    <td>{{ billingInvoice.amount }}</td>
    <td>{{ billingInvoice.paid }}</td>
    <td>{{ billingInvoice.amount_refunded_summary.toFixed(2) }}</td>
    <td>
      <button
        class="btn btn-sm btn-danger"
        :disabled="!isRefundBtnEnabled"
        @click="handleRefundClick"
        v-if="isRefundable">Refund</button>
    </td>
    <RefundInvoiceItemModal v-if="refundClicked" @close="refundClicked = false" @canceled="refresh"
      v-bind:order_uuid="this.uuid"
      v-bind:invoice_uuid="this.billingInvoice.uuid"
      v-bind:billing_invoice="this.billingInvoice"
      v-bind:coupons="this.billingInvoice.coupon_uuids"
      v-bind:refundable_amount="this.refundableAmount"
      v-bind:refund_reasons="this.refundReasons"
      :deferredSubscriptionServices="deferredSubscriptionServices"
      :deferredTransactionalServices="deferredTransactionalServices"
      @setRefundBtnEnabledStatus="setRefundBtnEnabledStatus" />
  </tr>
</template>

<script>
import RefundInvoiceItemModal from './refund-invoice-item-modal';

export default {
  name: 'OrderBillingListInvoiceItem',
  components: { RefundInvoiceItemModal },
  props: ['billingInvoice', 'uuid', 'refundReasons', 'subscriptionBillingItems', 'transactionalBillingItems'],

  data() {
    return {
      isRefundBtnEnabled: true,
      refundClicked: false
    };
  },

  computed: {
    isRefundable() {
      return this.billingInvoice.amount - this.billingInvoice.amount_refunded_summary > 0 && this.billingInvoice.paid === true;
    },
    refundableAmount() {
      return parseFloat((this.billingInvoice.amount - this.billingInvoice.amount_refunded_summary).toFixed(2));
    },
    deferredSubscriptionServices() {
      return this.subscriptionBillingItems.filter(
        (billingItem) => billingItem.status === 'deferred'
      ).map(
        (billingItem) => billingItem.service
      );
    },
    deferredTransactionalServices() {
      return this.transactionalBillingItems.filter(
        (billingItem) => billingItem.status === 'deferred'
      ).map(
        (billingItem) => billingItem.service
      );
    }
  },

  methods: {
    handleRefundClick() {
      this.refundClicked = true;
    },
    refresh() {
      this.refundClicked = false;
      this.$store.dispatch('orders/fetchBillingInvoices', { uuid: this.uuid });
    },
    setRefundBtnEnabledStatus (val) {
      this.isRefundBtnEnabled = val
    }
  }

};
</script>

<style lang="scss" scoped>
  .amount {
    width: 65px;
  }
</style>
