<template>
  <div>
    <h2 class="header">CORP Annual Report - {{transOrRecurring}}</h2>
    <div class="box">
      <div class="col-md-12">
        <div class="alert alert-warning" role="alert" id="exception-text" v-if="hasFeeException">
          {{ this.pricing.exception_text }}
        </div>
        <form>
          <div class="form-row">
            <div class="form-group col-md-12">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="waiveFeeCheck" v-model="waivePrice">
                <label class="form-check-label" for="waiveFeeCheck">
                  Waive ZenBusiness Fee (for qualifying WFG customers)
                </label>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="price">ZenBusiness Price</label>
              <input v-model="price" class="form-control" placeholder="10" name="Price" id="price" disabled/>
            </div>
            <div class="form-group col-md-6">
              <label for="fees">State Fees
                <span v-if="hasFeeException" class="text-danger numval" id="exception-numerical-text">
                  * Must assing a numerical value
                </span>
              </label>
              <input v-model="fees" class="form-control" placeholder="50" name="Fees" id="fees"/>
              <span v-if="hasFeeException" class="text-danger numval" id="exception-process-text">
                If state fees are unknown at the time, assign a value of 0 and charge the customer for state fees
                with the 'Other' product after confirming the cost with the state.
              </span>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="compliance_event">Compliance Event</label>
              <select class="form-control" v-model="productData.order_data.compliance_event_uuid">
                <option value="undefined" disabled selected>Select a compliance event</option>
                <option v-bind:key="index" v-for="(complianceEvent, index) in complianceEvents" :value="complianceEvent.compliance_event_uuid">{{complianceEvent.event_date}}</option>
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { orderCreateProductMixin } from '@/components/mixins';

export default {
  name: 'corpAnnualReportCreate',

  props: ['pricing', 'jurisdiction'],

  mixins: [orderCreateProductMixin],

  computed: {
    ...mapState({
      complianceEvents: (state) => state.businessEntities.complianceEvents,
      businessEntityUUID: (state) => state.businessEntities.businessEntity.business_entity_uuid
    })
  },

  data() {
    return {
      productData: {
        order_data: {
          service: 'corp_annual_report',
          jurisdiction: this.jurisdiction,
          formation_jurisdiction: this.jurisdiction,
          compliance_event_uuid: ''
        },
        order_item: {
          service: 'corp_annual_report',
          jurisdiction: this.jurisdiction,
          price: this.pricing.price,
          fees: this.pricing.fees
        }
      }
    };
  },

  created() {
    this.$store.dispatch('businessEntities/fetchComplianceEventsByEntity', { businessEntityUuid: this.businessEntityUUID });
  }
};
</script>
<style lang="scss" scoped>
  span {
    font-size: smaller;
    font-weight: bold;
  }
</style>
