<template>
  <div>
    <h2 class="header">Other - {{transOrRecurring}}</h2>
    <div class="box">
      <div class="col-md-12">
        <form>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="service-description">Service Description</label>
              <input v-model="productData.order_data.service_description" class="form-control" placeholder="Certificate of Amendment" name="ServiceDescription" id="service-description" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="price">ZenBusiness Price</label>
              <zg-numeric-input class="form-control" placeholder="100" name="Price" id="Price" 
                v-model="price" 
                :currency="'USD'"
                :label="'price-create-order'"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="fees">State Fees</label>
              <zg-numeric-input class="form-control" placeholder="50" name="Fees" id="fees" 
                v-model="fees" 
                :currency="'USD'"
                :label="'fees-create-order'"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { orderCreateProductMixin } from '@/components/mixins';
import ZgNumericInput from '@/components/common/Generics/ZgNumericInput.vue';

export default {
  components: { ZgNumericInput },
  name: 'otherCreate',

  props: ['pricing', 'jurisdiction'],

  mixins: [orderCreateProductMixin],

  data() {
    return {
      productData: {
        order_data: {
          service: 'other',
          jurisdiction: this.jurisdiction,
          formation_jurisdiction: this.jurisdiction
        },
        order_item: {
          service: 'other',
          jurisdiction: this.jurisdiction,
          price: 0,
          fees: 0
        }
      }
    };
  },
  computed: {
    isValid() {
      const validations = []
      const description = this.productData.order_data.service_description
      validations.push(typeof(this.price)=='number' && this.price>=0);
      validations.push(typeof(this.fees)=='number' && this.fees>=0);
      validations.push(description && description !== '' && description != null);
      return validations.every(Boolean)
    }
  },
};
</script>
