<template>
  <div>
    <h1 class="heading">Banking Request Data</h1>
    <h2 class="header">Details</h2>
    <div class="box">
      <div class="row row-cols-2">
        <div class="col" v-if="!editing">
          <dt>EIN</dt>
          <dd >
            {{ businessEntity.business_entity_data.ein || orderTaskParam.order_task_data.ein }}
          </dd>
        </div>
        <div class="col">
          <dt>Phone</dt>
          <dd v-if="!editing">{{ orderTaskParam.order_task_data.phone }}</dd>
          <input v-else class="form-control" v-model="orderTaskParam.order_task_data.phone" />
        </div>
      </div>
      <div class="row" v-if="!editing">
        <div class="col" >
          <dt>Entity Name</dt>
          <dd>
            {{
              businessEntity.business_entity_name || orderTaskParam.order_task_data.business_entity_name
            }}
          </dd>
        </div>
        <div class="col">
          <dt>Jurisdiction</dt>
          <dd>{{ orderTaskParam.order_task_data.jurisdiction }}</dd>
        </div>
      </div>
      <div class="row row-cols-2">
        <div class="col" v-if="!editing">
          <dt>Account Number</dt>
          <dd>
            {{ orderTaskParam.order_task_data.account_number | masked }}
          </dd>
        </div>
        <div class="col">
          <dt>Business Years</dt>
          <dd v-if="!editing">
            {{ orderTaskParam.order_task_data.business_years }}
          </dd>
          <input v-else class="form-control" v-model="orderTaskParam.order_task_data.business_years" />
        </div>
      </div>
      <div class="row row-cols-2">
        <div class="col">
          <dt>Funding Amount</dt>
          <dd v-if="!editing">
            {{ orderTaskParam.order_task_data.funding_amount }}
          </dd>
          <input v-else class="form-control" v-model="orderTaskParam.order_task_data.funding_amount" />
        </div>
        <div class="col" v-if="!editing">
          <dt>Routing Number</dt>
          <dd>
            {{ orderTaskParam.order_task_data.routing_number | masked }}
          </dd>
        </div>
      </div>
      <div class="row row-cols-2">
        <div class="col">
          <dt>Is New Business</dt>
          <dd v-if="!editing">
            {{ orderTaskParam.order_task_data.is_new_business }}
          </dd>
          <div v-else class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              v-model="orderTaskParam.order_task_data.is_new_business"
            />
          </div>
        </div>
        <div class="col" v-if="!editing">
          <dt>Business Purpose</dt>
          <dd>
            {{
              businessEntity.business_entity_data.business_purpose ||
              orderTaskParam.order_task_data.business_purpose
            }}
          </dd>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col">
          <dt>Business DBA Name</dt>
          <dd v-if="!editing">
            {{ orderTaskParam.order_task_data.business_dba_name }}
          </dd>
          <input
            v-else
            class="form-control"
            v-model="orderTaskParam.order_task_data.business_dba_name"
          />
        </div>
        <div class="col">
          <dt>Incorporation State</dt>
          <dd v-if="!editing">
            {{ orderTaskParam.order_task_data.incorporation_state }}
          </dd>
          <input
            v-else
            class="form-control"
            v-model="orderTaskParam.order_task_data.incorporation_state"
          />
        </div>
      </div>
      <div class="row" v-if="!editing">
        <div class="col">
          <h5>Business Entity Address</h5>
          <Person v-if="getAddressOfType('physical')" :person="getAddressOfType('physical')" />
        </div>
      </div>
    </div>
    <h2 class="header">Bank Account Members</h2>
    <div
      class="box"
      v-for="member in orderTaskParam.order_task_data.bank_account_members"
      :key="member.ssn"
    >
      <div class="row row-cols-4">
        <div class="col">
          <dt>First Name</dt>
          <dd v-if="!editing">{{ member.first_name }}</dd>
          <input v-else v-model="member.first_name" class="form-control" />
        </div>
        <div class="col">
          <dt>Last Name</dt>
          <dd v-if="!editing">{{ member.last_name }}</dd>
          <input v-else class="form-control" v-model="member.last_name" />
        </div>
        <div class="col">
          <dt>Middle Name</dt>
          <dd v-if="!editing">{{ member.middle_name }}</dd>
          <input v-else class="form-control" v-model="member.middle_name" />
        </div>
      </div>
      <div class="row row-cols-4">
        <div class="col">
          <dt>Address</dt>
          <dd v-if="!editing">{{ member.address_1 }}</dd>
          <input v-else v-model="member.address_1" class="form-control" />
        </div>
        <div class="col">
          <dt>City</dt>
          <dd v-if="!editing">{{ member.city }}</dd>
          <input v-else class="form-control" v-model="member.city" />
        </div>
        <div class="col">
          <dt>State</dt>
          <dd v-if="!editing">{{ member.state }}</dd>
          <input v-else class="form-control" v-model="member.state" />
        </div>
      </div>
      <div class="row row-cols-4">
        <div class="col">
          <dt>Date of Birth</dt>
          <dd v-if="!editing">{{ member.date_of_birth }}</dd>
          <input v-else v-model="member.date_of_birth" class="form-control" type="text" />
        </div>
        <div class="col">
          <dt>Ownership Percentage</dt>
          <dd v-if="!editing">{{ member.ownership_percentage }}</dd>
          <input v-else class="form-control" v-model="member.ownership_percentage" />
        </div>
        <div class="col">
          <dt>Role</dt>
          <dd v-if="!editing">{{ member.role }}</dd>
          <input v-else class="form-control" v-model="member.role" />
        </div>
      </div>
      <div class="row row-cols-4">
        <div class="col" v-if="!editing">
          <dt>EIN</dt>
          <dd >{{ member.ein }}</dd>
        </div>
        <div class="col" v-if="!editing">
          <dt>SSN</dt>
          <dd >{{ member.ssn | masked }}</dd>
        </div>
      </div>
    </div>
    <br />
    <div>
      <div v-if="missingFields.length && !editing">
        <h2 class="header invalid-data-header">
          !!! This order has some invalid or missing fields !!!
        </h2>
        <div class="box">
          <ul>
            <li
              v-for="f in missingFields"
              v-bind:key="f"
              :class="f.split(' ').length > 1 ? `title-case` : `upper-case`"
            >
              {{ f | getFullNameField }}
            </li>
          </ul>
        </div>
      </div>
      <button class="btn btn-info btn-sm" @click="handleEditClick">Edit</button>
      <button class="btn btn-success btn-sm" v-if="editing" @click="handleSaveClick">Save</button>
      <button class="btn btn-danger btn-sm" v-if="editing" @click="handleCancelEditClick">
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Person from '../../common/person';

export default {
  components: { Person },
  name: 'BankingServiceView',
  props: ['orderTask', 'edit'],
  data() {
    return {
      editing: this.edit
    };
  },
  methods: {
    getAddressOfType(addressType) {
      const address = this.addresses.find((a) => a.address_type === addressType);
      return address ? address.address : {};
    },
    handleSaveClick() {
      this.editing = false;
      this.$store.dispatch('orders/updateOrderTask', {
        orderTaskParam: this.orderTask
      });
    },
    handleCancelEditClick() {
      this.editing = false;
    },
    handleEditClick() {
      this.editing = true;
    }
  },
  computed: {
    ...mapState({
      businessEntity: (state) => state.orders.businessEntity,
      addresses: (state) => state.businessEntities.addresses
    }),
    missingFields() {
      const { order_task_data } = this.orderTaskParam
      const requiredFields = [
        'ein',
        'business_entity_type',
        'entity_name',
        'business_purpose',
        'business_entity_address'
      ];
      const isDefined = (val) => {
        if (val instanceof Object) {
          return Object.keys(val).length !== 0;
        }
        return val !== undefined && val !== null && val.length;
      };

      const validateAccMember = () => {
        const missingFieldsAccMembers = [];
        const requiredFields = ['ssn', 'date_of_birth'];
        const {
          order_task_data: { bank_account_members }
        } = this.orderTaskParam;
        bank_account_members.forEach((member) => {
          requiredFields.forEach((field) => {
            if (!isDefined(member[field])) {
              missingFieldsAccMembers.push(field);
            }
          });
        });
        return missingFieldsAccMembers;
      };

      return requiredFields
        .filter((key) => !isDefined(order_task_data[key]))
        .concat(validateAccMember());
    }
  },
  filters: {
    getFullNameField(fieldName) {
      return fieldName.split(' ').join(' ');
    },
    masked(value) {
      const firstDigits = value.slice(0, -4);
      const lastDigits = value.slice(-4);
      return firstDigits.replace(/\w/g, 'X') + lastDigits;
    }
  },
  mounted() {
    this.$store.dispatch('businessEntities/fetchBusinessEntity', { uuid: this.orderTaskParam.business_entity_uuid });
  }
};
</script>

<style scoped>
.invalid-data-header {
  background-color: #dc3545;
}
.title-case {
  text-transform: capitalize;
}
.upper-case {
  text-transform: uppercase;
}
</style>
