<template>
  <div>
    <OrderTaskFulfillment :business_entity_uuid="this.orderTask.business_entity_uuid" :order_task_status="this.orderTaskStatus" :jurisdiction_code="jurisdiction_code"  />
    <h2 class="header">Registered Agent</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <dt>State ID</dt>
          <dd>
            {{ change_of_registered_agent.state_id }}
          </dd>
        </div>
        <div class="col">
          <dt>Standalone</dt>
          <dd>
            {{ change_of_registered_agent.standalone }}
          </dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Jurisdiction</dt>
          <dd>{{ change_of_registered_agent.jurisdiction }}</dd>
        </div>
        <div class="col">
          <dt>Domestic Entity</dt>
          <dd>{{ change_of_registered_agent.domestic_entity }}</dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Is New Business</dt>
          <dd>{{ change_of_registered_agent.is_new_business }}</dd>
        </div>
        <div class="col">
          <dt>Domestic Filing Date</dt>
          <dd>{{ change_of_registered_agent.domestic_filing_date }}</dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Domestic Jurisdiction</dt>
          <dd>{{ change_of_registered_agent.domestic_jurisdiction }}</dd>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import OrderTaskFulfillment from './order-task-fulfillment.vue'

export default {
  name: 'ChangeOfRegisteredAgentView',

  components: {
    OrderTaskFulfillment
  },

  props: ['orderTask'],

  computed: {
    ...mapState('orders', ['order']),
    change_of_registered_agent() {
      return this.orderTask.order_task_data;
    },
    jurisdiction_code() {
      return this.orderTask.order_task_data.jurisdiction;
    },
    orderTaskStatus() {
      return this.orderTask.order_task_status;
    },
  }

};
</script>

<style lang="scss" scoped>

</style>
