<template>
  <div>
    <h2 class="header">Registered Agent</h2>
    <div class="box">
      <Person :person="ra_address" />
    </div>
    <h2 v-if="registered_agent_service.contact" class="header">Contact</h2>
    <div v-if="registered_agent_service.contact" class="box">
      <div class="row">
        <div class="col">
          <Person :person="registered_agent_service.contact" />
        </div>
      </div>
    </div>
    <h2 v-if="registered_agent_service.contact" class="header">Entity Information</h2>
    <div v-if="registered_agent_service.contact" class="box">
      <div class="row">
        <div class="col">
          <dt>State ID</dt>
          <dd>
            {{ registered_agent_service.state_id }}
          </dd>
        </div>
        <div class="col">
          <dt>Entity Name</dt>
          <dd>
            {{ businessEntity.business_entity_name }}
          </dd>
        </div>
        <div class="col">
          <dt>Entity Type</dt>
          <dd>
            {{ businessEntity.business_entity_type }}
          </dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Truic RA</dt>
          <dd>
            {{ registered_agent_service.is_truic_ra }}
          </dd>
        </div>
        <div class="col">
          <dt>Jurisdiction</dt>
          <dd>
            {{ registered_agent_service.jurisdiction }}
          </dd>
        </div>
        <div class="col">
          <dt>Manager Type</dt>
          <dd>
            {{ registered_agent_service.manager_type | managementStructurePresenter }}
          </dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Domestic Entity</dt>
          <dd>
            {{ registered_agent_service.domestic_entity }}
          </dd>
        </div>
        <div class="col">
          <dt>Is New Busines</dt>
          <dd>
            {{ registered_agent_service.is_new_business }}
          </dd>
        </div>
        <div class="col">
          <dt>Has Foreign Jurisdictions</dt>
          <dd>
            {{ registered_agent_service.has_foreign_jurisdictions }}
          </dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Domestic Filing Date</dt>
          <dd>
            {{ registered_agent_service.domestic_filing_date }}
          </dd>
        </div>
        <div class="col">
          <dt>Domestic Jurisdiction</dt>
          <dd>
            {{ businessEntity.domestic_jurisdiction_code }}
          </dd>
        </div>
        <div class="col">
          <dt>Foreign Jurisdictions</dt>
          <dd>
            <span v-for="jurisdiction in registered_agent_service.foreign_jurisdictions" v-bind:key="jurisdiction">{{ jurisdiction }}<br/></span>
          </dd>
        </div>
      </div>
    </div>
    <h2 v-if="registered_agent_service.members" class="header">Management Structure</h2>
    <div v-if="registered_agent_service.members" class="box">
      <div class="row">
        <div class="col">
          <person v-for="member in registered_agent_service.members" :person="member" v-bind:key="member.email" />
          <br/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { managementStructureMixin } from '../../mixins';
import Person from '../../common/person';

export default {
  name: 'RegisteredAgentServiceView',

  components: {
    Person
  },

  mixins: [managementStructureMixin],

  props: ['orderTask'],

  computed: {
    ...mapState('orders', ['raAddresses']),
    ...mapState({
      businessEntity: (state) => state.orders.businessEntity
    }),
    registered_agent_service() {
      return this.orderTask.order_task_data;
    },
    orderTaskAddress() {
      if (this.orderTask.order_task_data && this.orderTask.order_task_data.registered_agent) {
        return this.orderTask.order_task_data.registered_agent;
      }
      return {};
    },
    ra_address() {
      /* The address stored in raAddresses is just the current provider for each jurisdiction.  If this order task
      was created when a different provider was active for that jurisdiction, the raAddresses[jurisdiction] entry
      will not be accurate.  First check if there is a registered agent address in order_task_data, which will be
      the most accurate.  Absent that (if the key is missing or all empty strings) use the raAddressses[jurisdiction]
      as the only way an order task should have incomplete order_task_data is if the order task is not yet complete
      which means, when complete, it would use the address in raAddresses
       */
      if (this.orderTaskAddress && (this.orderTaskAddress.company_name || this.orderTaskAddress.last_name)) {
        return this.orderTaskAddress;
      }
      const jurisdiction = this.orderTask.order_task_jurisdiction;
      return this.raAddresses[jurisdiction] || {};
    }
  }

};
</script>

<style lang="scss" scoped>

</style>
