<template>
  <div>
    <h2 class="header">Purchase Extra Credits</h2>
    <div class="box">
      <div class="col-md-12">
        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="price">ZenBusiness Price</label>
              <input v-model="price" class="form-control" :placeholder="price" name="Price" id="price"/>
            </div>
            <div class="form-group col-md-6">
              <label for="fees">State Fees</label>
              <input v-model="fees" class="form-control" placeholder="0" name="Fees" id="fees"/>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Amount</label>
              <input v-model="productData.order_data.amount" class="form-control" :placeholder="extraCreditsAmount" name="Price" id="extraCreditsAmount" type="number"/>
            </div>
            <div class="form-group col-md-6">
              <label>Apply To Balance</label>
              <select v-model="productData.order_data.credit_balance_uuid" class="form-control" id="amendment-type">
                <option disabled>Please select one</option>
                <option v-for="balance in activeBalances" :value="balance.credit_balance_uuid" :key="balance.credit_balance_uuid">{{balance.service_product_name_id}} - {{balance.order_task.order_task_jurisdiction}}</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div v-if="selectedBalance" class="form-group col-md-6">
              <label><strong>Current Balance Amounts</strong></label>
              <p>Subscription credits: {{selectedBalance.subscription_credits}}</p>
              <p>Extra credits: {{selectedBalance.extra_credits}}</p>
            </div>
            <div v-else>
              <p>Please select a credit balance from the dropdown to see current balance amounts</p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { orderCreateProductMixin } from '@/components/mixins';

export default {
  name: 'extraCreditsCreate',

  props: ['pricing', 'jurisdiction'],

  mixins: [orderCreateProductMixin],

  data() {
    return {
      extraCreditsAmount: 0,
      productData: {
        order_data: {
          service: 'extra_credits',
          jurisdiction: this.jurisdiction,
          amount: 0,
          credit_balance_uuid: '',
          entity_name: this.entityName
        },
        order_item: {
          service: 'extra_credits',
          jurisdiction: this.jurisdiction,
          price: this.pricing.price,
          fees: this.pricing.fees
        }
      }
    };
  },

  computed: {
    ...mapState({
      businessEntityUUID: (state) => state.orderCreate.order.business_entity_uuid,
      creditBalances: (state) => state.businessEntities.creditBalances,
      businessEntityTasks: (state) => state.businessEntities.businessEntityTasks
    }),
    activeBalances() {
      const tasks = this.businessEntityTasks;
      const activeBalances = this.creditBalances.filter((cb) => cb.credit_balance_status === 'active');
      activeBalances.forEach((balance) => {
        balance.order_task = tasks.find((ot) => ot.order_task_uuid === balance.order_task_uuid);
      });

      return activeBalances;
    },
    selectedBalance() {
      return this.creditBalances.find((cb) => cb.credit_balance_uuid === this.productData.order_data.credit_balance_uuid);
    }
  },

  created() {
    this.$store.dispatch('businessEntities/fetchBusinessEntityCreditBalances', {
      businessEntityUuid: this.businessEntityUUID
    });
    this.$store.dispatch('businessEntities/fetchBusinessEntity', {
      uuid: this.businessEntityUUID
    });
  }
};
</script>
