<template>
  <div class="modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Downgrade Plan In Order</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">
          <form>
            <div>
              <strong>Downgrade from plan:</strong><br />
              <span>Note that starter plans cannot be downgraded.</span>
              <select class="form-control plan-name-cap" v-model="orderTask" @change="updateEligiblePlans">
                <option disabled>Select an active plan</option>
                <option v-for="ot in activePlanOrderTasks"
                        v-bind:key="ot.order_task_uuid"
                        v-bind:value="ot">{{ formatPlanName(ot.order_task_type) }} ({{ ot.order_task_jurisdiction }})</option>
              </select>
            </div>
            <div class="spaced" v-if="currentPlan">
              <strong>The current affiliate is: </strong> {{currentPlan.affiliate}}
            </div>
            <div class="spaced">
              <strong>Downgrade to plan:</strong>
              <select v-if="orderTask" class="form-control plan-name-cap" @change="setNewPlanAffiliatePrice" v-model="newPlan">
                <option disabled>Select an active plan</option>
                <option v-for="plan in supportedPlans"
                        v-bind:key="plan.name"
                        v-bind:value="plan">{{ formatPlanName(plan.name) }}</option>
              </select>
              <div v-else>First, select the plan you want to downgrade.</div>
            </div>
            <div class="spaced">
              <strong>The affiliate pricing to use for this plan:</strong>
              <select v-if="newPlan" class="form-control" v-model="newPlanAffiliatePrice">
                <option disabled>Select an affiliate to use for pricing</option>
                <option v-for="price in newPlan.affiliate_pricing"
                        v-bind:key="price.affiliate_plan_option_uuid"
                        v-bind:value="price">{{ formatNewPlanPrice(price) }}</option>
              </select>
              <div v-else>Second, select the plan to downgrade to.</div>
            </div>
            <div class="spaced">
              <div>Old plan price: ${{ oldPlanPrice }}</div>
              <div>New plan price: ${{ newPlanPrice }}</div>
              <div class="form-check" v-if="oldPlanPrice - newPlanPrice > 0">
                <input class="form-check-input" type="checkbox" v-model="shouldRefundDifference" />
                <label class="form-check-label">Refund the difference in plan prices (${{ oldPlanPrice - newPlanPrice }})?</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="change-subscription"
                       v-model="setSubscriptionStartTime" />
                <label class="form-check-label" for="change-subscription">Change Subscription Start Time</label>
              </div>
            </div>
            <div class="form-text" v-if="setSubscriptionStartTime">
              <datetime name="subscription"
                :value-zone="datetimePicker.zone"
                :zone="datetimePicker.zone"
                v-model.trim="subscriptionStartTime"
                :format="datetimePicker.format"
                :type="datetimePicker.type"
                :input-id="datetimePicker.input_id"
                :input-class="datetimePicker.input_class">
              </datetime>
            </div>
            <div v-if="newPlanCoupon" class="form-check">
              <input class="form-check-input" type="checkbox" id="apply-coupon" v-model="applyCoupon"/>
              <label class="form-check-label" for="apply-coupon">Apply coupon ({{ newPlanCoupon.external_coupon_id.replace(/_/g, ' ') }})</label>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <ZgButton 
            @click.once="handleCancelOrderDowngradeClick" 
            class="btn btn-danger action"
            :isEnable="downgradeParametersSelected"
            :isSubmitting="isDowngrading"
          >
            <template v-slot:title> Downgrade Plan </template>
          </ZgButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapState} from 'vuex'
import { Datetime } from 'vue-datetime';
import isEmpty from 'lodash/isEmpty';

import 'vue-datetime/dist/vue-datetime.min.css'

import ZgButton from '../common/Generics/ZgButton.vue';

export default {
  name: 'CancelForDowngradeModal',

  props: ['uuid'],

  components: {
    Datetime,
    ZgButton,
  },

  computed: {
    ...mapState('orders', ['order', 'canGenerateFormation']),
    ...mapState({
      orderTasks: (state) => state.orders.orderTasks,
      plans: (state) => state.orders.plans,
      coupons: (state) => state.orderCreate.coupons,
      products: (state) => state.orderCreate.allProducts,
      eligibleDowngradePlans: (state) => state.orders.eligibleDowngradePlans
    }),
    currentPlan() {
      if ('current_plan' in this.eligibleDowngradePlans) {
        return this.eligibleDowngradePlans['current_plan']
      }
      return null
    },
    downgradePlans() {
      if ('downgrade_plans' in this.eligibleDowngradePlans) {
        return this.eligibleDowngradePlans['downgrade_plans']
      }
      return null
    },
    supportedPlans() {
      if (this.downgradePlans){
        return this.downgradePlans
      }
      return [];
    },
    activePlanOrderTasks() {
      // Starter plans are not eligible for downgrades
      return this.orderTasks.filter((ot) => ot.order_task_type.includes('plan')
        && !ot.order_task_type.includes('starter')
        && ot.order_task_status !== 'canceled'
        && this.availablePlansToDowngrade.includes(ot.order_task_type));
    },
    oldPlanPrice() {
      return this.currentPlan
        ? this.currentPlan['price']
        : 0;
    },
    newPlanPrice() {
      return this.newPlanAffiliatePrice && this.newPlanAffiliatePrice['prices']
        ? this.newPlanAffiliatePrice.prices['annual']
        : 0;
    },
    downgradeParametersSelected() {
      return (
        !isEmpty(this.orderTask) &&
        !isEmpty(this.newPlan) &&
        !isEmpty(this.newPlanAffiliatePrice)
      );
    },
    newPlanCoupon() {
      if (this.newPlanAffiliatePrice && this.newPlanAffiliatePrice.prices && this.newPlanAffiliatePrice.prices['coupon']) {
        return this.newPlanAffiliatePrice.prices['coupon']
      }
      return undefined;
    }
  },

  data() {
    return {
      datetimePicker: {
        type: 'datetime',
        input_id: 'trial-end',
        input_class: "form-control",
        format: "yyyy-MM-dd HH:mm:ssZZ",
        zone: "local"
      },
      orderTask: null,
      newPlan: {},
      newPlanAffiliatePrice: {},
      shouldRefundDifference: false,
      setSubscriptionStartTime: false,
      subscriptionStartTime: null,
      applyCoupon: false,
      isDowngrading: false,
      availablePlansToDowngrade: [],
    };
  },

  created() {
    this.fetchPlanOptionsV2().then((res) => {
        if (res.data?.results) {
          const supportedPlansRaw = res.data.results.map((plan) => {
            if (
              plan.related_plan_pricing.active_flag &&
              !plan.related_plan_pricing.deleted &&
              plan.can_be_downgraded_zg
            ) {
              return plan.related_plan_pricing.service;
            }
          });
          this.availablePlansToDowngrade = supportedPlansRaw;
        }
      });
  },

  methods: {
    ...mapActions({
      fetchEligibleDowngradePlans: 'orders/fetchEligibleDowngradePlans'
    }),
    ...mapActions('products', ['fetchPlanOptionsV2']),
    updateEligiblePlans() {
      this.fetchEligibleDowngradePlans({orderTaskUuid: this.orderTask.order_task_uuid})
    },
    handleCancelOrderDowngradeClick() {
      this.isDowngrading = true;
      const payload = {
        uuid: this.uuid,
        oldServiceProductUuid: this.currentPlan.plan_pricing.service_product_uuid,
        newAffiliatePlanOptionUuid: this.newPlanAffiliatePrice.affiliate_plan_option_uuid,
        jurisdiction: this.orderTask.order_task_jurisdiction,
        refundDifference: this.shouldRefundDifference,
        applyCoupon: this.applyCoupon
      };
      if (this.setSubscriptionStartTime) {
        payload.subscriptionStartTime = this.subscriptionStartTime;
      }
      this.$store.dispatch('orders/downgradeOrder', payload)
        .then(() => {
          this.$emit('canceled');
          scroll(0, 0);
        });
    },
    formatNewPlanPrice(price) {
      return `${price.affiliate} ($${price.prices['annual']}/yr)`;
    },
    setNewPlanAffiliatePrice() {
      if (this.currentPlan && this.currentPlan.affiliate && this.newPlan && this.newPlan.affiliate_pricing)
      {
        const currentAffiliate = this.currentPlan.affiliate
        this.newPlanAffiliatePrice = this.newPlan.affiliate_pricing.find(price => price.affiliate === currentAffiliate)

      }
    },
    formatPlanName(plan) {
      return plan.replaceAll('_', ' ')
    }
  }

};
</script>

<style lang="scss" scoped>
  .badge {
    text-transform: capitalize;
  }

  .plan-name-cap {
    text-transform: capitalize;
  }

  .spaced {
    padding-top: .4em;
    padding-bottom: .20em;
  }
</style>
