<template>
  <div>
    <h2 class="header">WFG Initial Report</h2>
    <div v-if="hasData">
      <automated-checking
        v-if="canCheckStatus"
        :businessEntityName="businessEntity.business_entity_name"
        :businessEntityType="businessEntity.business_entity_type"
        :filingNumber="jurisdiction.jurisdiction_filing_number"
        :orderTaskUuid="orderTaskUuid"
        :jurisdiction="jurisdiction_code"
      ></automated-checking>
      <FilingSubmission
        v-if="isAutomationAdmin || isAutomationProcessor"
        :isSOIBtnDisabled="isSOIBtnDisabled"
        :jurisdiction="jurisdiction_code"
        :automatedFiling="getAutomatedFiling(false)"
        :orderUuid="fulfillmentData.order_uuid"
        :orderTaskUuid="fulfillmentData.order_task_uuid"
        :isTest="false"
        orderTaskType="wfg_initial_report"
      ></FilingSubmission>
      <automated-filing
        v-if="isAutomationAdmin"
        :jurisdiction="jurisdiction_code"
        :automatedFiling="getAutomatedFiling(true)"
        :orderUuid="fulfillmentData.order_uuid"
        :isTest="true"
      ></automated-filing>
      <OrderTaskFulfillment :business_entity_uuid="businessEntityUuid" :order_task_status="orderTaskStatus"
                            :jurisdiction_code="jurisdiction_code" v-if="jurisdiction_code === 'CA'" />
      <div>
        <h2 class="header">Business Entity</h2>
        <div class="box">
          <div class="row">
            <div class="col-6" >
              <dt>Jurisdiction</dt>
              {{fulfillmentData.jurisdiction_code}}
            </div>
            <div class="col-6" >
              <dt>Business Name</dt>
                {{businessEntity.business_entity_name}}
            </div>
          </div>
          <div class="row">
            <div class="col-6" >
              <dt>Business ID / Jurisdiction Filing ID</dt>
              {{jurisdiction.jurisdiction_filing_number}}
            </div>
            <div class="col-6" >
              <dt>Management Structure</dt>
              {{businessEntity.management_structure | managementStructurePresenter}}
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <dt>Business Purpose</dt>
              {{ businessEntity.business_purpose}}
            </div>
          </div>
        </div>
      </div>
      <MultiAddressBlock :addresses="addresses" />
      <div>
        <h2 class="header">Contacts</h2>
        <div class="box">
          <div class="row">
            <div class="col-4" >
              <dt>Members</dt>
              <dd v-if="fulfillmentData.fulfillment_data.members.length > 0">
                <Person
                  v-for="(member, index) in fulfillmentData.fulfillment_data.members"
                  v-bind:key="index"
                  :person="member"
                  :showIndividualName="true"
                />
              </dd>
              <dd v-else>No members on file.</dd>
            </div>
            <div class="col-4" >
              <dt>Managers</dt>
              <dd v-if="fulfillmentData.fulfillment_data.managers.length > 0">
                <Person
                  v-for="(manager, index) in fulfillmentData.fulfillment_data.managers"
                  v-bind:key="index"
                  :person="manager"
                  :showIndividualName="true"
                />
              </dd>
              <dd v-else>No managers on file.</dd>
            </div>
            <div class="col-4" v-if="jurisdiction_code === 'CA'">
              <dt>Elective CEO</dt>
              <dd v-if="fulfillmentData.fulfillment_data.elective_ceo && Object.keys(fulfillmentData.fulfillment_data.elective_ceo).length > 0">
                <Person :person="fulfillmentData.fulfillment_data.elective_ceo"/>
              </dd>
              <dd v-else>No elective ceo on file.</dd>
            </div>
          </div>
          <div class="row">
            <div class="col-6" >
              <dt>Registered Agent</dt>
              <dd v-if="fulfillmentData.fulfillment_data.registered_agent">
                <Person
                  :person="fulfillmentData.fulfillment_data.registered_agent"
                  :showIndividualName="true"
                />
              </dd>
              <dd v-else>No registered agent on file.</dd>
            </div>
            <div class="col-6" >
              <dt>Authorized Signatory</dt>
              <dd v-if="fulfillmentData.fulfillment_data.authorized_signatory">
                <Person
                  :person="fulfillmentData.fulfillment_data.authorized_signatory"
                  :showIndividualName="true"
                />
              </dd>
              <dd v-else>No authorized signatory on file.</dd>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div>Loading...</div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import OrderTaskFulfillment from './order-task-fulfillment.vue'
import {hasPermissionMixin, automatedFilingMixin, managementStructureMixin} from '@/components/mixins';
import AutomatedFiling from '../../common/automated-filing';
import MultiAddressBlock from '../../common/MultiAddressBlock'
import AutomatedChecking from '../../common/automated-checking'
import Person from '../../common/person'
import {ENTITY_CHECK_AVAILABILITY} from '../../../assets/constants/automations'
import FilingSubmission from '../../common/FilingSubmission'

export default {
  name: 'WFGInitialReport',

  components: {
    MultiAddressBlock,
    OrderTaskFulfillment,
    AutomatedFiling,
    AutomatedChecking,
    Person,
    FilingSubmission
  },

  props: ['orderTaskUuid'],
  mixins: [hasPermissionMixin, automatedFilingMixin, managementStructureMixin],
  computed: {
    ...mapState({
      fulfillmentData: (state) => state.fulfillment.fulfillmentData
    }),
    ...mapGetters({
      getAutomatedFiling: 'orders/getAutomatedFiling',
    }),
    hasData() {
      return ('order_task_uuid' in this.fulfillmentData) &&
        (this.fulfillmentData['order_task_uuid'] === this.orderTaskUuid)
    },
    jurisdiction_code() {
      return this.fulfillmentData.jurisdiction_code;
    },
    orderTaskStatus() {
      return this.fulfillmentData.order_task_status;
    },
    businessEntity() {
      return this.fulfillmentData.fulfillment_data.business_entity
    },
    jurisdiction() {
      return this.fulfillmentData.fulfillment_data.jurisdiction
    },
    businessEntityUuid() {
      return this.fulfillmentData.fulfillment_data.business_entity.business_entity_uuid
    },
    addresses() {
      // pack addresses off the payload into the format expected by the MultiAddressBlock
      let addresses = []
      if (this.fulfillmentData) {
        addresses.push({
          'displayName': 'Physical Address',
          'address': this.fulfillmentData.fulfillment_data.physical_address
        })
        addresses.push({
          'displayName': 'Principal Address',
          'address': this.fulfillmentData.fulfillment_data.principal_address
        })
        addresses.push({
          'displayName': 'Mailing Address',
          'address': this.fulfillmentData.fulfillment_data.mailing_address
        })
      }
      return addresses
    },
    canCheckStatus() {
      return Object.keys(ENTITY_CHECK_AVAILABILITY.jurisdictions).includes(this.jurisdiction_code)
        && ENTITY_CHECK_AVAILABILITY.jurisdictions[this.jurisdiction_code].includes('wfg_initial_report');
    },
    isSOIBtnDisabled() {
      return this.orderTaskStatus != 'pending';
    }
  },
  methods: {
    ...mapActions({
      fetchFulfillmentData: 'fulfillment/fetchFulfillmentDataValidation'
    })
  },
  mounted() {
    this.fetchFulfillmentData(this.orderTaskUuid)
  }
};
</script>

