<template>
  <div>
    <h2 class="header">Virtual Business Address</h2>

    <div class="box">
      <div class="row">
        <div class="col-md-5">
          <dt>Virtual Address<br/>(use this in place of mailing address on forms)</dt>
          <Person v-if="getAddressOfType('virtual')" :person="getAddressOfType('virtual')" address-type="virtual" />
          <div v-else>None on file.</div>
        </div>
        <div class="col-md-2">
          <br/><br/>
          forward to
        </div>
        <div class="col-md-5">
          <dt>Mailing Address</dt>
          <Person v-if="getAddressOfType('mailing')" :person="getAddressOfType('mailing')" />
          <div v-else>None on file.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import Person from '../../common/person.vue';

export default {
  name: 'VirtualBusinessAddressView',

  props: ['orderTask'],

  components: { Person },

  computed: {
    ...mapState({
      addresses: (state) => state.businessEntities.addresses
    }),
    ...mapGetters({
      getAddressOfType: 'businessEntities/getAddressOfType',
    }),
  },
  methods: {
    ...mapActions({
      fetchBusinessEntity: 'businessEntities/fetchBusinessEntity'
    })
  },
  created() {
    this.fetchBusinessEntity({ uuid: this.orderTask.business_entity_uuid });
  }
};
</script>
