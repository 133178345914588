<template>
  <div>
    <h2 class="header">Zen Banking Preferred</h2>
    <div class="box">
      <div class="col-md-12">
        <form>
          <div class="form-row py-1">
            <div class="form-group col-md-6">
              <label for="zbbankingpreferred-price">ZenBusiness Price</label>
              <input v-model="price" class="form-control" placeholder="0" name="Price" id="zbbankingpreferred-price" disabled/>
            </div>
          </div>
          <div class="form-row py-1">
            <div class="form-group col-md-6">
              <label for="zbbankingpreferred-period">Pricing Plan</label>
              <select v-model="period" class="form-control" id="zbbankingpreferred-period" disabled>
                <option value="1">Annual</option>
              </select>
            </div>
          </div>
          <div class="form-check">
              <input v-model="applyCouponMonthly"  class="form-check-input" type="checkbox" id="applyCouponMonthly" @change="updateCoupon">
              <label class="form-check-label" for="applyCouponMonthly">
                Apply First Month Free ($5 off)
              </label>
            </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { orderCreateProductMixin } from '@/components/mixins';

export default {
  name: 'zenBankingPreferred',

  props: ['pricing', 'jurisdiction', 'coupons'],

  mixins: [orderCreateProductMixin],

  data() {
    return {
      productData: {
        order_data: {
          service: 'zb_banking_preferred',
          jurisdiction: this.jurisdiction,
          entity_name: this.entity_name,
          business_entity_name: this.business_entity_name
        },
        order_item: {
          service: 'zb_banking_preferred',
          jurisdiction: this.jurisdiction,
          price: this.pricing.price,
          fees: this.pricing.fees,
          term: this.pricing.term,
          period: 12, // currently a bug in dev that doesn't set this correctly
          is_add_on: false,
          affiliate_pricing_uuid: null,
          invoice_trigger:  'manual'
        },
      },
      coupon: null,
      applyCouponMonthly: false
    };
  },
  methods: {
    updateCoupon() {
      if (this.applyCouponMonthly) {
        this.coupon = this.coupons.find((c) => c.external_coupon_id === '5_off_first_month')
      }
      else {
        this.coupon = null
      }
      this.applyCoupon = this.applyCouponMonthly
      this.setCoupon()
    },
    removeCoupon() {
      this.applyCoupon = false
      this.applyCouponMonthly = false
      this.setCoupon()
    }
  }
};
</script>
