import { render, staticRenderFns } from "./order-notes-view.vue?vue&type=template&id=3f71e8fa&scoped=true"
import script from "./order-notes-view.vue?vue&type=script&lang=js"
export * from "./order-notes-view.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f71e8fa",
  null
  
)

export default component.exports