<template>
  <div>
    <h2 class="header">Business Kit</h2>
    <div class="box">
      <div class="col-md-12">
        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="bk-price">Pricing Plan</label>
              <select v-model="localPrice" 
                class="form-control" id="bk-price"
                @change="setOrderItem"
              >
                <option disabled :value="undefined">Please select one</option>
                <option v-for="optionPrice in localPrices" v-bind:key="optionPrice.uuid" :value="optionPrice">
                  {{ optionPrice.related_affiliate.name | capitalizeAllWordsAndReplaceUnderscore }} {{ optionPrice.related_service_pricing.interval | capitalizeAllWordsAndReplaceUnderscore }} {{ `($${optionPrice.related_service_pricing.amount}/${optionPrice.related_service_pricing.interval==='annual'?'year':'month'})` }}
                </option>
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { orderCreateProductMixin } from '@/components/mixins';
import { mapActions, mapGetters } from 'vuex';
import { capitalizeAndReplaceUnderscoreMixin } from '@/components/mixins';

export default {
  name: 'businessKitCreate',

  props: ['jurisdiction', 'pricing'],

  mixins: [orderCreateProductMixin, capitalizeAndReplaceUnderscoreMixin],

  data() {
    return {
      priceGroup: 'zenbusiness',
      productData: {
        order_data: {
          service: 'business_kit',
          standalone: true,
          business_kit: true,
          jurisdiction: this.jurisdiction
        },
        order_item: {
          service: 'business_kit',
          jurisdiction: this.jurisdiction,
          price: undefined,
          fees: 0
        }
      },
      localPrice: undefined,
      localPrices: [],
      localFees: []
    };
  },
  computed: {
    ...mapGetters({
      getAffiliateOrDefault: 'accounts/getAffiliateOrDefault',
    }),
  },
  methods: {
    ...mapActions('orderCreate', [
      'fetchProductFees',
      'fetchProductPrices',
      'clearProductFeesAndPrices',
    ]),
    setOrderItem() {
      if(this.localPrice) {
        this.productData.order_item.price = this.localPrice.related_service_pricing.amount;

        // Logic for setting term and period.
        const intervalLabel = this.localPrice.related_service_pricing.interval;
        const { term, period } = this.getTermAndPeriodAccordingIntervalLabel(intervalLabel);
        this.productData.order_item.term = term;
        this.productData.order_item.period = period;
      }
    }
  },
  created() {
    const product = 'business_kit';
    this.fetchProductPrices({ product, affiliate: this.getAffiliateOrDefault })
      .then((data) => {
        if (data.success) {
          this.localPrices = data.prices;
          if (this.localPrices?.length === 1) {
            this.localPrice = this.localPrices[0];
            this.setOrderItem();
          }
        }
      });
  }
};
</script>
