<template>
  <div>
    <ZgAlert v-if="!ein_creation.business_data" :alert="missingDataAlertMessage"/>
    <h1 v-if="hasSsn" class="heading">EIN Creation</h1>
    <h1 v-else class="heading">EIN Creation (Foreign Applicant)</h1>
    <h2 class="header">Entity Info</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <dt>Entity Type</dt>
          <dd>{{ businessEntity.business_entity_type }}</dd>
        </div>
      </div>
      <div v-if="isLLC" class="row">
        <div class="col">
          <dt>Number of Members</dt>
          <dd>{{ memberCount }}</dd>
        </div>
        <div class="col">
          <dt>Physical Jurisdiction</dt>
          <dd v-if="getAddressOfType('physical')">{{ getAddressOfType('physical').state }}</dd>
          <dd v-else>No physical address on file for this entity. Please correct the entity info on the Business Entity page.</dd>
        </div>
        <div class="col">
          <dt>Members are married</dt>
          <dd v-if="ein_creation.tax_info">{{ ein_creation.tax_info.members_are_married|yesno }}</dd>
        </div>
        <div class="col">
          <dt>Taxed as Single Member</dt>
          <dd v-if="ein_creation.tax_info">{{ ein_creation.tax_info.taxed_as_single_member_llc|yesno }}</dd>
        </div>
      </div>
      <div v-if="isCorp" class="row">
        <div class="col">
          <dt>S Corp</dt>
          <dd v-if="ein_creation.tax_info">{{ ein_creation.tax_info.taxed_as_s_corp }}</dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>EIN Reason</dt>
          <dd>{{ ein_creation.reason }}</dd>
        </div>
      </div>
    </div>
    <h2 class="header">Responsible Party</h2>
    <div class="box" v-if="!requiresDataCollection">
      <div v-if="!editing">
        <div v-if="responsibleParty.first_name" class="row">
          <div class="col">
            <dt>First</dt>
            <dd>{{ responsibleParty.first_name }}</dd>
          </div>
          <div class="col">
            <dt>Middle</dt>
            <dd>{{ responsibleParty.middle_name }}</dd>
          </div>
          <div class="col">
            <dt>Last</dt>
            <dd>{{ responsibleParty.last_name }}</dd>
          </div>
          <div class="col-md-1">
            <dt>Suffix</dt>
            <dd>{{ responsibleParty.suffix }}</dd>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <dt>Full Name</dt>
            <dd>{{ responsibleParty.first_name }} {{ responsibleParty.middle_name }} {{ responsibleParty.last_name }} {{ responsibleParty.suffix }}</dd>
          </div>
        </div>
        <div v-if="responsibleParty.company_name" class="row">
          <div class="col">
            <dt>Company</dt>
            <dd>{{ responsibleParty.company_name }}</dd>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <dt>SSN/ITIN</dt>
            <dd v-if="hasSsn">{{ responsiblePartySsn }}</dd>
            <dd v-else>The applicant has no SSN/ITIN. Please follow the EIN fulfillment process for foreign customers.</dd>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <dt>Alternate Last Names</dt>
            <dd><span v-for="name in responsibleParty.alternate_last_names" v-bind:key="name">{{ name }}<br /></span></dd>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <dt>Previously Applied for EIN</dt>
            <dd>{{ responsibleParty.previous_ein }}</dd>
          </div>
          <div class="col-md-1" v-if="!editing">
            <div class="btn btn-sm btn-danger" @click="editing = !editing">Edit</div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col">
            <dt>First</dt>
            <input class="form-control" v-model.trim="responsibleParty.first_name" />
          </div>
          <div class="col">
            <dt>Middle</dt>
            <input class="form-control" v-model.trim="responsibleParty.middle_name" />
          </div>
          <div class="col">
            <dt>Last</dt>
            <input class="form-control" v-model.trim="responsibleParty.last_name" />
          </div>
          <div class="col-md-1">
            <dt>Suffix</dt>
            <input class="form-control" v-model.trim="responsibleParty.suffix" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <dt>Company Name</dt>
            <input class="form-control" v-model.trim="responsibleParty.company_name" />
          </div>
        </div>
        <!-- Edit SSN -->
        <div class="row">
          <div class="col">
            <dt>SSN</dt>
            <input v-if="hasSsn" class="form-control" v-model.trim="responsiblePartySsn" />
            <dd v-else>No SSN provided.</dd>
          </div>
        </div>
        <!-- Edit Alternate Last Names -->
        <div>
          <dt>Alternate Last Names</dt>
          <li class="list-group-item" v-bind:key="'last-name-' + index" v-for="(lastName, index) in responsibleParty.alternate_last_names">
            <div class="row">
              <input class="form-control col-10" v-model.trim="responsibleParty.alternate_last_names[index]" />
              <button type="button" class="btn btn-danger btn-sm col-2"  @click="removeAlternateLastName(index)">Remove</button>
            </div>
          </li>
          <button @click="addAlternateLastName" type="button" class="btn btn-separate btn-primary btn-square btn-block">+ Add Alternate Last Name</button>
        </div>
        <!-- Editor control buttons -->
        <div class="row">
          <div class="col-1">
            <div class="btn btn-separate btn-info action" @click="updateEinCreationTask">Save</div>
          </div>
          <div class="col-1">
            <div class="btn btn-separate btn-danger" @click="editing = !editing">Cancel</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box" v-else>
      <div class="col">
        <b>REQUIRES CUSTOMER CONTACT!</b>  The customer did not provide a named individual on their entity that can be
        used as the responsible party on the EIN.  This is usually because they only named companies in their ownership
        definition. EINs require a contactable person so you need to contact the customer and collect a suitable
        Responsible Party.
      </div>
    </div>
    <h2 class="header">Address</h2>
    <div class="box">
      <div class="row">
        <div class="col-6">
          <dt>Physical Address</dt>
          <Person v-if="getAddressOfType('physical')" :person="getAddressOfType('physical')" />
          <div v-else>None on file.</div>
        </div>
        <div class="col-6">
          <dt>Mailing Address</dt>
          <Person v-if="getAddressOfType('mailing')" :person="getAddressOfType('mailing')" />
          <div v-else>None on file.</div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Phone</dt>
          <dd>{{ account.account_phone }}</dd>
        </div>
      </div>
      <div v-if="isCorp" class="row">
        <div class="col">
          <dt>Corp Care Of</dt>
          <dd v-if="einBusinessData">{{ einBusinessData.corp_care_of }}</dd>
        </div>
      </div>
    </div>
    <h2 class="header">Details</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <dt>Entity Name</dt>
          <dd>{{ businessEntity.business_entity_name }}</dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Trade Names</dt>
          <dd>{{ getTradeNames }}</dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Accounting Year Closing Month</dt>
          <dd>{{ einBusinessData.corp_closing_month }}</dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Domestic Jurisdiction</dt>
          <dd>{{ domesticJurisdiction.jurisdiction_code }}</dd>
        </div>
        <div class="col">
          <dt>Filing Date</dt>
          <dd v-if="domesticJurisdiction !== undefined && domesticJurisdiction.jurisdiction_filing_date">{{ domesticJurisdiction.jurisdiction_filing_date }}</dd>
          <dd v-else>None on file.</dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Highway Vehicle</dt>
          <dd>{{(einBusinessData.highway_vehicle && einBusinessData.highway_vehicle_over_55000)|yesno}}</dd>
        </div>
        <div class="col">
          <dt>Gambling</dt>
          <dd>{{ einBusinessData.gambling|yesno }}</dd>
        </div>
        <div class="col">
          <dt>Form 720(Excise Tax)</dt>
          <dd>{{ einBusinessData.files_form_720|yesno }}</dd>
        </div>
        <div class="col">
          <dt>Alcohol, Tobacco, Firearms</dt>
          <dd>{{ einBusinessData.alcohol_tobacco_firearms|yesno }}</dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>W2 Wages Paid Date</dt>
          <dd>{{ einBusinessData.w2_wages_paid_date}}</dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>W2 Wages LT 1000</dt>
          <dd>{{ einBusinessData.w2_wages_lt_1000|yesno }}</dd>
        </div>
        <div class="col">
          <dt>W2 Agricultural Employee Count</dt>
          <dd>{{ einBusinessData.w2_agricultural_employees_count }}</dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Employees with W2</dt>
          <dd>{{ einBusinessData.employees_with_w2|yesno }}</dd>
        </div>
        <div class="col">
          <dt>W2 Other Employee Count</dt>
          <dd>{{ einBusinessData.w2_other_employees_count }}</dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Industry</dt>
          <dd v-if="einBusinessData.activity">
            Level 1: {{ einBusinessData.activity.level_1 }}<br />
            Level 2: {{ einBusinessData.activity.level_2 }}<br />
            Level 3: {{ einBusinessData.activity.level_3 }}<br />
            Level 4: {{ einBusinessData.activity.level_4 }}<br />
            Level 5: {{ einBusinessData.activity.level_5 }}<br />
            Specify: {{ einBusinessData.activity.specify }}
          </dd>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { yesNoMixin } from '@/components/mixins';
import Person from '../../common/person.vue';
import ZgAlert from '../../utils/ZgAlert.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'EinCreationView',

  props: ['orderTask'],

  mixins: [yesNoMixin],

  components: { Person, ZgAlert },

  data() {
    return {
      editing: false,
      missingDataAlertMessage: {
        primary: 'This order task is missing data because it was placed in an upgrade order!',
        secondary: 'Check the previous plan order for entity info or contact support.',
        type: 'alert alert-danger'
      },
    };
  },

  computed: {
    ...mapState({
      order: (state) => state.orders.order,
      account: (state) => state.accounts.account,
      businessEntity: (state) => state.businessEntities.businessEntity,
      jurisdictions: (state) => state.businessEntities.jurisdictions,
      addresses: (state) => state.businessEntities.addresses,
      directors: (state) => state.businessEntities.directors,
      members: (state) => state.businessEntities.members,
      officers: (state) => state.businessEntities.officers,
      responsibleParty: (state) => state.businessEntities.responsibleParty,
    }),
    ...mapGetters({
      getOrderTasksByTypeArr: 'orders/getOrderTasksByTypeArr',
    }),
    businessEntityUUID() {
      return this.orderTask.business_entity_uuid;
    },
    ein_creation() {
      return this.orderTask.order_task_data;
    },
    einBusinessData() {
      return this.ein_creation.business_data || {};
    },
    isLLC() {
      return this.businessEntity.business_entity_type === 'llc';
    },
    isCorp() {
      return this.businessEntity.business_entity_type === 'corp';
    },
    domesticJurisdiction() {
      return this.jurisdictions.find((j) => j.domestic_flag) || {};
    },
    hasSsn() {
      return !!this.responsiblePartySsn;
    },
    useVGS() {
      // If the EIN order came from UJM use VGS for dealing with tax ids
      return Boolean(this.orderTask.order_task_data.journey_instance_uuid);
    },
    responsiblePartySsn: {
      // ssn has 2 possible sources, new journey data or legacy data, give priority to the first one.
      get() {
        return this.responsibleParty.tax_identifier || this.order.order_ssn;
      },
      set(newSsn) {
        if (this.useVGS) {
          this.responsibleParty.tax_identifier = newSsn;
        } else {
          this.order.order_ssn = newSsn;
        }
      }
    },
    requiresDataCollection() {
      return this.responsibleParty && this.responsibleParty.collect_data_from_user;
    },
    memberCount() {
      let memberCount = 0;
      if (this.isCorp) {
        const numDirectors = this.directors.length;
        const numOfficers = this.officers.length;
        memberCount = numDirectors + numOfficers;
      } else {
        // Only count members here, no managers
        memberCount = this.members.length;
      }

      // Sometimes people order an EIN by itself. In this case, we only collect the number of members, but never
      // create the contact records. If this is the case, fall back to the member count in the order data.
      if (memberCount === 0) {
        memberCount = this.ein_creation.member_count;
      }

      return memberCount;
    },
    getTradeNames() {
      // It the EIN Creation Order Task has the trade_names, it should be displayed.
      // Otherwise, we should search a complete DBA Entity Order Task and it use the property dba_name.
      // Any other case, empty return.
      if (this.ein_creation?.trade_names) return this.ein_creation.trade_names;
      const dbas = this.getOrderTasksByTypeArr(['dba_entity']);
      const dbasActive = dbas.filter(ot => ot.order_task_status == 'complete');
      if(dbasActive.length>0 && dbasActive[0].order_task_data) return dbasActive[0].order_task_data.dba_name;
      return '';

    }
  },
  methods: {
    getAddressOfType(addressType) {
      const address = this.addresses.find((a) => a.address_type === addressType);
      return address ? address.address : {};
    },
    addAlternateLastName() {
      if (!this.responsibleParty.alternate_last_names) {
        this.responsibleParty.alternate_last_names = [];
      }
      this.responsibleParty.alternate_last_names.push('');
    },
    removeAlternateLastName(index) {
      this.responsibleParty.alternate_last_names.splice(index, 1);
    },
    updateEinCreationTask() {
      if (!this.ein_creation.responsible_party) {
        this.ein_creation.responsible_party = this.responsibleParty;
      }
      if (!this.ein_creation.business_data) {
        this.ein_creation.business_data = this.einBusinessData;
      }

      if (this.hasSsn) {
        if (this.useVGS) {
          // Save SSN in responsible party contact for UJM
          this.$store.dispatch('businessEntities/updateResponsibleParty', {
            contactUuid: this.responsibleParty.contact_uuid,
            taxIdentifier: this.responsiblePartySsn
          }).then(() => {
            // refresh (will pull in new tax identifier)
            this.$store.dispatch('businessEntities/fetchResponsibleParty', {
              businessEntityUUID: this.businessEntityUUID
            });
          });
        } else {
          // Save SSN within order_ssn table for legacy
          this.$store.dispatch('orders/updateOrderSsn', {
            orderUuid: this.order.order_uuid,
            orderSsn: this.responsiblePartySsn
          });
        }
      }

      // Reset edit flag
      this.editing = false;
    },
    ...mapActions({
      fetchDirectors: 'businessEntities/fetchDirectors',
      fetchMembers: 'businessEntities/fetchMembers',
      fetchOfficers: 'businessEntities/fetchOfficers',
      fetchResponsibleParty: 'businessEntities/fetchResponsibleParty',
    })
  },
  created() {
    this.$store.dispatch('businessEntities/fetchBusinessEntity', { uuid: this.orderTask.business_entity_uuid });
    //dba_entity
  },
  mounted () {
    this.fetchDirectors({ businessEntityUUID: this.businessEntityUUID });
    this.fetchMembers({ businessEntityUUID: this.businessEntityUUID });
    this.fetchOfficers({ businessEntityUUID: this.businessEntityUUID });
    this.fetchResponsibleParty({ businessEntityUUID: this.businessEntityUUID });
  }
};
</script>

<style lang="scss" scoped>
  dt {
    font-size: 10pt;
    color: #a9a9a9;
  }
  .btn-separate {
    margin-top: 10px;
  }
</style>
