<template>
  <tr>
    <td>{{ document.name }}</td>
    <td>{{ active }}</td>
    <td>{{ bookmarked }}</td>
    <td>{{ document.uploaded_by }}</td>
    <td>{{ uploadDate }}</td>
    <td>{{ lastRead }}</td>
    <td><a :href="viewUrl" target="_blank">Download</a></td>
  </tr>
</template>

<script>
import moment from 'moment';

export default {
  name: 'OperatingAgreementUploadedItem',
  props: ['document'],
  computed: {
    active() {
      return this.document.active ? 'Yes' : 'No'
    },
    bookmarked() {
      return this.document.bookmarked ? 'Yes' : 'No'
    },
    viewUrl() {
      if (this.document.document_uuid) {
        return `/business-entity/${this.document.business_entity}/documents/${this.document.document_uuid}/view`;
      }
      return null;
    },
    uploadDate() {
      return moment(this.document.created_datetime).format('MMMM DD YYYY, h:mm:ss a');
    },
    lastRead() {
      if (this.document.last_read_by_owner_datetime) {
        return moment(this.document.last_read_by_owner_datetime).format('MMMM DD YYYY, h:mm:ss a');
      }
      return 'Unread';
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
