<template>
  <div>
    <ZgAlert v-if="successAlert.status" :alert="successAlert" />
    <h2 class="header">Publication - {{ transOrRecurring }}</h2>
    <div class="box">
      <div class="col-md-12">
        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="price">ZenBusiness Price</label>
              <input v-model="price" class="form-control" placeholder="10" name="Price" id="price" disabled />
            </div>
            <div class="form-group col-md-6 mt-4">
              <label for="county">State Fees by County</label>
              <select v-model="nyCounty" class="form-control" name="County" id="county" required>
                <option value="" disabled>-- Please Select One--</option>
                <option v-for="ny_county in Object.keys(pricing.fees).sort()" :value="ny_county" :key="ny_county">
                  {{ makeTitleCase(ny_county, '_') }} (${{ getTotalFees(ny_county) }})
                </option>
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { makeTitleCase } from  '@/assets/utils/text-utils';
import { mapState } from "vuex";
import { orderCreateProductMixin } from "@/components/mixins";
import ZgAlert from "@/components/utils/ZgAlert.vue";

export default {
  name: 'nyPublicationCreate',

  props: ['pricing', 'jurisdiction', 'filingType', 'stateFee'],

  components: { ZgAlert },

  mixins: [orderCreateProductMixin],

  data() {
    return {
      productData: {
        order_data: {
          service: 'ny_publication',
          jurisdiction: this.jurisdiction
        },
        order_item: {
          service: 'ny_publication',
          jurisdiction: this.jurisdiction,
          price: this.pricing.price,
          fees: this.pricing.fees
        }
      }
    };
  },

  computed: {
    ...mapState({
      successAlert: (state) => state.successAlert,
    }),
    isValid: function () {
      const validations = []
      validations.push(this.pricing && typeof this.pricing.fees !== 'number')
      return validations.every(Boolean)
    }
  },
  methods: {
    makeTitleCase,
    getTotalFees(countyName) {
      return (this.stateFee + this.pricing.fees[countyName][this.filingType]).toFixed(2)
    }
  }
};
</script>
