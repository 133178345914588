<template>
  <div>
    <h1 class="heading">Annual Report ({{ orderTask.order_task_jurisdiction }} / {{ isDomestic ? 'Domestic' : 'Foreign' }})</h1>
    <automated-checking
      v-if="canCheckStatus"
      :businessEntityName="businessEntity.business_entity_name"
      :businessEntityType="businessEntity.business_entity_type"
      :filingNumber="filingNumber"
      :orderTaskUuid="orderTask.order_task_uuid"
      :jurisdiction="orderTask.order_task_jurisdiction"
    ></automated-checking>
    <automated-filing
      v-if="isAutomationAdminOrProcessor && domesticJurisdictionCode"
      :jurisdiction="domesticJurisdictionCode"
      :automatedFiling="getAutomatedFiling(false)"
      :filingType="'llc_annual_report'"
      :orderUuid="order.order_uuid"
      :isTest="false"
    ></automated-filing>
    <automated-filing
      v-if="isAutomationAdmin"
      :jurisdiction="domesticJurisdictionCode"
      :automatedFiling="getAutomatedFiling(true)"
      :orderUuid="order.order_uuid"
      :isTest="true"
    ></automated-filing>
    <OrderTaskFulfillment :business_entity_uuid="this.orderTask.business_entity_uuid" :order_task_status="this.orderTaskStatus" :jurisdiction_code="jurisdiction_code" v-if="jurisdiction_code === 'CA'" />
    <h2 class="header">LLC Annual Report</h2>
    <ReportValidationMessages
      :messages="automationMessages"
    />
    <h2 class="header">Addresses</h2>
    <div class="box">
      <div class="row">
        <div class="col-6">
          <dt>Physical Address</dt>
          <Person v-if="getAddressOfType('physical')" :person="getAddressOfType('physical')" />
          <div v-else>None on file.</div>
        </div>
        <div class="col-6" v-if="!businessEntity.has_vba">
          <dt>Mailing Address</dt>
          <Person v-if="getAddressOfType('mailing')" :person="getAddressOfType('mailing')" />
          <div v-else>None on file.</div>
        </div>
        <div class="col-6" v-if="businessEntity.has_vba">
          <dt>Virtual Address<br/>(use this in place of mailing address on forms)</dt>
          <Person :person="getAddressOfType('virtual')" address-type="virtual" />
        </div>
      </div>
    </div>
    <h2 class="header">State-Specific Details</h2>
    <div class="box">
      <!--tax_payer-->
      <div class="row" v-if="changeSupportsField('tax_payer')">
        <div class="col">
          <dt>Tax Preparer</dt>
          <dd v-if="businessEntity.business_entity_data.tax_payer">
            <person :person="businessEntity.business_entity_data.tax_payer"/>
          </dd>
          <dd v-else>None on file.</dd>
        </div>
      </div>

      <div class="row" v-if="showMDFieldsV2 && changeSupportsField('had_good_standing')" data-testid="had_good_standing">
        <div class="col">
          <dt>Required to be in good standing with the State Department of Assessments and Taxation (SDAT)?</dt>
          <dd v-if="businessEntity.business_entity_data.had_good_standing !== undefined">
            {{ businessEntity.business_entity_data.had_good_standing | yesno }}
          </dd>
          <dd v-else>None on file.</dd>
        </div>
      </div>

      <!--liability_single_member-->
      <div class="row" v-if="showMDv2Field('liability_single_member')" data-testid="liability_single_member">
        <div class="col col-indent">
          <dt>Liability Company (LLC) owned by a single member</dt>
          <dd>
            {{ orderTaskBusinessEntityData.liability_single_member | yesno }}
          </dd>
        </div>
      </div>

      <!--privately_held-->
      <div class="row" v-if="showMDv2Field('privately_held')" data-testid="privately_held">
        <div class="col col-indent">
          <dt>Privately held company if at least 75% of the company\'s shareholders are family members</dt>
          <dd>
            {{ orderTaskBusinessEntityData.privately_held | yesno }}
          </dd>
        </div>
      </div>

      <!--state_benefit-->
      <div class="row" v-if="showMDv2Field('state_benefit')" data-testid="state_benefit">
        <div class="col col-indent">
          <dt>Entity that has an annual operating budget or annual sales less than $5,000,000 and has not qualified for or applied for, and does not intend to apply for a State benefit</dt>
          <dd>
            {{ orderTaskBusinessEntityData.state_benefit | yesno }}
          </dd>
        </div>
      </div>

      <!--none_of_the_above-->
      <div class="row" v-if="showMDv2Field('liability_single_member') && showMDv2Field('privately_held') && showMDv2Field('state_benefit')" data-testid="none_of_the_above">
        <div class="col col-indent">
          <dt>None Of The Above</dt>
          <dd>
            {{ !orderTaskBusinessEntityData.liability_single_member && !orderTaskBusinessEntityData.privately_held && !orderTaskBusinessEntityData.state_benefit | yesno }}
          </dd>
        </div>
      </div>

      <!--own_or_lease_property-->
      <div class="row" v-if="changeSupportsField('own_or_lease_property')">
        <div class="col">
          <dt>Does the business own, lease, or use property located in {{ orderTask.order_task_jurisdiction }}?</dt>
          <dd v-if="businessEntity.business_entity_data.own_or_lease_property !== undefined">
            {{ businessEntity.business_entity_data.own_or_lease_property | yesno }}
          </dd>
          <dd v-else>None on file.</dd>
        </div>
      </div>

      <!--requires_license-->
      <div class="row" v-if="!showMDFieldsV2 && changeSupportsField('requires_license')" data-testid="requires_license">
        <div class="col">
          <dt>Does the business require or maintain a trader's (retail sales) or other license with a local unit of
            government (for example, Clerk of the Court or Liquor Board)?</dt>
          <dd v-if="businessEntity.business_entity_data.requires_license !== undefined">
            {{ businessEntity.business_entity_data.requires_license | yesno }}
          </dd>
          <dd v-else>None on file.</dd>
        </div>
      </div>

      <!--had_gross_sales-->
      <div class="row" v-if="!showMDFieldsV2 && changeSupportsField('had_gross_sales')" data-testid="had_gross_sales">
        <div class="col">
          <dt>Did the business have gross sales in {{ orderTask.order_task_jurisdiction }}?</dt>
          <dd v-if="businessEntity.business_entity_data.had_gross_sales !== undefined">
            {{ businessEntity.business_entity_data.had_gross_sales | yesno }}
          </dd>
          <dd v-else>None on file.</dd>
        </div>
      </div>

      <!--list_gross_sales-->
      <div class="row" v-if="!showMDFieldsV2 && changeSupportsField('list_gross_sales')" data-testid="list_gross_sales">
        <div class="col">
          <dt>Please list gross sales</dt>
          <dd v-if="businessEntity.business_entity_data.list_gross_sales !== undefined">
            {{ businessEntity.business_entity_data.list_gross_sales }}
          </dd>
          <dd v-else>None on file.</dd>
        </div>
      </div>

      <!--sold_property-->
      <div class="row" v-if="changeSupportsField('sold_property')">
        <div class="col">
          <dt>Did the entity dispose, see, or transfer ALL of its business personal property prior to January 1st?</dt>
          <dd v-if="businessEntity.business_entity_data.sold_property !== undefined">
            {{ businessEntity.business_entity_data.sold_property | yesno }}
          </dd>
          <dd v-else>None on file.</dd>
        </div>
      </div>

      <!--makes_less_than_amount-->
      <div class="row" v-if="changeSupportsField('makes_less_than_amount')">
        <div class="col" :class="{'is-error': !isTXTotalRevenueLessThanSOSAmout()}">
          <dt>Does the company make less than $<span v-if="isTxChangesEnabled">2.47</span><span v-else>1.23</span> million a year in revenue?</dt>
          <dd data-testid="makes_less_than_amount_answer">{{ isTXTotalRevenueLessThanSOSAmout() | yesno }}</dd>
        </div>
      </div>

      <!-- TODO: instead of this div, use a fragment -->
      <div v-if="orderTaskBusinessEntityData">
      <!--US_TX total_revenue-->
      <div class="row" v-if="changeSupportsField('total_revenue')">
        <div class="col"
          :class="{'is-error': isFieldError(orderTaskBusinessEntityData.total_revenue)}">
          <dt>What was the exact total revenue for {{prevYear}}?</dt>
          <dd v-if="orderTaskBusinessEntityData.total_revenue !== undefined">
            {{ orderTaskBusinessEntityData.total_revenue }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <div class="row" v-if="changeSupportsField('owns_any_other_business') || changeSupportsField('company_data_owns')">
        <!--US_TX owns_any_other_business-->
        <template v-if="changeSupportsField('owns_any_other_business')">
          <div class="col"
            :class="{
              'is-error': orderTaskBusinessEntityData.owns_any_other_business === undefined,
              'col-6': isContingentFieldError(orderTaskBusinessEntityData.owns_any_other_business, orderTaskBusinessEntityData.company_data_owns)}">
            <dt>Does {{businessEntity.business_entity_name}} own 10% or more of any other business?</dt>
            <dd v-if="orderTaskBusinessEntityData.owns_any_other_business !== undefined">
              {{ orderTaskBusinessEntityData.owns_any_other_business  | yesno }}
            </dd>
            <dd v-else>Nothing on file.</dd>
          </div>
        </template>

        <!--US_TX company_data_owns-->
        <template
          v-if="changeSupportsField('company_data_owns')
            && isContingentFieldDisplayed(orderTaskBusinessEntityData.owns_any_other_business, orderTaskBusinessEntityData.company_data_owns)">
          <div class="col col-6"
            :class="{'is-error': isContingentFieldError(orderTaskBusinessEntityData.owns_any_other_business, orderTaskBusinessEntityData.company_data_owns)}">
            <dt>Owned Entity(s)</dt>
            <dd class="is-group" v-if="orderTaskBusinessEntityData.company_data_owns !== undefined">
              <strong>Name of Owned (Subsidiary) Corporation or Entity:</strong><br>
              {{ orderTaskBusinessEntityData.company_data_owns.name}}<br>
              <strong>State of Formation:</strong><br>
              {{ orderTaskBusinessEntityData.company_data_owns.state}}<br>
              <strong>Percentage:</strong><br>
              {{ orderTaskBusinessEntityData.company_data_owns.percentageOwned}}%
            </dd>
            <dd v-else>Nothing on file.</dd>
          </div>
        </template>
      </div>

      <div class="row" v-if="changeSupportsField('is_owned_by_any_other_business') || changeSupportsField('company_data_owned_by')">
        <!--US_TX is_owned_by_any_other_business-->
        <template v-if="changeSupportsField('is_owned_by_any_other_business')">
          <div class="col"
            :class="{
              'is-error': orderTaskBusinessEntityData.is_owned_by_any_other_business === undefined,
              'col-6': isContingentFieldDisplayed(orderTaskBusinessEntityData.is_owned_by_any_other_business, orderTaskBusinessEntityData.company_data_owned_by)}">
            <dt>Does any other business own 10% or more of {{businessEntity.business_entity_name}}?</dt>
            <dd v-if="orderTaskBusinessEntityData.is_owned_by_any_other_business !== undefined">
              {{ orderTaskBusinessEntityData.is_owned_by_any_other_business  | yesno }}
            </dd>
            <dd v-else>Nothing on file.</dd>
          </div>
        </template>

        <!--US_TX company_data_owned_by-->
        <template
          v-if="changeSupportsField('company_data_owned_by')
            && isContingentFieldDisplayed(orderTaskBusinessEntityData.is_owned_by_any_other_business, orderTaskBusinessEntityData.company_data_owned_by)">
          <div class="col col-6"
            :class="{'is-error': isContingentFieldError(orderTaskBusinessEntityData.is_owned_by_any_other_business, orderTaskBusinessEntityData.company_data_owned_by)}">
            <dt>Owned By, Corporation: </dt>
            <dd class="is-group" v-if="orderTaskBusinessEntityData.company_data_owned_by !== undefined">
              <strong>Name of Owning (Parent) Corporation or LLC:</strong><br>
              {{ orderTaskBusinessEntityData.company_data_owned_by.name}}<br>
              <strong>State of Formation:</strong><br>
              {{ orderTaskBusinessEntityData.company_data_owned_by.state}}<br>
              <strong>Percentage:</strong><br>
              {{ orderTaskBusinessEntityData.company_data_owned_by.percentageOwned}}%
            </dd>
            <dd v-else>Nothing on file.</dd>
          </div>
        </template>
      </div>

      <div class="row" v-if="showTXFieldsV2('is_combined_group') || showTXFieldsV2('is_combined_group_temp_loss_preserved')">
        <!--US_TX is_combined_group-->
        <template v-if="showTXFieldsV2('is_combined_group')">
          <div class="col col-6"
            :class="{ 'is-error': isFieldError(orderTaskBusinessEntityData.is_combined_group) }">
            <dt>Is this the reporting entity of a combined group?</dt>
            <dd v-if="orderTaskBusinessEntityData.is_combined_group !== undefined">
              {{ orderTaskBusinessEntityData.is_combined_group | yesno }}
            </dd>
            <dd v-else>Nothing on file.</dd>
          </div>
        </template>

        <!--US_TX is_combined_group_temp_loss_preserved-->
        <template
          v-if="showTXFieldsV2('is_combined_group_temp_loss_preserved')">
          <div class="col col-6"
            :class="{
              'is-error': isFieldError(orderTaskBusinessEntityData.is_combined_group)
                || isContingentFieldError(orderTaskBusinessEntityData.is_combined_group, orderTaskBusinessEntityData.is_combined_group_temp_loss_preserve) }">
            <dt>Do any of the entities in the combined group have a temporary business loss preserved?</dt>
            <dd v-if="orderTaskBusinessEntityData.is_combined_group_temp_loss_preserved !== undefined">
              {{ orderTaskBusinessEntityData.is_combined_group_temp_loss_preserved | yesno }}
            </dd>
            <dd v-else>Nothing on file.</dd>
          </div>
        </template>
      </div>

      <!--US_TX is_tiered_partner_rev_adjusted-->
      <div class="row" v-if="showTXFieldsV2('is_tiered_partner_rev_adjusted')">
        <div class="col"
          :class="{'is-error': isFieldError(orderTaskBusinessEntityData.is_tiered_partner_rev_adjusted)}">
          <dt>Will your total revenue be adjusted for the Tiered Partnership Election?</dt>
          <dd v-if="orderTaskBusinessEntityData.is_tiered_partner_rev_adjusted !== undefined">
            {{ orderTaskBusinessEntityData.is_tiered_partner_rev_adjusted | yesno }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_TX is_passive_entity_tx-->
      <div class="row" v-if="showTXFieldsV2('is_passive_entity_tx')">
        <div class="col"
          :class="{'is-error': isFieldError(orderTaskBusinessEntityData.is_passive_entity_tx)}">
          <dt>Is this a passive entity as defined in Chapter 171 of the Texas Tax Code?</dt>
          <dd v-if="orderTaskBusinessEntityData.is_passive_entity_tx !== undefined">
            {{ orderTaskBusinessEntityData.is_passive_entity_tx | yesno }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_TX is_reit_tx-->
      <div class="row" v-if="showTXFieldsV2('is_reit_tx')">
        <div class="col"
          :class="{'is-error': isFieldError(orderTaskBusinessEntityData.is_reit_tx)}">
          <dt>Is this entity a Real Estate Investment Trust (REIT) that meets the qualifications specified in section 171.0002(c)(4)?</dt>
          <dd v-if="orderTaskBusinessEntityData.is_reit_tx !== undefined">
            {{ orderTaskBusinessEntityData.is_reit_tx | yesno }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_TX is_new_vet_owned_tx-->
      <div class="row" v-if="showTXFieldsV2('is_new_vet_owned_tx')">
        <div class="col"
          :class="{'is-error': isFieldError(orderTaskBusinessEntityData.is_new_vet_owned_tx)}">
          <dt>Is this entity a New Veteran-Owned Business as defined in Texas Tax Code Sec. 171.0005?</dt>
          <dd v-if="orderTaskBusinessEntityData.is_new_vet_owned_tx !== undefined">
            {{ orderTaskBusinessEntityData.is_new_vet_owned_tx | yesno }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_WA owns_real_property-->
      <div class="row" v-if="changeSupportsField('owns_real_property')">
        <div class="col">
          <dt>Does this entity own (hold title) real property in Washington, such as land or buildings, including leasehold improvements?</dt>
          <dd v-if="orderTaskBusinessEntityData.owns_real_property !== undefined">
            {{ orderTaskBusinessEntityData.owns_real_property  | yesno }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_WA has_there_been_a_transfer_of_stock-->
      <div class="row" v-if="changeSupportsField('has_there_been_a_transfer_of_stock')">
        <div class="col">
          <dt v-if="showWAFieldsV2">
            In the past 12 months, has there been a transfer of at least 16-⅔ percent of the ownership, stock, or other financial interest in {{businessEntity.business_entity_name}}?
          </dt>
          <dt v-else>
            As of January 1, {{orderTask.created_datetime.substring(0, 4)}}, has there been a transfer of stock, other financial interest change, or an option agreement exercised that resulted in a transfer of at least 16% percent interest in {{businessEntity.business_entity_name}}?
          </dt>
          <dd v-if="orderTaskBusinessEntityData.has_there_been_a_transfer_of_stock !== undefined">
            {{ orderTaskBusinessEntityData.has_there_been_a_transfer_of_stock  | yesno }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

       <!--US_WA has_transfer_of_stock_been_over_equal_fifty_percent-->
      <div class="row" v-if="changeSupportsField('has_transfer_of_stock_been_over_equal_fifty_percent')">
        <div class="col">
          <dt v-if="showWAFieldsV2">
            In the past 36 months, has there been a transfer of controlling interest (50% or greater) of the ownership, stock, or other financial interest in {{businessEntity.business_entity_name}}?
          </dt>
          <dt v-else>
            Has the transfer of stock, other financial interest change, or an option agreement exercised resulted in a transfer of controlling interest of 50% or greater?
          </dt>
          <dd v-if="orderTaskBusinessEntityData.has_transfer_of_stock_been_over_equal_fifty_percent !== undefined">
            {{ orderTaskBusinessEntityData.has_transfer_of_stock_been_over_equal_fifty_percent  | yesno }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_WA has_controlling_interest_transfer_filed_department_revenue-->
      <div
        class="row"
        data-testid="has_controlling_interest_transfer_filed_department_revenue"
        v-if="showWAFieldsV2 && changeSupportsField('has_controlling_interest_transfer_filed_department_revenue')"
      >
        <div class="col">
          <dt>Has a controlling interest transfer return been filed with the Department of Revenue?</dt>
          <dd v-if="orderTaskBusinessEntityData.has_controlling_interest_transfer_filed_department_revenue !== undefined">
            {{ orderTaskBusinessEntityData.has_controlling_interest_transfer_filed_department_revenue  | yesno }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_WA has_option_agreement_for_future_purchase-->
      <div
        class="row"
        data-testid="has_option_agreement_for_future_purchase"
        v-if="!showWAFieldsV2 && changeSupportsField('has_option_agreement_for_future_purchase')"
      >
        <div class="col">
          <dt>As of January 1, {{orderTask.created_datetime.substring(0, 4)}}, has an option agreement been executed allowing for the future purchase or acquisition of {{businessEntity.business_entity_name}}?</dt>
          <dd v-if="orderTaskBusinessEntityData.has_option_agreement_for_future_purchase !== undefined">
            {{ orderTaskBusinessEntityData.has_option_agreement_for_future_purchase  | yesno }}</dd>
        </div>
      </div>

      <!--US_WI has_entered_into_any_contract-->
      <div class="row" v-if="changeSupportsField('has_entered_into_any_contract')">
        <div class="col">
          <dt>Has {{businessEntity.business_entity_name}} entered into any contract, combination in the form of a trust or otherwise, or conspiracy in restraint of trade or commerce?</dt>
          <dd v-if="orderTaskBusinessEntityData.has_entered_into_any_contract !== undefined">
            {{ orderTaskBusinessEntityData.has_entered_into_any_contract  | yesno }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

       <!--US_IA holds_interest_in_agricultural_land-->
      <div class="row" v-if="changeSupportsField('holds_interest_in_agricultural_land')">
        <div class="col">
          <dt>Does {{businessEntity.business_entity_name}} hold an interest in agricultural land in Iowa?</dt>
          <dd v-if="orderTaskBusinessEntityData.holds_interest_in_agricultural_land !== undefined">
            {{ orderTaskBusinessEntityData.holds_interest_in_agricultural_land  | yesno }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_IA is_a_family_llc-->
      <div class="row" v-if="changeSupportsField('is_a_family_llc')">
        <div class="col">
          <dt>Is {{businessEntity.business_entity_name}} a "family farm limited liability company"?</dt>
          <dd v-if="orderTaskBusinessEntityData.is_a_family_llc !== undefined">
            {{ orderTaskBusinessEntityData.is_a_family_llc  | yesno }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_WV naics_code-->
      <div class="row" v-if="changeSupportsField('naics_code')">
        <div class="col">
          <dt>What is your NAICS code?</dt>
          <dd v-if="orderTaskBusinessEntityData.naics_code !== undefined">
            {{ orderTaskBusinessEntityData.naics_code }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_WV number_of_state_employees-->
      <div class="row" v-if="changeSupportsField('number_of_state_employees')">
        <div class="col">
          <dt>Number of West Virginia employees</dt>
          <dd v-if="orderTaskBusinessEntityData.number_of_state_employees !== undefined">
            {{ orderTaskBusinessEntityData.number_of_state_employees }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_WV number_of_employees-->
      <div class="row" v-if="changeSupportsField('number_of_employees')">
        <div class="col">
          <dt>Total number of employees</dt>
          <dd v-if="orderTaskBusinessEntityData.number_of_employees !== undefined">
            {{ orderTaskBusinessEntityData.number_of_employees }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_WV scrap_metal_dealer-->
      <div class="row" v-if="changeSupportsField('scrap_metal_dealer')">
        <div class="col">
          <dt>Are you a scrap metal dealer or recycler?</dt>
          <dd v-if="orderTaskBusinessEntityData.scrap_metal_dealer !== undefined">
            {{ orderTaskBusinessEntityData.scrap_metal_dealer | yesno }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_WV currently_exporting-->
      <div class="row" v-if="changeSupportsField('currently_exporting')">
        <div class="col">
          <dt>Is your company currently exporting?</dt>
          <dd v-if="orderTaskBusinessEntityData.currently_exporting !== undefined">
            {{ orderTaskBusinessEntityData.currently_exporting | yesno }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

       <!--US_WV top_export_markets-->
      <div class="row" v-if="changeSupportsField('top_export_markets')">
        <div class="col">
          <dt>Enter your top 3 export markets. The market description is the name of the country to which you are exporting.</dt>
          <dd v-if="orderTaskBusinessEntityData.top_export_markets !== undefined">
            {{ orderTaskBusinessEntityData.top_export_markets }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_WV be_interested_in_exporting-->
      <div class="row" v-if="changeSupportsField('be_interested_in_exporting')">
        <div class="col">
          <dt>Would you be interested in learning about resources to help you sell your products and services overseas?</dt>
          <dd v-if="orderTaskBusinessEntityData.be_interested_in_exporting !== undefined">
            {{ orderTaskBusinessEntityData.be_interested_in_exporting | yesno }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_WV is_minority_owned_business-->
      <div class="row" v-if="changeSupportsField('is_minority_owned_business')">
        <div class="col">
          <dt>Is this a minority owned business?</dt>
          <dd v-if="orderTaskBusinessEntityData.is_minority_owned_business !== undefined">
            {{ orderTaskBusinessEntityData.is_minority_owned_business | capitalizeAndReplaceUnderscore }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_WV is_woman_owned_business-->
      <div class="row" v-if="changeSupportsField('is_woman_owned_business')">
        <div class="col">
          <dt>Is this a woman owned business?</dt>
          <dd v-if="orderTaskBusinessEntityData.is_woman_owned_business !== undefined">
            {{ orderTaskBusinessEntityData.is_woman_owned_business | capitalizeAndReplaceUnderscore }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_WV own_more_than_one_business-->
      <div class="row" v-if="changeSupportsField('own_more_than_one_business')">
        <div class="col">
          <dt>Do you own or operate more than one business in West Virginia?</dt>
          <dd v-if="orderTaskBusinessEntityData.own_more_than_one_business !== undefined">
            {{ orderTaskBusinessEntityData.own_more_than_one_business | yesno }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_WV how_many_businesses-->
      <div class="row" v-if="changeSupportsField('how_many_businesses')">
        <div class="col">
          <dt>How many businesses?</dt>
          <dd v-if="orderTaskBusinessEntityData.how_many_businesses !== undefined">
            {{ orderTaskBusinessEntityData.how_many_businesses }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_WV how_many_counties-->
      <div class="row" v-if="changeSupportsField('how_many_counties')">
        <div class="col">
          <dt>How many counties?</dt>
          <dd v-if="orderTaskBusinessEntityData.how_many_counties !== undefined">
            {{ orderTaskBusinessEntityData.how_many_counties }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_WV does_employ_individuals_who_serve_in_forces-->
      <div class="row" v-if="changeSupportsField('does_employ_individuals_who_serve_in_forces')">
        <div class="col">
          <dt>Does your organization employ individual(s) who currently serve or someone who has served as a member of the United States Armed Forces?</dt>
          <dd v-if="orderTaskBusinessEntityData.does_employ_individuals_who_serve_in_forces !== undefined">
            {{ orderTaskBusinessEntityData.does_employ_individuals_who_serve_in_forces | capitalizeAndReplaceUnderscore }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_WV how_many_individuals-->
      <div class="row" v-if="changeSupportsField('how_many_individuals')">
        <div class="col">
          <dt>How many individuals?</dt>
          <dd v-if="orderTaskBusinessEntityData.how_many_individuals !== undefined">
            {{ orderTaskBusinessEntityData.how_many_individuals }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_WV does_owner_serve_in_forces-->
      <div class="row" v-if="changeSupportsField('does_owner_serve_in_forces')">
        <div class="col">
          <dt>Does the owner of the organization currently serve or has served as a member of the United States Armed Forces?</dt>
          <dd v-if="orderTaskBusinessEntityData.does_owner_serve_in_forces !== undefined">
            {{ orderTaskBusinessEntityData.does_owner_serve_in_forces | capitalizeAndReplaceUnderscore }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_WV get_support_from_WVSBDC-->
      <div class="row" v-if="changeSupportsField('get_support_from_WVSBDC')">
        <div class="col">
          <dt>The West Virginia Small Business Development Center (WVSBDC) provides support to West Virginia&apos;s small business through one-on-one coaching, training, and facilitating connections to resources. There is not charge for this service.</dt>
          <dd v-if="orderTaskBusinessEntityData.get_support_from_WVSBDC !== undefined">
            {{ orderTaskBusinessEntityData.get_support_from_WVSBDC | yesno }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_DE issued_shares_and_gross_assets-->
      <div class="row" v-if="changeSupportsField('issued_shares_and_gross_assets')">
        <div class="col">
          <dt>The State of Delaware requires the current number of Issued Shares and Gross Assets to calculate what is owed on your Annual Report.</dt>
          <dd>
            <div class="row">
              <div class="col">
                <dt>Issued Shares</dt>
                <dd v-if="orderTaskBusinessEntityData.issued_shares">{{ orderTaskBusinessEntityData.issued_shares }}</dd>
                <dd v-else>No info on file.</dd>
              </div>
              <div class="col">
                <dt>Gross Assets</dt>
                <dd v-if="orderTaskBusinessEntityData.gross_assets">{{ orderTaskBusinessEntityData.gross_assets }}</dd>
                <dd v-else>No info on file.</dd>
              </div>
            </div>
          </dd>
        </div>
      </div>

      <!--US_NJ worker_compensation_insurance-->
      <div class="row" v-if="changeSupportsField('worker_compensation_insurance')">
        <div class="col">
          <dt>Is {{businessEntity.business_entity_name}} required to provide worker's compensation insurance?</dt>
          <dd v-if="orderTaskBusinessEntityData.worker_compensation_insurance_required !== undefined">
              {{ orderTaskBusinessEntityData.worker_compensation_insurance_required | yesno }}
            <dl v-if="orderTaskBusinessEntityData.worker_compensation_insurance_required">
              <dt>Is your business self-insured?</dt>
              <dd> {{ orderTaskBusinessEntityData.is_business_self_insured | yesno }}
                <dl v-if="!orderTaskBusinessEntityData.is_business_self_insured">
                  <dt>Insurance Company</dt>
                  <dd>{{ orderTaskBusinessEntityData.insurance_company }}</dd>
                  <dt>Policy Number</dt>
                  <dd>{{ orderTaskBusinessEntityData.policy_number }}</dd>
                  <dt>Coverage Start Date</dt>
                  <dd>{{ orderTaskBusinessEntityData.coverage_start_date }}</dd>
                </dl>
              </dd>
            </dl>
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>

      <!--US_NC owned_us_military_veteran-->
      <div class="row" v-if="changeSupportsField('owned_us_military_veteran')">
        <div class="col">
          <dt>Is 50% or more of {{businessEntity.business_entity_name}} owned by a U.S Military veteran?</dt>
          <dd v-if="orderTaskBusinessEntityData.owned_us_military_veteran !== undefined">
            {{ orderTaskBusinessEntityData.owned_us_military_veteran | yesno }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>
      <!--US_NC owned_us_disabled_military_veteran-->
      <div class="row" v-if="changeSupportsField('owned_us_disabled_military_veteran')">
        <div class="col">
          <dt>Is 50% or more of {{businessEntity.business_entity_name}} owned by a <u>disabled</u> U.S Military veteran?</dt>
          <dd v-if="orderTaskBusinessEntityData.owned_us_disabled_military_veteran !== undefined">
            {{ orderTaskBusinessEntityData.owned_us_disabled_military_veteran | yesno }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>
      <!--US_NC net_receipts_less_than_one_million-->
      <div class="row" v-if="changeSupportsField('net_receipts_less_than_one_million')">
        <div class="col">
          <dt>Are the net receipts of this company less than $1,000,000?</dt>
          <dd v-if="orderTaskBusinessEntityData.net_receipts_less_than_one_million !== undefined">
            {{ orderTaskBusinessEntityData.net_receipts_less_than_one_million | yesno }}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>
      <!--US_IL up_to_date_stock_information-->
      <div class="row" v-if="changeSupportsField('up_to_date_stock_information')">
        <div class="col">
          <dt>The State of Illinois requires up-to-date stock information. Have there been any changes since formation or last year's Annual Report (if applicable)?</dt>
          <dd v-if="orderTaskBusinessEntityData.up_to_date_stock_information !== undefined">
              {{ orderTaskBusinessEntityData.up_to_date_stock_information | yesno }}
            <dl v-if="orderTaskBusinessEntityData.up_to_date_stock_information">
              <dt>What class of stock was added? Common or Preferred?</dt>
              <dd> {{ orderTaskBusinessEntityData.stock_class}}
                <dl v-if="orderTaskBusinessEntityData.up_to_date_stock_information">
                  <dt>Stock Added</dt>
                  <dd>{{ orderTaskBusinessEntityData.stock_added }}</dd>
                  <dt>Authorized Shares</dt>
                  <dd>{{ orderTaskBusinessEntityData.authorized_shares }}</dd>
                  <dt>Issued Shares</dt>
                  <dd>{{ orderTaskBusinessEntityData.issued_shares }}</dd>
                </dl>
              </dd>
            </dl>
            <dl v-else>
              <dt>Please select the option that applies most to {{businessEntity.business_entity_name}} :</dt>
              <dd>{{ orderTaskBusinessEntityData.corp_transacts }}</dd>
              <dt>Is the ownership of {{businessEntity.business_entity_name}} Minority/Female Status?</dt>
              <dd>{{ orderTaskBusinessEntityData.corp_ownership }}</dd>
            </dl>
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>
      <!--US_NY chief_executive_officer-->
      <div class="row" v-if="changeSupportsField('chief_executive_officer')">
        <div class="col">
          <dt>Confirm or update the Chief Executive Officer</dt>
          <dd><Person :person="orderTaskBusinessEntityData.chief_executive_officer" /></dd>
        </div>
      </div>
      <!--US_NY business_entity_principal_address-->
      <div class="row" v-if="changeSupportsField('business_entity_principal_address')">
        <div class="col">
          <dt>Confirm or update the Business Address</dt>
          <dd><Person :person="orderTaskBusinessEntityData.business_entity_principal_address" /></dd>
        </div>
      </div>
      <!--US_NY number_of_directors_and_directors_as_women-->
      <div class="row" v-if="changeSupportsField('number_of_directors_and_directors_as_women')">
        <div class="col">
          <dt>Number of Directors</dt>
          <dd>{{ orderTaskBusinessEntityData.number_of_directors }}</dd>
          <dt>Number of Directors as Women</dt>
          <dd>{{ orderTaskBusinessEntityData.number_of_directors_as_women }}</dd>
        </div>
      </div>
      <!--US_TX web_file_id-->
      <div class="row" v-if="changeSupportsField('web_file_id')">
        <div class="col"
          :class="{'is-error': isFieldError(orderTaskBusinessEntityData.web_file_id)}">
          <dt>Texas WebFile ID </dt>
          <dd v-if="orderTaskBusinessEntityData.web_file_id !== undefined">
            {{orderTaskBusinessEntityData.web_file_id}}
          </dd>
          <dd v-else>Nothing on file.</dd>
        </div>
      </div>
      </div>
    </div>

    <entity-filing-change v-if="filingChange"
                          :filingChange="filingChange"
                          :businessEntity="businessEntity"
                          :domesticJurisdictionCode="domesticJurisdictionCode"
                          :orderTask="orderTask"
                          :onlyShowChangedValues="false"
                          :isAnnualReport="true" />

  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { ENTITY_CHECK_AVAILABILITY } from '@/assets/constants/automations'
import Person from '../../common/person';
import AutomatedChecking from '../../common/automated-checking';
import AutomatedFiling from '../../common/automated-filing';
import EntityFilingChange from '../../common/entity-filing-change';
import {
  hasPermissionMixin, automatedFilingMixin, amendmentReportMixin, computeFullNameOfPersonMixin, entityFilingChangeMixin, yesNoMixin, capitalizeAndReplaceUnderscoreMixin
} from '@/components/mixins';
import OrderTaskFulfillment from './order-task-fulfillment.vue';
import ReportValidationMessages from '@/v2/automation/components/ReportValidationMessages';
import {getClient} from '../../../client/ldClient';
import { tx2024ChangesEnabled } from '@/assets/utils/feature-utils.js'
import { getYear, parseISO, subYears } from 'date-fns';

export default {
  name: 'AnnualReportServiceView',
  props: ['orderTask'],
  components: {
    AutomatedChecking,
    AutomatedFiling,
    EntityFilingChange,
    Person,
    OrderTaskFulfillment,
    ReportValidationMessages
  },
  mixins: [amendmentReportMixin, automatedFilingMixin, computeFullNameOfPersonMixin, entityFilingChangeMixin, yesNoMixin, capitalizeAndReplaceUnderscoreMixin, hasPermissionMixin],
  computed: {
    ...mapState('orders', ['order']),
    ...mapState({
      businessEntity: (state) => state.businessEntities.businessEntity,
      complianceEvents: (state) => state.businessEntities.complianceEvents,
      entityFilingChanges: (state) => state.businessEntities.entityFilingChanges,
      reportFieldConfig: (state) => state.products.reportFieldConfig,
      jurisdictions: (state) => state.businessEntities.jurisdictions,
      addresses: (state) => state.businessEntities.addresses
    }),
    ...mapGetters({
      getAutomatedFiling: 'orders/getAutomatedFiling',
      getAutomationMessagesByOrderTaskType: 'automation/getAutomationMessagesByOrderTaskType',
    }),
    complianceEvent() {
      return this.complianceEvents?.find((event) => event.order_task_uuid === this?.orderTask?.order_task_uuid);
    },
    // This date is not always accurate, to be used as fallback
    legacyARDate() {
      const jurisdiction = this.jurisdictions.find((jur) => jur.jurisdiction_code == this.orderTask.order_task_jurisdiction);
      const legacyARDate = jurisdiction?.jurisdiction_annual_report_date;
      return legacyARDate;
    },
    // Get the year prior to the compliance event date
    prevYear() {
      let prevYear = this.legacyARDate && getYear(parseISO(this.legacyARDate));
      const complianceEventDateStr = this.complianceEvent?.event_date;
      if (complianceEventDateStr) {
        prevYear = getYear(subYears(parseISO(complianceEventDateStr), 1))
      }
      return prevYear;
    },
    automationMessages() {
      const type = this.orderTask.order_task_type;
      const messages = this.getAutomationMessagesByOrderTaskType(type);
      return messages?messages:[];
    },
    annual_report() {
      return this.orderTask.order_task_data;
    },
    jurisdiction_code() {
      return this.annual_report.jurisdiction;
    },
    orderTaskStatus() {
      return this.orderTask.order_task_status;
    },
    domesticJurisdictionCode() {
      const domesticJurisdiction = this.jurisdictions.find((jur) => jur.domestic_flag);
      return domesticJurisdiction ? domesticJurisdiction.jurisdiction_code : '';
    },
    orderTaskBusinessEntityData() {
      const {
        order_task_data: {
          new_data: { business_entity_data: businessEntityData } = {},
        },
      } = this.orderTask;
      return businessEntityData;
    },
    filingNumber() {
      const orderTaskJurisdiction = this.jurisdictions.find((jur) => jur.jurisdiction_code == this.orderTask.order_task_jurisdiction);
      return orderTaskJurisdiction ? orderTaskJurisdiction.jurisdiction_filing_number : '';
    },
    canCheckStatus () {
      return Object.keys(ENTITY_CHECK_AVAILABILITY.jurisdictions).includes(this.orderTask.order_task_jurisdiction)
        && ENTITY_CHECK_AVAILABILITY.jurisdictions[this.orderTask.order_task_jurisdiction].includes(this.businessEntity.business_entity_type);
    },
    // Show new field and hide questions for MD introduced on 2023-03-03
    showMDFieldsV2() {
      return this.orderTaskBusinessEntityData.had_good_standing !== undefined
    },
    // Show new field and updated questions for WA introduced on 2023-03-24
    showWAFieldsV2() {
      return this.orderTaskBusinessEntityData.has_controlling_interest_transfer_filed_department_revenue !== undefined
    },
  },
  data() {
    return {
      featureFlagClient: null,
      isTxChangesEnabled: false
    }
  },
  methods: {
    getAddressOfType(addressType) {
      const address = this.addresses.find((a) => a.address_type === addressType);
      return address ? address.address : {};
    },
    isContingentFieldDisplayed(paramField, contingentField)  {
      // if there's a data mismatch, display the fields
      if (this.isContingentFieldError(paramField, contingentField)) {
        return true;
      }
      return paramField ? true : false;
    },
    isContingentFieldError(paramField, contingentField) {
      /* if a) the parameter field is undefined or false, we should not have
      -- a value for the contingent field or
      -- b) the parameter field is true and we don't have a
      -- value for the contingent field
      -- Show the contingent field
      -- and alert the user of a data mismatch */
      if (!paramField && contingentField !== undefined) {
        return true;
      }
      if (paramField && !contingentField) {
        return true;
      }
      return false;
    },
    isFieldError(field) {
      return field === undefined ? true : false;
    },
    isTXTotalRevenueLessThanSOSAmout() {
      const value = this.isTxChangesEnabled
        ? 2470000
        : 1230000;
      return parseInt(this.orderTaskBusinessEntityData.total_revenue?.replace(/,/g, ''), 10) <= value;
    },
    showMDv2Field(key) {
      return this.showMDFieldsV2 && this.changeSupportsField(key) && this.orderTaskBusinessEntityData[key] !== undefined;
    },
    showTXFieldsV2(key) {
      const fields = this.orderTask?.order_task_data?.new_data?.business_entity_data
      return this.isTxChangesEnabled
        ? fields[key] !== undefined
        : this.changeSupportsField(key)
    },
  },
  mounted() {
    this.$store.dispatch('businessEntities/fetchBusinessEntity', { uuid: this.orderTask.business_entity_uuid });
    this.$store.dispatch('businessEntities/fetchEntityFilingChanges', { uuid: this.orderTask.business_entity_uuid });
    this.$store.dispatch('products/fetchReportFieldConfigByJurisdiction', { jurisdictionCode: this.orderTask.order_task_jurisdiction });
  },
  async beforeMount() {
    await this.$store.dispatch('businessEntities/fetchComplianceEventsByEntity', { businessEntityUuid: this.orderTask.business_entity_uuid });
    this.featureFlagClient = await getClient();
    this.isTxChangesEnabled = tx2024ChangesEnabled(this.complianceEvents, this.orderTask, this.featureFlagClient)
  }
};
</script>
<style lang="scss" scoped>
.changed {
  background-color:#d4edda;
}
.newChanged {
  background-color:green;
}
.col {
  margin-bottom: .25em;
  margin-top: .25em;
}

.col-indent {
  margin-left: 2em;
}
.is-error {
  background-color: rgba($clr-danger, .25);
  padding-bottom: em(5);
  padding-top: em(5);
  dt {
    color: $clr-font-secondary;
  }
}
.is-group {
  background: $stone-6;
  padding-top: em(5);
  padding-right: em(5);
  strong {
    color: $clr-font-secondary;
    font-size: em(13.333)
  }
}
.report-manually {
  text-transform: uppercase;
}
</style>
