<template>
  <div>
    <automated-filing-status-indicator
      :orderUuid="orderUuid"
      :isTest="isTest"
      v-if="canAutomateFiling" />
    <h2 class="header">{{testText}}Automated Filing</h2>
    <div class="box">
    <div class="row" v-if="ifAutomatedFiling">
      <div class="col">
        <dt>
          Automated Filing Status
          <i class="fa fa-info-circle" :content="this.noRetryMessage" v-tippy v-if="!allowAutomatedFiling"></i>
        </dt>
        <dd>{{ automatedFiling.status }}</dd>
      </div>
      <div class="col">
        <div content="GA does not include a tracking number" v-tippy  v-if="jurisdiction==='GA'">
          <dt>Automated Filing Tracking</dt>
          <dd><a :href="automatedFiling.sos_job_tracking_url" target="_blank">{{ automatedFiling.tracking_number }}</a></dd>
        </div>
        <div v-else>
          <dt>Automated Filing Tracking</dt>
          <dd><a :href="automatedFiling.sos_job_tracking_url" target="_blank">{{ automatedFiling.tracking_number }}</a></dd>
        </div>
      </div>
    </div>
    <div class="row" v-if="ifAutomatedFiling">
      <div class="col">
        <dt>Automated Filing Last Started</dt>
        <dd v-if="automatedFiling.started_datetime">{{ automatedFiling.started_datetime | moment("MMMM DD YYYY, h:mm:ss a") }}</dd>
        <dd v-else>None</dd>
      </div>
      <div class="col">
        <dt>Automated Filing Last Completed</dt>
        <dd v-if="automatedFiling.completed_datetime">{{ automatedFiling.completed_datetime | moment("MMMM DD YYYY, h:mm:ss a") }}</dd>
        <dd v-else>None</dd>
      </div>
    </div>
      <div class="row" v-if="ifAutomatedFiling">
        <div class="col">
          <dt>Invalid Input Fields</dt>
          <dd>{{ automatedFiling.invalid_input_fields }}</dd>
        </div>
      </div>
    <div class="row" v-if="ifAutomatedFiling && isAutomationAdminOrProcessor">
      <div class="col">
        <dt>
          Data Confirmation Screenshot
          <i class="fa fa-user-lock" :content="`${this.adminMessage} ${this.confirmationMessage}`" v-tippy v-if="isAutomationAdmin" />
        </dt>
        <dd v-if="automatedFiling.confirmation_url">
          <a :href="url" target="_blank" v-for="(url, index) in automatedFiling.confirmation_url" :key="index" >Link {{index+1}} </a>
        </dd>
        <dd v-else>none</dd>
      </div>
      <div class="col">
        <dt>
          Automated Filing UUID
          <i class="fa fa-user-lock" :content="`${this.adminMessage}`" v-tippy v-if="isAutomationAdmin" />
        </dt>
        <dd>{{ automatedFiling.automated_filing_uuid }}</dd>
      </div>
    </div>
    <div class="row" v-if="ifAutomatedFiling && isAutomationAdminOrProcessor">
      <div class="col">
        <dt>
          Payment Confirmation Screenshot
          <i class="fa fa-user-lock" :content="`${this.adminMessage} ${this.confirmationMessage}`" v-tippy v-if="isAutomationAdminOrProcessor" />
        </dt>
        <dd v-if="automatedFiling.payment_confirmation_url">
          <a :href="url" target="_blank" v-for="(url, index) in automatedFiling.payment_confirmation_url.split('|')" :key="index" >Link {{index+1}} </a>
        </dd>
        <dd v-else>none</dd>
      </div>
      <div class="col">
        <dt>
          Error Screenshot
          <i class="fa fa-user-lock" :content="`${this.adminMessage}`" v-tippy v-if="isAutomationAdminOrProcessor" />
        </dt>
        <dd v-if="automatedFiling.error_page_url">
          <a :href="url" target="_blank" v-for="(url, index) in automatedFiling.error_page_url.split('|')" :key="index" >Link {{index+1}} </a>
        </dd>
        <dd v-else>none</dd>
      </div>
    </div>
    <div class="row" v-if="ifAutomatedFiling">
      <div class="col">
        <dt>
          Average Completion Time
          <i class="fa fa-info-circle" :content="this.averageCompletionTimeMessage" v-tippy></i>
        </dt>
        <dd v-if="automatedFiling.avg_completion_time_field">{{ getDuration(automatedFiling.avg_completion_time_field) }}</dd>
        <dd v-else>Not enough data</dd>
      </div>
      <div class="col">
        <dt>
          Attempts
        </dt>
        <dd>{{ automatedFiling.num_tries }}</dd>
      </div>
    </div>
    <div class="row" v-if="ifAutomatedFiling">
      <div class="col" v-if="automatedFiling.automation_eta">
        <dt>
          Automation ETA
        </dt>
        <dd>{{ automatedFiling.automation_eta }}</dd>
      </div>
       <div class="col" v-if="automatedFiling">
        <dt>
          Queue status
        </dt>
        <dd>{{ queueMessage }}</dd>
      </div>
    </div>
    <div class="row" v-if="ifAutomatedFiling">
      <div class="col" v-if="automatedFiling.sub_status">
        <dt>
          Sub Status
        </dt>
        <dd>{{ makeSentenceCase(automatedFiling.sub_status) }}</dd>
      </div>
      <div class="col" v-if="automatedFilingTracking.length > 0">
        <dt>
          Error details
        </dt>
        <dd>{{ makeSentenceCase(automatedFilingTracking[automatedFilingTracking.length - 1].error_details) }}</dd>
      </div>
    </div>
    <div class="row">
      <div class="col" v-if="ifAutomatedFiling && isAutomationAdmin">
        <div @click="showFilingData = !showFilingData">
          <dt>
            <SlotCopy :valueToCopy="JSON.stringify(automatedFiling.filing_data)">
              Filing Data
            </SlotCopy>
            <i class="fa" :class="iconArrow"></i>
          </dt>
          <dd v-if="showFilingData">
            <vue-json-pretty :data="automatedFiling.filing_data"></vue-json-pretty>
          </dd>
        </div>
      </div>
      <div class="col" v-if="ifAutomatedFiling">
        <div>
          <dt>
            Tracking
          </dt>
          <dd>
            <button class="btn btn-secondary" @click="showTracking = true">Show</button>
            <AutomatedFilingTracking v-if="showTracking"  @close="showTracking = false" :automatedFiling="automatedFiling"/>
          </dd>
        </div>
      </div>
    </div>
    <div v-if="!ifAutomatedFiling && !isTest" class="row container-flex">
    <div class="container">
      <div class="text-center col-centered">

        <h1>
          <span class="fa fa-info-circle"></span>
        </h1>


      </div>
      <div class="text-center">
        <p>Queue Status</p>
        <dt>{{ queueMessage }}</dt>
      </div>



    </div>
  </div>
    </div>
  </div>
</template>

<script>
import AutomatedFilingStatusIndicator from '../common/AutomatedFilingStatusIndicator.vue';
import VueJsonPretty from 'vue-json-pretty';
import { hasPermissionMixin, automatedFilingMixin } from '@/components/mixins';
import SlotCopy from "@/components/v2/common/Generics/SlotCopy.vue";
import { makeSentenceCase } from '@/assets/utils/text-utils';
import { mapActions, mapGetters } from 'vuex';

const AutomatedFilingTracking = () => import('./automated-filing-tracking.vue');

export default {
  data() {
    return {
      showFilingData: false,
      showTracking: false,
      queueMessage: 'Queue data not available',
    };
  },
  name: 'AutomatedFiling',
  components: {
    SlotCopy,
    AutomatedFilingStatusIndicator,
    AutomatedFilingTracking,
    VueJsonPretty,
  },
  computed: {
    canAutomateFiling () {
      return this.hasAutomatedPermission('llc_formation', this.jurisdiction) || this.hasAutomatedPermission('llc_annual_report', this.jurisdiction)
    },
    iconArrow() {
      return this.showFilingData ? 'fa-sort-up' : 'fa-sort-down';
    },
    testText() {
      if (this.isTest) {
        return 'Test '
      }
      return ''
    },
    ...mapGetters({
      automatedFilingTracking: 'orders/automatedFilingTracking'
    }),
  },
  watch: {
  'automatedFiling.automated_filing_uuid': {
    handler(uuid) {
      if (uuid) {
        this.$store.dispatch('orders/fetchAutomatedFilingTracking', { uuid });
      }
    },
    immediate: true,
  },
},
  created() {
    if(this.filingType){
      this.calculateQueuePosition();

    }
    
  },

  mixins: [hasPermissionMixin, automatedFilingMixin],

  props: ['automatedFiling', 'jurisdiction', 'orderUuid', 'isTest', 'filingType'],
  methods: {
    ...mapActions({
      fetchQueueNumber: 'automation/fetchQueueNumber',
    }),
    makeSentenceCase,
    getDuration(seconds) {
      return new Date(seconds * 1000).toISOString().substr(11, 8);
    },
    async calculateQueuePosition() {
      let data = await this.fetchQueueNumber({
        jurisdiction_code: this.jurisdiction,
        formation_type: this.filingType,
        ...this.automatedFiling ? { automated_filing_uuid: this.automatedFiling.automated_filing_uuid } : {}
      });
      this.queueMessage = this.displaQueueMessage(data, !!this.automatedFiling);
    },
    displaQueueMessage(data, hasFiling = true) {
      if (data?.error) return 'Queue data not available';
      const orderPosition = data.current_queue_position;
      const totalItems = data.total_queue_items;
      if(!hasFiling)
        return `${totalItems} items`

      return orderPosition ? `${orderPosition} of ${totalItems}` : 'Order not in queue'
    },
  },

};
</script>
<style lang="scss" scoped>
.container-flex {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}

.col-centered {
  margin: 0 auto; /* optional for additional horizontal centering of flex items if needed */
}

</style>
