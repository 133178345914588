<template>
  <div>
    <h2 class="header">S-Corp Designation</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <p>Filing date must be set to generate Form 2553 for this entity.</p>
        </div>
      </div>
      <div class="row">
        <div class="col col-4">
          <dt>Filing Date</dt>
          <dd v-if="jurisdiction && jurisdiction.jurisdiction_filing_date">{{ jurisdiction.jurisdiction_filing_date | moment("MMMM DD YYYY") }}</dd>
          <dd v-else>None on file.</dd>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'SCorpDesignationView',

  props: ['orderTask'],

  computed: {
    ...mapState({
      jurisdictions: (state) => state.businessEntities.jurisdictions
    }),
    jurisdiction() {
      return this.jurisdictions.find((j) => j.jurisdiction_code === this.orderTask.order_task_jurisdiction);
    }
  },
  methods: {
    ...mapActions({
      fetchBusinessEntity: 'businessEntities/fetchBusinessEntity'
    })
  },
  created() {
    /* TODO: Ideally, fetching the business entity should happen once and
      -- only be refetched if there has been a change. Business entities 
      -- should be stored as an array in the state and cleared out when 
      -- leaving the business entity.
      -- Eventually, this should be removed from this component.
    */
    this.fetchBusinessEntity({ uuid: this.orderTask.business_entity_uuid });
  }
};
</script>

<style lang="scss" scoped>
.box {
  p {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
