<template>
  <div class="modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content min-width-fit-content">
        <div class="modal-header">
          <h5 class="modal-title">Are you sure you would like to edit the refund reason?</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">
          <ReasonCollector 
            :listReasons="refund_reasons"
            :description="'Reason for providing a refund:'"
            v-model="refund"
            @input="setReasonCollected"
          />
        </div>
        <div class="modal-footer">
          <button class="btn btn-danger action" @click="handleEditReason" :disabled="!inputIsValid">Yes, submit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ReasonCollector from '@/components/common/forms/ReasonCollector.vue';

export default {
  name: 'EditRefundInvoiceReasonModal',
  props: ['order_uuid', 'refund_reasons', 'oldRefund'],

  components: {
    ReasonCollector
  },

  data() {
    return {
      refund: {
        refund_cancel_reason_definition: undefined,
        refund_reason_other: ''
      }
    };
  },

  created() {
    this.refund.refund_cancel_reason_definition = this.getCurrentRefundReason;
    this.refund.refund_reason_other = this.oldRefund.refund_reason_other;
  },

  computed: {
    ...mapState('orders', ['order']),
    reasonNotProvided() {
      return this.refund.refund_cancel_reason_definition
        && this.refund.refund_cancel_reason_definition.secondary_reason.toLowerCase() === 'other'
        && !this.refund.refund_reason_other.length;
    },
    inputIsValid() {
      return this.refund.refund_cancel_reason_definition && !this.reasonNotProvided;
    },
    refundReasonGroups() {
      const reasonGroups = [];
      this.refund_reasons.forEach((rr) => {
        let found = false;
        reasonGroups.forEach((group) => {
          if (group.group_label === rr.primary_reason) {
            found = true;
            group.refund_reason_group.push(rr);
          }
        });
        if (!found) {
          reasonGroups.push({
            group_label: rr.primary_reason,
            refund_reason_group: [rr]
          });
        }
      });
      return reasonGroups;
    },
    getCurrentRefundReason() {
      let result = this.refundReasonGroups.find((reasonGroup) => reasonGroup.refund_reason_group.some((reason) => reason.refund_cancel_reason_definition_name === this.oldRefund.refund_reason_definition));
      if (result !== undefined) {
        result = result.refund_reason_group.find((reason) => reason.refund_cancel_reason_definition_name === this.oldRefund.refund_reason_definition);
      }
      return result;
    }
  },
  methods: {
    handleEditReason() {
      const data = {
        refund_reason_other: this.refund.refund_reason_other,
        refund_reason_definition: this.refund.refund_cancel_reason_definition.refund_cancel_reason_definition_name
      };
      if (this.oldRefund.refund_reason_other !== data.refund_reason_other || this.oldRefund.refund_reason_definition !== data.refund_reason_definition) {
        this.$store.dispatch('orders/editRefundInvoiceReason', { uuid: this.oldRefund.order_billing_refund_uuid, data })
          .then(() => {
            this.$store.dispatch('orders/fetchRefunds', { uuid: this.order_uuid });
          });
      }
      this.$emit('close');
    },
    setReasonCollected(reason) {
      this.refund.refund_reason_definition = reason.refund_cancel_reason_definition;
      this.refund.refund_reason_other = reason.refund_reason_other;
    }

  }

};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style lang="scss" scoped>
  .badge {
    text-transform: capitalize;
  }

  .invalid-input-message {
    color: red;
  }

  input:invalid{
    border: 1px solid red;
  }
</style>
