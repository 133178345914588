<template>
  <tr>
    <td>{{ document.generated_document_name }}</td>
    <td><span class="badge" :class="statusBadge">{{ document.status }}</span></td>
    <td>{{ deleted }}</td>
    <td>{{ createdDate }}</td>
    <td>{{ lastUpdated }}</td>
    <td v-if="viewUrl">
      <a :href="viewUrl" target="_blank">Download</a>
    </td>
  </tr>
</template>

<script>
import moment from 'moment';

export default {
  name: 'OperatingAgreementGeneratedItem',
  props: ['document'],
  computed: {
    deleted() {
      return this.document.deleted ? 'Yes' : 'No'
    },
    viewUrl() {
      if (this.document.resulting_document_uuid) {
        return `/business-entity/${this.document.business_entity}/documents/${this.document.resulting_document_uuid}/view`;
      }
      return null;
    },
    createdDate() {
      return moment(this.document.created_datetime).format('MMMM DD YYYY, h:mm:ss a');
    },
    lastUpdated() {
      return moment(this.document.updated_datetime).format('MMMM DD YYYY, h:mm:ss a');
    },
    statusBadge() {
      switch(this.document.status) {
        case 'cancelled': 
          return 'bg-danger';
        case 'draft':
          return 'bg-secondary';
        default:
          return 'bg-info';
      }
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
