<template>
  <div class="sections-wrapper">
    <div v-if="enableAutomationSectionFeatureFlag && canViewPII" class="section">
      <h2 class="header">Automation</h2>
      <bof-automated-filing
        :filingStatus="'pending'"
        :orderTaskUUID="otUuid"
        :businessEntityUUID="this.getUuidBusinessEntity"
        :businessEntityJurisdiction="this.jurisdictions"
        :stateOfFormation="state_of_formation"
         />
    </div>
    <div class="section">
      <h2 class="header">Filing Information</h2>
      <div class="box-wrapper-row">
        <div class="box">
          <dt>Filing Type</dt>
          <div class="col">
            <!-- {{ bof.filingType }} -->
            <dd>Initial Filing</dd>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <h2 class="header">Company Information</h2>
      <div class="box-wrapper-row">
        <div class="box">
          <dt>Reporting Company FinCEN ID</dt>
          <div class="col">
            {{ desires_fincen_id ? 'YES' : 'NO' }}
          </div>
        </div>
        <div class="box">
          <dt>Foreign pooled investment vehicle</dt>
          <div class="col">
            {{ foreign_pooled_investment_vehicle ? "YES" : "NO" }}
          </div>
        </div>
      </div>
      <div class="box-wrapper-row">
        <div class="box">
          <dt>Reporting company legal name</dt>
          <div class="col">
            {{ business_entity_name }}
          </div>
        </div>
        <div class="box">
          <dt>Alternative name (e.g., trade name, DBA)</dt>
          <div class="col">
            <ul v-if="dbaListDisplay && dbaListDisplay.length > 0">
              <li v-for="(dba, index) in dbaListDisplay" :key="index">{{ dba.name }}</li>
            </ul>
            <p v-else>No DBA on file</p>
          </div>
        </div>
      </div>
      <div class="box-wrapper-row">
        <div class="box">
          <dt>Tax identification type</dt>
          <div class="col">
            {{ tax_identification_type }}
          </div>
        </div>
        <div class="box">
          <dt>Tax Identification number</dt>
          <div v-if="canViewPII" class="col">
            {{ tax_identification_number }}
          </div>
          <div v-else class="col">Sensitive PII Information</div>
        </div>
      </div>
      <div class="column-container">
        <div class="left-column">
          <div class="box">
            <dt>Jurisdiction of formation or first registration</dt>
            <div class="col">
              US
            </div>
          </div>
          <div class="box">
            <dt>State of formation</dt>
            <div class="col">
              {{ getJurisdictionName(state_of_formation) || state_of_formation }}
            </div>
          </div>
        </div>
        <div class="right-column">
          <div class="box">
            <dt>Reporting Company's current Address</dt>
            <div class="col">
              <dd>
                {{ formatAddress(reporting_company_current_address) || "N/A " }}
              </dd>
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <dt>Existing reporting company</dt>
        <div class="col">
          {{ existing_reporting_company }}
        </div>
      </div>
    </div>
    <div class="section">
      <h2 class="header">Company Applicant Information</h2>
      <div v-for="(companyApplicant, index) in displayed_applicants" :key="index">
        <div class="box">
          <dt>Company Applicant FinCEN ID</dt>
          <div class="col">
            {{ companyApplicant.contact.fincen_id }}
          </div>
        </div>
        <span>OR</span>
        <div class="box-wrapper-row-container">
          <div class="box-wrapper-row">
            <div class="box">
              <dt>Last name</dt>
              <div class="col">
                {{ companyApplicant.contact.last_name || "N/A" }}
              </div>
            </div>
            <div class="box">
              <dt>First Name</dt>
              <div class="col">
                {{ companyApplicant.contact.first_name || "N/A" }}
              </div>
            </div>
          </div>
          <div class="box-wrapper-row">
            <div class="box">
              <dt>Middle name (optional)</dt>
              <div class="col">
                {{ companyApplicant.contact.middle_name || "N/A" }}
              </div>
            </div>
            <div class="box">
              <dt>Suffix (optional)</dt>
              <div class="col">
                {{ companyApplicant.contact.suffix || "N/A" }}
              </div>
            </div>
            <div class="box">
              <dt>Date of birth</dt>
              <div class="col">
                {{ companyApplicant.contact.dob || "N/A" }}
              </div>
            </div>
          </div>
          <div class="box-wrapper-row">
            <div class="box">
              <dt>Address Type</dt>
              <div class="col">
                {{ companyApplicant.contact.location_type || "N/A" }}
              </div>
            </div>
            <div class="box">
              <dt>Company Applicant's current Address</dt>
              <div class="col">
                {{ formatAddress(companyApplicant) || "None on file" }}
              </div>
            </div>
          </div>
          <div class="box-wrapper-row">
            <div class="box">
              <dt>Identifying document type</dt>
              <div class="col">
                {{ companyApplicant.identification_type || "N/A" }}
              </div>
            </div>
            <div class="box">
              <dt>Identifying document number</dt>
              <div class="col">
                {{ companyApplicant.identification_number || "N/A" }}
              </div>
            </div>
          </div>
          <div class="box-wrapper-row">
            <div class="box">
              <dt>
                Identifying document issuing jurisdiction: Country/Jurisdiction
              </dt>
              <div class="col">
                {{ companyApplicant.issuing_country || "N/A" }}
              </div>
            </div>
            <div class="box">
              <dt>
                Identifying document issuing State:
              </dt>
              <div class="col">
                {{ getJurisdictionName(companyApplicant.issuing_jurisdiction) || companyApplicant.issuing_jurisdiction || "N/A" }}
              </div>
            </div>
            <div class="box">
              <dt>Identifying document image</dt>
              <div class="col">
                {{ companyApplicant.image || "N/A" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div v-for="(beneficialOwner, index) in beneficial_owners" :key="index">
        <h2 class="header">
          Beneficial Owner Information - [Beneficial Owner Name #{{ index + 1 }}]
        </h2>
        <div class="box-wrapper-row-container">
          <div class="box-wrapper-row">
            <div class="box">
              <dt>Parent/Guardian information instead of minor child</dt>
              <div class="col">
                {{ beneficialOwner.contact.be2c_profile.on_behalf_of_minor ? "YES" : "NO" }}
              </div>
            </div>
          </div>
          <div class="box">
            <dt>Beneficial Owner FinCEN ID</dt>
            <div class="col">{{ beneficialOwner.contact.fincen_id || "N/A" }}</div>
          </div>
          <span>OR</span>
          <div class="box-wrapper-row">
            <div class="box">
              <dt>Last name</dt>
              <div class="col">{{ beneficialOwner.contact.last_name || "N/A" }}</div>
            </div>
            <div class="box">
              <dt>First Name</dt>
              <div class="col">{{ beneficialOwner.contact.first_name || "N/A" }}</div>
            </div>
          </div>
          <div class="box-wrapper-row">
            <div class="box">
              <dt>Middle name (optional)</dt>
              <div class="col">{{ beneficialOwner.contact.middle_name || "N/A" }}</div>
            </div>
            <div class="box">
              <dt>Suffix (optional)</dt>
              <div class="col">{{ beneficialOwner.contact.suffix || "N/A" }}</div>
            </div>
            <div class="box">
              <dt>Date of birth</dt>
              <div class="col">{{ formatDate(beneficialOwner.contact.dob) || "N/A" }}</div>
            </div>
          </div>
          <div class="box">
            <dt>Beneficial Owner's current Address</dt>
            <div class="col">{{ formatAddress(beneficialOwner.contact) || "N/A" }}</div>
          </div>
          <div class="box-wrapper-row">
            <div class="box">
              <dt>Identifying document type</dt>
              <div class="col">{{ accessIdDoc(beneficialOwner).identification_type || "N/A" }}</div>
            </div>
            <div class="box">
              <dt>Identifying document number</dt>
              <div v-if="canViewPII" class="col">{{ accessIdDoc(beneficialOwner).identification_number || "N/A" }}</div>
              <div v-else class="col">Sensitive PII Information</div>
            </div>
          </div>
          <div class="box-wrapper-row">
            <div class="box">
              <dt>Identifying document issuing jurisdiction: Country/Jurisdiction</dt>
              <div class="col">{{ accessIdDoc(beneficialOwner).issuing_country }}</div>
            </div>
            <div class="box">
              <dt>Identifying document issuing State:</dt>
              <div class="col">{{ renderDocumentJurisdiction(beneficialOwner) }}</div>
            </div>
            <div class="box pii-image">
              <dt class="column-container">
                Identifying document image
                <a class="fa fa-search" v-on:click="makeBigPicture" />
                <a class="fa fa-download" download="document.pdf" />
              </dt>
              <div v-if="accessIdDoc(beneficialOwner).image && is_token(accessIdDoc(beneficialOwner).image)" class="col">
                <div v-if="canViewPII" :data-pii-token="accessIdDoc(beneficialOwner).image" data-pii-type="image">
                  <a class="fa fa-times" v-on:click="unmakeBigPicture" />
                  <img src="https://res.cloudinary.com/zenbusiness/v1/shared-assets/animation/gif/loading.gif" alt="loading" class="loading-placeholder" />
                  <!-- Image rendered by VGS Show here -->
                </div>
                <div v-else>Sensitive PII Information</div>
              </div>
              <div v-else-if="accessIdDoc(beneficialOwner).image && !is_token(accessIdDoc(beneficialOwner).image)" class="col">
                Bad response, received non-token data, cannot render image
              </div>
              <div v-else-if="!accessIdDoc(beneficialOwner).image && canViewPII" class="col">
                Missing document, there should have been one captured
              </div>
              <div v-else class="col">Sensitive PII Information</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">

import { stateObj } from '../../utils/global-utils';
import { mapState, mapActions, mapGetters } from "vuex";
import BofAutomatedFiling from '../../common/bof-automated-filing';
import { hasPermissionMixin } from "@/components/mixins";
import { formatAddress, formatDate } from "../../../components/utils/global-utils";
import authService from '../../../auth/AuthService';
import { v4 as uuid } from 'uuid';
import { isToken } from "../../../components/utils/vgsUtils";
import { getClient } from '@/client/ldClient';

const Heic2Any = () => import('heic2any');
const featureFlagClient = await getClient();

export default {
  name: "BofFilingView",
  components: { BofAutomatedFiling },
  mixins: [hasPermissionMixin],
  props: ["orderTask"],

  computed: {
    ...mapState({
      businessEntity: (state) => state.orders.businessEntity,
      dbas: (state) => state.dba.dbas,
      beneficialOwners: (state) => state.businessEntities.beneficialOwners,
      jurisdictions: (state) => state.businessEntities.jurisdictions,
      companyApplicants: (state) => state.businessEntities.companyApplicants,
      addresses: (state) => state.businessEntities.addresses,
      orderTasks: (state) => state.orders.orderTasks,
    }),
    ...mapGetters("businessEntities", ["getContacts", "getJurisdictions"]),
    ...mapGetters("orders", ["getUuidBusinessEntity"]),

    desires_fincen_id() {
      const bof_ot = this.orderTasks.find((ot) => ot.order_task_type === "beneficial_ownership_filing");
      return bof_ot?.order_task_data?.desire_fincen_id || false;
    },
    jurisdiction_code() {
      if (!this.bof.jurisdiction) {
        return '';
      }
      return this.bof.jurisdiction;
    },
    bof() {
      return this.orderTask.order_task_data;
    },
    otUuid() {
      return this.orderTask.order_task_uuid;
    },
    beneficial_owners() {
      return this.beneficialOwners;
    },
    dbaListDisplay() {
      if (!this.dbas || this.dbas.length === 0) {
        return null; // Return null or an empty array to indicate no DBAs
      } else {
        return this.dbas; // Return the array of DBA objects
      }
    },
    entity_type() {
      return this.businessEntity.business_entity_type;
    },
    reporting_company_fincen_id() {
      return this.businessEntity.fincen_id;
    },
    foreign_pooled_investment_vehicle() {
      return this.businessEntity.foreign_pooled_investment_vehicle;
    },
    business_entity_name() {
      return this.businessEntity.business_entity_name;
    },
    tax_identification_type() {
      return (
        this.businessEntity.tax_id_type ||
        "N/A"
      );
    },
    tax_identification_number() {
      return (
        this.businessEntity.tax_id ||
        "Sensitive PII Information"
      );
    },
    canViewPII() {
      return this.hasPermission("access:bof_filers");
    },
    reporting_company_current_address() {
      const { address: fincenAddress } = this.addresses.find(
        (address) => address.address_type === "fincen_reporting_address"
      ) || {};

      return fincenAddress;
    },
    state_of_formation() {
      return this.businessEntity.domestic_jurisdiction_code;
    },
    existing_reporting_company() {
      // Need to grab domestic jurisdiction
      const domJur = this.jurisdictions.find(
        (jurisdiction) => jurisdiction.domestic_flag
      ) || {};

      // Check if jurisdiction_filing_date is present
      if (domJur.jurisdiction_filing_date) {
        // Parse the date from the jurisdiction_filing_date
        const filingDate = new Date(domJur.jurisdiction_filing_date);
        // This is the date we are comparing against for this value
        const comparisonDate = new Date('2024-01-01');

        // Compare the filing date with January 1, 2024
        if (filingDate < comparisonDate) {
          return "YES"; // Yes, if filing date is before January 1, 2024
        } else {
          return "NO"; // No, if filing date is on or after January 1, 2024
        }
      } else {
        // If jurisdiction_filing_date is null or undefined
        return "None on file";
      }
    },
    company_applicants() {
      return this.companyApplicants;
    },
    displayed_applicants() {
      if (this.company_applicants.length === 0) {
        // Return an array with a single object containing "N/A" values
        return [{
          contact: {
            fincen_id: "None on file",
            last_name: "None on file",
            first_name: "None on file",
            middle_name: "None on file",
            suffix: "None on file",
            dob: "None on file",
            location_type: "None on file",
          },
          identification_type: "None on file",
          identification_number: "None on file",
          issuing_country: "None on file",
          issuing_jurisdiction: "None on file",
          image: "None on file",
        }];
      }
      return this.company_applicants;
    },
    enableAutomationSectionFeatureFlag() {
      // adding feature flag check
      const isFlagEnabled = featureFlagClient.variation('enable-zg-bof-order-automation-section', false);
      return isFlagEnabled;
    }
  },
  methods: {
    ...mapActions({
      fetchBusinessEntity: "businessEntities/fetchBusinessEntity",
      fetchDbas: "dba/fetchDbas",
      fetchCompanyApplicants: 'businessEntities/fetchCompanyApplicants',
      fetchBeneficialOwners: "businessEntities/fetchBeneficialOwners",
      fetchOrder: 'orders/fetchOrder',
    }),
    formatAddress,
    formatDate,
    getJurisdictionName(jurisdiction) {
      return stateObj[jurisdiction];
    },
    renderDocumentJurisdiction(bof_member) {
      const bofMemberDocType = this.accessIdDoc(bof_member).identification_type;
      const bofMemberIssuingJurisdiction = this.accessIdDoc(bof_member).issuing_jurisdiction;
      // fallback to a jurisdiction code if not found
      const jurisdictionName = this.getJurisdictionName(bofMemberIssuingJurisdiction) || bofMemberIssuingJurisdiction;

      if (bofMemberDocType === 'license') return jurisdictionName

      return 'N/A'
    },
    accessIdDoc(bof_member) {
      return bof_member.contact?.identification_documents?.[0] || {};
    },
    is_token(token) {
      return isToken(token);
    },
    async vgsShowDoc(token, elem) {
      const authToken = authService.getAuthToken();
      const { VGS_HOST } = window.zgInit;

      const result = await fetch(`${VGS_HOST}/api/vgs-echo`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'x-notabot': 'true',
          'x-idempotency-key': uuid(),
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({ 'token': token })
      }).then(response => response.json());

      let dataUrl = result?.token;

      // fix for earlier stripping of base64 header
      if (!dataUrl.startsWith("data:")) {
        if (dataUrl[0] == '/') {
          dataUrl = `data:image/jpeg;base64,${dataUrl}`
        } else if (dataUrl[0] == 'i') {
          dataUrl = `data:image/png;base64,${dataUrl}`
        } else if (dataUrl[0] == 'J') {
          dataUrl = `data:application/pdf;base64,${dataUrl}`
        }
      }

      //**dataURL to blob**
      function dataURLtoBlob(dataurl) {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      }

      //**blob to dataURL**
      async function blobToDataURL(blob) {
        return new Promise((resolve, reject) => {
          const a = new FileReader();
          a.onload = () => {
            resolve(a.result);
          };
          a.onerror = (error) => {
            reject(error);
          };
          a.readAsDataURL(blob);
        })
      }

      const blob = dataURLtoBlob(dataUrl);
      const heic2any = (await Heic2Any()).default;
      try {
        const fixedBlob = await heic2any({
          blob,
          toType: "image/jpeg",
        });
        dataUrl = await blobToDataURL(fixedBlob);
      } catch (e) {
        // eslint-disable-next-line no-empty
      }

      const dataType = elem.getAttribute("data-pii-type");

      const parentBox = elem.closest('div.box');

      if (dataType === "image") {
        if (dataUrl.startsWith("data:application/pdf")) {
          const tag = document.createElement("iframe");
          tag.src = dataUrl;
          elem.appendChild(tag);

          parentBox.querySelector('.fa-download').setAttribute('href', dataUrl);
          parentBox.querySelector('.fa-download').classList.add('pii-selected');
          parentBox.classList.add('pii-revealed');
        } else {
          const tag = document.createElement("img");
          tag.src = dataUrl;
          elem.appendChild(tag);

          parentBox.querySelector('.fa-search').classList.add('pii-selected');
          parentBox.classList.add('pii-revealed');
        }
      } else {
        elem.appendChild(document.createTextNode(result));
      }
    },
    makeBigPicture(evt) {
      const elem = evt.currentTarget;
      const parent = elem.closest('div.box');
      const target = parent.querySelector('div[data-pii-token]');
      target.classList.add('big-picture');
      return true;
    },
    unmakeBigPicture(evt) {
      const elem = evt.currentTarget;
      const target = elem.closest('div[data-pii-token]');
      target.classList.remove('big-picture');
      return true;
    }
  },
  async created() {
    const beUuid = this.orderTask.business_entity_uuid || this.getUuidBusinessEntity;
    await this.fetchBusinessEntity({ uuid: beUuid });
    await this.fetchDbas({ uuid: beUuid });
    await this.fetchCompanyApplicants({ businessEntityUUID: beUuid })
    await this.fetchBeneficialOwners({
      businessEntityUUID: beUuid,
    });
  },
  updated() {
    const piis = [...document.querySelectorAll("[data-pii-token]")]
      .filter((elem) => !elem.hasAttribute('data-pii-started'))

    piis.forEach((pii) => {
      pii.setAttribute("data-pii-started", "true");
      const token = pii.getAttribute("data-pii-token");
      this.vgsShowDoc(token, pii)
    });
  },
};
</script>

<style lang="scss">
.box.pii-image {
  .fa {
    display: none;
    text-decoration: none;
    font-size: 22px;
  }

  .column-container {
    justify-content: space-between;
  }

  &.pii-revealed {
    .pii-selected {
      display: initial;
    }

    .loading-placeholder {
      display: none;
    }
  }
}

div[data-pii-token] {
  .fa-times {
    position: fixed; /* Fixed position relative to the viewport */
    top: 20px; /* Adjust as needed */
    right: 20px; /* Adjust as needed */
    z-index: 1000; /* Ensure it's above other content */
    font-size: 32px;
  }

  &.big-picture {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: white !important;
    padding: 50px !important;
    overflow: auto;

    .fa-times {
      display: initial;
    }

    iframe {
      height: 100%;
      max-height: none;
      width: 100%;
      max-width: none;
    }
  }
}
</style>

<style lang="scss" scoped>
.header {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 16px;
  display: flex;
  font-size: 24px;
  font-weight: bold;
  gap: 10px;
  padding: 14px;
  margin-bottom: 20px;
}

.section {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0px;
}

.sections-wrapper {
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
}

.box {
  align-items: flex-start;
  align-self: stretch;
  background: #ededed;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  gap: 10px;
  margin: 0 0 0px 0;
  padding: 16px;
}

.box-wrapper-row {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 16px;
}

.box-wrapper-row-container {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0px;
}

.column-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

span {
  align-self: stretch;
  text-align: center;
}

hr {
  align-self: stretch;
  background: #000;
  height: 1px;
  margin: 0;
  opacity: 1;
}

.col {
  align-items: flex-start;
  align-self: stretch;
  background: #fff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 14px;
}

.left-column,
.right-column {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 50%;
}

.left-column .box:last-child {
  flex-grow: 1;
}

.right-column .box {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

dt {
  font-size: 22px;
  color: #000;
}
</style>
