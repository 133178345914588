import { render, staticRenderFns } from "./registered-agent-service-view.vue?vue&type=template&id=07bcdb98&scoped=true"
import script from "./registered-agent-service-view.vue?vue&type=script&lang=js"
export * from "./registered-agent-service-view.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07bcdb98",
  null
  
)

export default component.exports