<template>
  <div class="modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Are you sure you would like to reset this partner request?</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">
          <strong>Partner Type: </strong>{{ partnerRequest.partner_type }}
        </div>
        <div class="modal-footer">
          <div class="btn btn-warning action" @click="handleResetPartnerRequestStatus">Yes, I want to reset partner request</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ResetPartnerRequestModal',

  props: ['partnerRequest'],

  methods: {
    handleResetPartnerRequestStatus() {
      this.$store.dispatch('partnerRequest/resetPartnerRequest', { uuid: this.partnerRequest.partner_request_uuid })
        .then(() => {
          this.$emit('canceled');
        });
    }
  }
};
</script>

<style lang="scss" scoped>
  .badge {
    text-transform: capitalize;
  }
</style>
