<template>
  <div>
    <h2 class="header">{{testText}} Filing Submission</h2>
    <div class="box">
    <div v-if="automatedFiling">
      <automated-filing-status-indicator
        :orderUuid="orderUuid"
        :isTest="isTest"
        v-if="canAutomateFiling" />
      <div class="row" v-if="ifAutomatedFiling">
        <div class="col">
          <dt>
            Automated Filing UUID
            <i class="fa fa-info-circle" :content="this.noRetryMessage" v-tippy v-if="!allowAutomatedFiling"></i>
          </dt>
          <dd>{{ automatedFiling.automated_filing_uuid}}</dd>
        </div>
        <div class="col">
          <dt>
            Filing Status
            <i class="fa fa-info-circle" :content="this.noRetryMessage" v-tippy v-if="!allowAutomatedFiling"></i>
          </dt>
          <dd>{{ automatedFiling.status }}</dd>
        </div>
        <div class="col">
          <div content="GA does not include a tracking number" v-tippy  v-if="jurisdiction==='GA'">
            <dt>Filing Tracking</dt>
            <dd><a :href="automatedFiling.sos_job_tracking_url" target="_blank">{{ automatedFiling.tracking_number }}</a></dd>
          </div>
          <div v-else>
            <dt>Filing Tracking</dt>
            <dd><a :href="automatedFiling.sos_job_tracking_url" target="_blank">{{ automatedFiling.tracking_number }}</a></dd>
          </div>
        </div>
      </div>
      <div class="row" v-if="ifAutomatedFiling || isAutomationAdmin">
        <div class="col">
          <dt>
            Data Review Screenshot
            <i class="fa fa-user-lock" :content="`${this.adminMessage} ${this.confirmationMessage}`" v-tippy />
          </dt>
          <dd v-if="automatedFiling && automatedFiling.confirmation_url">
            <a :href="url" target="_blank" v-for="(url, index) in automatedFiling.confirmation_url" :key="index" >Link {{index+1}} </a>
          </dd>
          <dd v-else>none</dd>
        </div>
      </div>
      <div class="row" v-if="ifAutomatedFiling || isAutomationAdmin">
        <div class="col">
          <dt>
            Payment Confirmation Screenshot
            <i class="fa fa-user-lock" :content="`${this.adminMessage} ${this.confirmationMessage}`" v-tippy />
          </dt>
          <dd v-if="automatedFiling.payment_confirmation_url">
            <a :href="url" target="_blank" v-for="(url, index) in automatedFiling.payment_confirmation_url.split('|')" :key="index" >Link {{index+1}} </a>
          </dd>
          <dd v-else>none</dd>
        </div>
        <div class="col">
          <dt>
            Error Screenshot
            <i class="fa fa-user-lock" :content="`${this.adminMessage}`" v-tippy />
          </dt>
          <dd v-if="automatedFiling.error_page_url">
            <a :href="url" target="_blank" v-for="(url, index) in automatedFiling.error_page_url.split('|')" :key="index" >Link {{index+1}} </a>
          </dd>
          <dd v-else>none</dd>
        </div>
      </div>
      <div class="row">
        <div class="col" v-if="ifAutomatedFiling">
          <div>
            <dt>
              Tracking
            </dt>
            <dd>
              <button class="btn btn-secondary" @click="showTracking = true">Show</button>
              <AutomatedFilingTracking v-if="showTracking"  @close="showTracking = false" :automatedFiling="automatedFiling"/>
            </dd>
          </div>
        </div>
      </div>
      </div>
    <div v-else>
      No Filing Information Available
    </div>
    <br />
    <div class="row">
      <div class="col" v-if="isAutomationAdmin && onProd">
        <div>
          <dd>
            <ZgButton type="button"  class="btn btn-info action" @click="startAutomatedSOI(true)">
            <template v-slot:title>
                Start Automated SOI AS TEST
              </template>
            </ZgButton>
          </dd>
        </div>
      </div>
      <div class="col" v-if="isAutomationAdminOrProcessor">
        <div>
          <dd>
            <ZgButton type="button" class="btn btn-info action"
            :disabled="isSOIBtnDisabled"
            @click="startAutomatedSOI(false)">
              <template v-slot:title>
                Start Automated SOI
              </template>
            </ZgButton>
          </dd>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AutomatedFilingStatusIndicator from '../common/AutomatedFilingStatusIndicator.vue';
import { SOI_ORDER_TASK_TYPES } from '@/assets/constants/automations';
import { hasPermissionMixin, automatedFilingMixin } from '@/components/mixins';
import ZgButton from '../common/Generics/ZgButton.vue';

const AutomatedFilingTracking = () => import('./automated-filing-tracking.vue');

export default {
  data() {
    return {
      showFilingData: false,
      showTracking: false
    };
  },
  name: 'FilingSubmission',
  components: {
    AutomatedFilingStatusIndicator,
    AutomatedFilingTracking,
    ZgButton,
  },
  computed: {
    canAutomateFiling () {
      return this.hasAutomatedPermission(this.orderTaskType, this.jurisdiction)
    },
    iconArrow() {
      return this.showFilingData ? 'fa-sort-up' : 'fa-sort-down';
    },
    testText() {
      if (this.isTest) {
        return 'Test '
      }
      return ''
    }
  },

  mixins: [hasPermissionMixin, automatedFilingMixin],

  props: [
    "automatedFiling",
    "isSOIBtnDisabled",
    "jurisdiction",
    "orderTaskType",
    "orderUuid",
    "orderTaskUuid",
    "isTest",
  ],

  methods: {
    ...mapActions({
      startAutomatedFiling: 'orders/startAutomatedFiling',
    }),
    getDuration(seconds) {
      return new Date(seconds * 1000).toISOString().substr(11, 8);
    },
    startAutomatedSOI(test) {
      const uuid = this.orderTaskUuid;
      this.startAutomatedFiling({
        uuid,
        test,
        reloadPage: true,
        filingType: SOI_ORDER_TASK_TYPES["wfg_initial_report"],
      });
    },
  }

};
</script>
