<template>
  <div>
    <h2 class="header">Operating Agreement</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <dt>Fees</dt>
          <dd>{{ orderItem.fees }}</dd>
        </div>
        <div class="col">
          <dt>Price</dt>
          <dd>{{ orderItem.price }}</dd>
        </div>
        <div class="col">
            <dt>Status</dt>
            <dd>{{ orderItem.status }}</dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Jurisdiction</dt>
          <dd>{{ orderTaskData.jurisdiction }}</dd>
        </div>
      </div>
      <div class="row">
      </div>
    </div>

    <h2 class="header">Guided Generated Documents</h2>
    <div class="box">
      <table class="table table-sm table-borderless">
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
            <th>Deleted</th>
            <th>Created date</th>
            <th>Last updated</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody v-if="operatingAgreementGeneratedDocuments.length > 0">
          <OperatingAgreementGeneratedItem v-for="document in operatingAgreementGeneratedDocuments"
                                           v-bind:key="document.uuid"
                                           :document="document" />
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5">
              No generated documents
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col">
          <span><em><strong>Note: </strong>Multi-Member Operating Agreement is not supported</em></span>
        </div>
      </div>
    </div>

    <h2 class="header">Uploaded Documents</h2>
    <div class="box">
      <table class="table table-sm table-borderless">
        <thead>
          <tr>
            <th>Name</th>
            <th>Active</th>
            <th>Bookmarked</th>
            <th>Uploaded by</th>
            <th>Upload date</th>
            <th>Last Read by Owner</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody v-if="operatingAgreementUploadedDocuments.length > 0">
          <OperatingAgreementUploadedItem v-for="document in operatingAgreementUploadedDocuments"
                                           v-bind:key="document.uuid"
                                           :document="document" />
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6">
              No uploaded documents found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
</template>

<script>
import { mapState, mapActions } from 'vuex';
import OperatingAgreementGeneratedItem from './operating-agreement-generated-item';
import OperatingAgreementUploadedItem from './operating-agreement-uploaded-item';

export default {
  name: 'OperatingAgreementServiceView',
  props: ['orderTask'],
  components: {
    OperatingAgreementGeneratedItem,
    OperatingAgreementUploadedItem
  },
  computed: {
    ...mapState({
      documents: (state) => state.businessEntities.documents,
      generatedDocuments: (state) => state.documents.generatedDocuments,
      order: (state) => state.orders.order
    }),
    orderTaskData() {
      return this.orderTask.order_task_data;
    },
    orderItem() {
      return this.order.order_items.find((a) => a.service === this.orderTaskData.service);
    },
    businessEntityUuid() {
      return this.orderTask.business_entity_uuid;
    },
    operatingAgreementUploadedDocuments() {
      return this.documents
              .filter((d) => d.type === this.orderTaskData.service);
    },
    operatingAgreementGeneratedDocuments() {
      return this.generatedDocuments
              .filter((d) => d.generated_document_name === "My Operating Agreement")
              .sort((d1, d2) => new Date(d2.updated_datetime) - new Date(d1.updated_datetime));
    }
  },
  methods: {
    ...mapActions({
      fetchBusinessEntity: 'businessEntities/fetchBusinessEntity',
      fetchDocuments: 'businessEntities/fetchDocuments',
      fetchBusinessEntityGeneratedDocuments: 'documents/fetchBusinessEntityGeneratedDocuments'
    })
  },
  created() {
    this.fetchBusinessEntity({ uuid: this.businessEntityUuid });
    this.fetchDocuments({ uuid: this.businessEntityUuid });
    this.fetchBusinessEntityGeneratedDocuments({ uuid: this.businessEntityUuid });
  }
};
</script>

<style lang="scss" scoped>

</style>
