<template>
  <div>
    <h2 class="header">Zen Money Pro</h2>
    <div class="box">
      <div class="col-md-12">
        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Pricing Plan</label>
              <select v-model="period" class="form-control" id="zbmoneypro-period" @change="removeCoupon">
                <option value="0" disabled>Please select one</option>
                <option value="1">Annual (${{ annual_price }}/year)</option>
                <option value="12">Monthly (${{ monthly_price }}/month)</option>
              </select>
            </div>
            <div class="form-check">
              <input v-model="applyCouponAnnual" :disabled="period!==1" class="form-check-input" type="checkbox" id="applyCouponAnnual" @change="updateCoupon">
              <label class="form-check-label" for="applyCouponAnnual">
                Apply $60 off (20%) First Year coupon to Annual Service
              </label>
            </div>
            <div class="form-check">
              <input v-model="applyCouponMonthly" :disabled="period!==12" class="form-check-input" type="checkbox" id="applyCouponMonthly" @change="updateCoupon">
              <label class="form-check-label" for="applyCouponMonthly">
                Apply First Month Free ($30 off)
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { orderCreateProductMixin } from '@/components/mixins';

export default {
  name: 'zenMoneyProCreate',

  props: ['pricing', 'jurisdiction', 'coupons'],

  mixins: [orderCreateProductMixin],

  data() {
    return {
      productData: {
        order_data: {
          service: 'zb_money_pro',
          jurisdiction: this.jurisdiction
        },
        order_item: {
          service: 'zb_money_pro',
          jurisdiction: this.jurisdiction,
          price: this.pricing.price,
          fees: this.pricing.fees,
          term: this.pricing.term,
          period: this.pricing.period,
          is_add_on: false,
          affiliate_pricing_uuid: null
        }
      },
      coupon: null,
      applyCouponMonthly: false,
      applyCouponAnnual: false
    };
  },
  methods: {
    updateCoupon() {
      if (this.applyCouponAnnual) {
        this.coupon = this.coupons.find((c) => c.external_coupon_id === '60_off_first_year')
      }
      else if (this.applyCouponMonthly) {
        this.coupon = this.coupons.find((c) => c.external_coupon_id === '30_off_first_month')
      }
      else {
        this.coupon = null
      }
      this.applyCoupon = this.applyCouponAnnual || this.applyCouponMonthly
      this.setCoupon()
    },
    removeCoupon() {
      this.applyCoupon = false
      this.applyCouponAnnual = false
      this.applyCouponMonthly = false
      this.setCoupon()
    }
  }
};
</script>
