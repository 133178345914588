<template>
  <div>
    <h1 class="heading">Amendment ({{ orderTask.order_task_jurisdiction }} / {{
        isDomestic ? 'Domestic' : 'Foreign'
      }})</h1>
    <OrderTaskFulfillment :business_entity_uuid="this.orderTask.business_entity_uuid"
                          :order_task_status="this.orderTaskStatus" 
                          :jurisdiction_code="jurisdiction_code"
                          v-if="['CA', 'PA'].includes(jurisdiction_code)"/>
    <div v-if="hasData">
      <div v-if="amendment.amendment_type || amendment.amendment_description">
        <h2 class="header">Details</h2>
        <div class="box">
          <div class="row">
            <div class="col">
              <dt>Amendment Type</dt>
              <dd>{{ amendment.amendment_type }}</dd>
            </div>
            <div class="col">
              <dt>Amendment Description</dt>
              <dd>{{ amendment.amendment_description }}</dd>
            </div>
          </div>
        </div>
      </div>
      <entity-filing-change v-if="filingChange"
                            :filingChange="filingChange"
                            :businessEntity="businessEntity"
                            :domesticJurisdictionCode="domesticJurisdictionCode"
                            :orderTask="orderTask"
                            :onlyShowChangedValues="true"/>
      <div v-if="hasWebfileID">
        <h2 class="header">State-Specific Details</h2>
        <div class="box">
          <div class="row">
            <div class="col">
              <dt>Texas Web File ID</dt>
              <dd>{{ amendment.new_data.business_entity_data.web_file_id }}</dd>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <span>This Amendment order has <strong>no changes</strong> from the original order. Please follow-up with the customer to determine if changes are required and then cancel/reorder as needed.</span>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import EntityFilingChange from '../../common/entity-filing-change'
import {amendmentReportMixin, entityFilingChangeMixin} from '@/components/mixins'
import OrderTaskFulfillment from './order-task-fulfillment.vue'

export default {
  name: 'AmendmentServiceView',
  props: ['orderTask'],
  components: {
    EntityFilingChange,
    OrderTaskFulfillment
  },
  mixins: [amendmentReportMixin, entityFilingChangeMixin],
  computed: {
    ...mapState('orders', ['order']),
    ...mapState({
      businessEntity: (state) => state.businessEntities.businessEntity,
      entityFilingChanges: (state) => state.businessEntities.entityFilingChanges,
      reportFieldConfig: (state) => state.products.reportFieldConfig,
      addresses: (state) => state.businessEntities.addresses,
      jurisdictions: (state) => state.businessEntities.jurisdictions
    }),
    amendment () {
      return this.orderTask.order_task_data
    },
    domesticJurisdictionCode () {
      const domesticJurisdiction = this.jurisdictions.find((jur) => jur.domestic_flag)
      return domesticJurisdiction ? domesticJurisdiction.jurisdiction_code : 'Unknown'
    },
    jurisdiction_code () {
      return this.amendment.jurisdiction
    },
    orderTaskStatus () {
      return this.orderTask.order_task_status
    },
    hasData () {
      const hasNewData = this.amendment && this.amendment.new_data
      // Looking in the db, for some reason ZenGarden created amendments have a different json structure than others
      // this bypasses that if 'created_by' is in the order_task_data/it came from zg
      const fromZG = 'created_by' in this.amendment

      if (hasNewData) {
        // As learned in OPERTOOL-1025, some amendments (might be just Texas) can have just their web_file_id provided
        // Those still have no data

        const hasChangedBusinessEntity = this.amendment.new_data.business_entity_data
          && Object.keys(this.amendment.new_data.business_entity_data).filter(k => k !== 'web_file_id').length > 0

        const hasOtherChanges = Object.keys(this.amendment.new_data).filter(e => e !== 'business_entity_data').length > 0

        return hasChangedBusinessEntity || hasOtherChanges
      }
      return fromZG
    },
    hasWebfileID () {
      return this.amendment
        && this.amendment.new_data
        && this.amendment.new_data.business_entity_data
        && this.amendment.new_data.business_entity_data.web_file_id
        && this.jurisdiction_code === 'TX'
    }
  },
  mounted () {
    this.$store.dispatch('businessEntities/fetchBusinessEntity', {uuid: this.orderTask.business_entity_uuid})
    this.$store.dispatch('businessEntities/fetchEntityFilingChanges', {uuid: this.orderTask.business_entity_uuid})
    this.$store.dispatch('products/fetchReportFieldConfigByJurisdiction', {jurisdictionCode: this.orderTask.order_task_jurisdiction})
  }
}
</script>
<style lang="scss" scoped>
.changed {
  background-color: #d4edda;
}

.newChanged {
  background-color: green;
}
</style>
