<template>
  <tr>
    <td>{{ refund.created_by }} on {{ refund.created_datetime| moment("MMMM DD YYYY") }}</td>
    <td>${{ refund.external_refund_amount }}</td>
    <td>{{ refund.refund_reason_definition }}</td>
    <td>{{ refund.refund_reason_other }}</td>
    <td>
      <table>
        <tr v-for="item in refund.tracking" :key="item.uuid">
          <td>{{item.product}}</td>
          <td>${{ item.amount }}</td>
          <td>{{ item.charge_type }}</td>
          <td v-if="!isProductRefundable(item.product)">
            <i class="fa fa-info-circle" :content='item.product + " is fully refunded"' v-tippy></i>
          </td>
        </tr>
      </table>
    </td>
    <td>
      <div class="btn btn-sm btn-danger" @click="editRefundReasonClick = true"
      data-toggle="tooltip" data-placement="top" title="Edit refund invoice reason">Edit</div>
    </td>
    <EditRefundInvoiceReasonModal v-if="editRefundReasonClick" @close="editRefundReasonClick = false" @canceled="refresh"
      v-bind:oldRefund="refund"
      v-bind:order_uuid="uuid"
      v-bind:refund_reasons="refund_reasons" />
  </tr>
</template>

<script>
import { extractRefunds, parsePositiveNumber } from '../../utils/global-utils';
import { mapGetters } from 'vuex';
import EditRefundInvoiceReasonModal from './edit-refund-invoice-reason-modal';

export default {
  name: 'RefundListInvoiceReasonItem',
  components: { EditRefundInvoiceReasonModal },
  props: ['refund', 'uuid', 'refund_reasons', 'subscriptionBillingItems', 'transactionalBillingItems'],

  data() {
    return {
      editRefundReasonClick: false
    };
  },

  computed: {
    ...mapGetters('orders', ['getSplitBillingItems']),
    billingItemAmounts() {
      return this.getSplitBillingItems.map(({service, fees, price, refunds, coupon}) => ({
        service, fees: parsePositiveNumber(fees) - extractRefunds(refunds, 'fee'), price: parsePositiveNumber(price) - extractRefunds(refunds, 'price') - this.getCouponValue(coupon)
      }));
    },
  },

  methods: {
    refresh() {
      this.editRefundReasonClick = false;
    },
    getCouponValue(coupon) {
      if (coupon === null) {
        return 0;
      }
      return parsePositiveNumber(coupon.amount);
    },
    isProductRefundable(product){
      return this.billingItemAmounts.filter((item) => item.fees > 0 || item.price > 0 ? true : false).map((item) => item.service).includes(product)
    }
  }

};
</script>

<style lang="scss" scoped>
  .amount {
    width: 65px;
  }
</style>
