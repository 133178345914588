<template>
  <div>
    <automated-filing-status-indicator
      :orderUuid="uuid"
      v-if="canAutomateFiling" />
    <h2 class="header">Order Tasks</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <table class="table table-sm table-borderless">
            <thead>
              <tr>
                <th v-for="(header, i) in columnHeadersOrderTasks" :key="i">
                  <a href="" @click.prevent="sortingOptionsOrderTasks(header.column)">{{header.header}}</a>
                  <span v-if="header.column === sortByOrderTasks">
                    <i :class="[reverseOrderTasks ? 'fas fa-arrow-down' : 'fas fa-arrow-up' ]"></i>
                    <a href="" class="fa fa-times" @click.prevent="defaultSortingOrderTasks()" ></a>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody v-if="!isLoadingOrderTasks">
              <OrderTaskListItem v-for="orderTask in sortedDataOrderTasks"
                                 v-bind:key="orderTask.order_task_uuid"
                                 :orderTask="orderTask"
                                 :uuid="uuid" />

              <tr v-if="vanityProductName">
                <td colspan="5">{{vanityProductName}}</td>
              </tr>
            </tbody>
            <span v-else>Loading order tasks...</span>
          </table>
        </div>
      </div>
    </div>
    <h2 class="header">Partner Requests</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <table class="table table-sm table-borderless">
            <thead>
            <tr>
              <th v-for="(header, i) in columnHeadersPartnerRequests" :key="i">
                  <a href="" @click.prevent="sortingOptionsPartnerRequests(header.column)">{{header.header}}</a>
                  <span v-if="header.column === sortByPartnerRequests">
                    <i :class="[reversePartnerRequests ? 'fas fa-arrow-down' : 'fas fa-arrow-up' ]"></i>
                    <a href="" class="fa fa-times" @click.prevent="defaultSortingPartnerRequests()" ></a>
                  </span>
                </th>
            </tr>
            </thead>
            <tbody v-if="!loadingPartnerRequests">
              <PartnerRequestListItem v-for="partnerRequest in sortedDataPartnerRequests"
                                      v-bind:key="partnerRequest.uuid"
                                      :partnerRequest="partnerRequest"
                                      :businessEntity="businessEntity"
                                      :partnerRequestDomains="partnerRequestDomains"
                                      :uuid="uuid"
                                      :bundledPrivacy="bundledPrivacy" />
            </tbody>
            <span v-else>Loading</span>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { automatedFilingMixin, hasPermissionMixin, sortingDataMixin } from '@/components/mixins';
import AutomatedFilingStatusIndicator from '../common/AutomatedFilingStatusIndicator.vue';
import OrderTaskListItem from './order-task-list-item';
import PartnerRequestListItem from './partner-request-list-item';

export default {
  name: 'OrderTasksView',

  components: {
    AutomatedFilingStatusIndicator,
    OrderTaskListItem,
    PartnerRequestListItem
  },

  mixins: [automatedFilingMixin, hasPermissionMixin, sortingDataMixin],

  props: ['uuid'],

  data() {
    return {
      sortByOrderTasks: 'default',
      reverseOrderTasks: false,
      columnHeadersOrderTasks: [
        { column: 'order_task_type', header: 'Service' },
        { column: 'order_task_jurisdiction', header: 'Jurisdictions' },
        { column: 'order_task_status', header: 'Status' },
        { column: 'delayed', header: 'Delayed Turnaround' },
        { column: 'salesforce', header: 'Salesforce' }
      ],
      sortByPartnerRequests: 'default',
      reversePartnerRequests: false,
      columnHeadersPartnerRequests: [
        { column: 'partner_type', header: 'Partner' },
        { column: 'jurisdiction', header: 'Jurisdictions' },
        { column: 'status', header: 'Status' },
        { column: 'sentDatetime', header: 'Sent Date' },
        { column: 'partner_request_external_id', header: 'Ext. ID' },
        { column: 'external_status', header: 'Ext. Status' }
      ]
    };
  },

  computed: {
    ...mapState({
      orderItems: (state) => state.orders.order.order_items || [],
      orderTasks: (state) => state.orders.orderTasks,
      isLoadingOrderTasks: (state) => state.orders.isLoadingOrderTasks,
      businessEntity: (state) => state.orders.businessEntity,
      partnerRequestDomains: (state) => state.partnerRequest.partnerRequestDomains,
      partnerRequests: (state) => state.partnerRequest.partnerRequests,
      loadingPartnerRequests: (state) => state.partnerRequest.loadingPartnerRequests
    }),
    vanityProductName() {
      return ((this.orderItems || []).find(oi => oi.service.toLowerCase().startsWith('vanity_')))?.service || null
    },
    canAutomateFiling () {
      const jurisdiction = this.businessEntity.business_entity_data?.domestic_jurisdiction
      /* TODO: this is a bit of a bandaid and should be reevaluated after we update the mixins */
      const hasFilingProduct = this.orderItems.some((oi) => {
        return oi.service === 'llc_formation' || oi.service === 'llc_annual_report'
      })
      return hasFilingProduct && (this.hasAutomatedPermission('llc_formation', jurisdiction) || this.hasAutomatedPermission('llc_annual_report', jurisdiction))
    },
    sortedDataOrderTasks() {
      return this.orderTasks.concat().sort(this.sorting(this.sortByOrderTasks, this.reverseOrderTasks));
    },
    sortedDataPartnerRequests() {
      return this.partnerRequests.concat().sort(this.sorting(this.sortByPartnerRequests, this.reversePartnerRequests));
    },
    bundledPrivacy() {
      const partnerRequests = this.sortedDataPartnerRequests.map((i) => i.partner_type);
      return partnerRequests.includes('domain_name_privacy') && partnerRequests.includes('domain_name_reg');
    }
  },

  methods: {
    defaultSortingOrderTasks() {
      this.sortByOrderTasks = 'default';
      this.reverseOrderTasks = false;
    },
    sortingOptionsOrderTasks(sortBy) {
      if (this.sortByOrderTasks === sortBy) {
        this.reverseOrderTasks = !this.reverseOrderTasks;
      } else {
        this.reverseOrderTasks = false;
      }

      this.sortByOrderTasks = sortBy;
    },
    defaultSortingPartnerRequests() {
      this.sortByPartnerRequests = 'default';
      this.reversePartnerRequests = false;
    },
    sortingOptionsPartnerRequests(sortBy) {
      if (this.sortByPartnerRequests === sortBy) {
        this.reversePartnerRequests = !this.reversePartnerRequests;
      } else {
        this.reversePartnerRequests = false;
      }

      this.sortByPartnerRequests = sortBy;
    }
  },

  mounted() {
    this.$store.dispatch('orders/fetchRegisteredAgentAddresses');
  }

};
</script>

<style lang="scss">
  .heading {
    padding: 20px 0 10px 0;
    a {
      padding: 0;
    }
  }
</style>
