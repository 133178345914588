<template>
  <div>
    <h1 class="heading">Entity Name Reservation</h1>
    <OrderTaskFulfillment :business_entity_uuid="this.orderTask.business_entity_uuid" :order_task_status="this.orderTaskStatus" :jurisdiction_code="jurisdiction_code"  />
    <h2 class="header">Details</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <dt>Entity Name</dt>
          <dd>{{ entityNameReservation.entity_name }}</dd>
        </div>
        <div class="col">
          <dt>Entity Type</dt>
          <dd>{{ entityNameReservation.entity_type }}</dd>
        </div>
        <div class="col">
          <dt>Jurisdiction</dt>
          <dd> {{ entityNameReservation.jurisdiction }}</dd>
        </div>
        <div class="col">
          <dt>County</dt>
          <dd>{{ entityNameReservation.address.county }}</dd>
        </div>
      </div>
    </div>
    <h2 class="header">Address</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <person :person="entityNameReservation.address"></person>
          <br/>
        </div>
      </div>
    </div>
    <h2 class="header">Primary Contact</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <person :person="entityNameReservation.contact"></person>
          <br/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Person from '../../common/person';
import OrderTaskFulfillment from './order-task-fulfillment.vue'

export default {
  name: 'EntityNameReservationView',

  components: {
    Person,
    OrderTaskFulfillment
  },

  props: ['orderTask'],

  computed: {
    jurisdiction_code() {
      return this.entityNameReservation.jurisdiction;
    },
    entityNameReservation() {
      return this.orderTask.order_task_data;
    },
    orderTaskStatus() {
      return this.orderTask.order_task_status;
    },
  }
};
</script>

<style lang="scss" scoped>

</style>
