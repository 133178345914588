<template>
  <div class="my-2">
    <div>{{ description }}</div>
    <div>
      <multiselect id="refund-reason-select"
        v-model="localRefund"
        :options="refundReasonGroups"
        group-values="refund_reason_group"
        group-label="group_label"
        track-by="refund_cancel_reason_definition_uuid"
        label="secondary_reason"
        @input="checkInformationReason()">
          <span slot="noResult">Not found.</span>
      </multiselect>
    </div>
    <div>
      More details: 
      <input class="form-control" maxlength="255" v-model="localRefundDetail" @input="checkInformationReason()"/>
      </div>
    <div><label v-if="localRefund && localRefund.is_explanation_required && localRefundDetail.length<3" class="invalid-input-message">Please describe why this reason is being given in the text box.</label></div>
  </div>
</template>
<script>
import { inputValidationsMixin } from '../../mixins';

export default {
  name: 'ReasonCollector',

  mixins: [inputValidationsMixin],

  props: ['listReasons', 'description', 'value'],

  data: function () {
    return {
      localRefund: {},
      localRefundDetail: ''
    }
  },

  computed: {
    refundReasonGroups() {
      const reasonGroups = [];
      this.listReasons.forEach((rr) => {
        let found = false;
        reasonGroups.forEach((group) => {
          if (group.group_label === rr.primary_reason) {
            found = true;
            group.refund_reason_group.push(rr);
          }
        });
        if (!found) {
          reasonGroups.push({
            group_label: rr.primary_reason,
            refund_reason_group: [rr]
          });
        }
      });
      return reasonGroups;
    },
  },

  methods: {
    checkInformationReason() {
      if(this.localRefund.refund_cancel_reason_definition_uuid) {
        if(this.localRefund.is_explanation_required) {
          if(this.localRefundDetail?.length>3) {
            this.$emit('input', { "refund_cancel_reason_definition": this.localRefund, "refund_reason_other": this.localRefundDetail });
          } else {
            this.$emit('input', {});
          }
        } else {
          this.$emit('input', { "refund_cancel_reason_definition": this.localRefund, "refund_reason_other": this.localRefundDetail });
        }
      }
    },
  },

  created() {
    if (this.value && this.value.refund_cancel_reason_definition) {
      this.localRefund = this.value.refund_cancel_reason_definition;
    }
    if (this.value && this.value.refund_reason_other) {
      this.localRefundDetail = this.value.refund_reason_other;
    }

  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style lang="scss" scoped>
  .invalid-input-message {
    color: red;
  }
</style>
