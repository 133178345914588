<template>
  <div>
    <h2 class="header">Initial Report</h2>
    <div class="box">
      <div class="col-md-12">
        <form>
          <div class="form-row">
            <div class="form-group col-md-12">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="waivePrice" />
                <label class="form-check-label" for="defaultCheck1">
                  Waive ZenBusiness Fee (for qualifying WFG customers)
                </label>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="price">ZenBusiness Price</label>
              <input v-model="price" class="form-control" :placeholder="zbPrice" name="Price" id="price" disabled />
            </div>
            <div class="form-group col-md-6">
              <label for="fees">State Fees</label>
              <select v-model="fees" class="form-control" id="fees">
                <option disabled>Please select one</option>
                <option v-if="domesticPrice" :value="domesticPrice['fees']">Domestic (${{ domesticPrice.fees }})</option>
                <option v-if="foreignPrice" :value="foreignPrice.fees">Foreign (${{ foreignPrice.fees }})</option>
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { orderCreateProductMixin } from '@/components/mixins';
import { mapActions } from 'vuex';

export default {
  name: 'wfgInitialReportCreate',

  props: ['pricing', 'jurisdiction', 'businessEntityType'],

  mixins: [orderCreateProductMixin],

  data() {
    return {
      productData: {
        order_data: {
          service: 'wfg_initial_report',
          jurisdiction: this.jurisdiction
        },
        order_item: {
          service: 'wfg_initial_report',
          jurisdiction: this.jurisdiction,
          price: this.pricing[this.businessEntityType] ? this.pricing[this.businessEntityType].domestic.price : undefined,
          fees: this.pricing[this.businessEntityType] ? this.pricing[this.businessEntityType].domestic.fees : undefined
        }
      },
      localPrice: undefined,
      localPrices: [],
      localFees: []
    }
  },

  methods: {
    // TODO: remove unused methods
    ...mapActions('orderCreate', [
      'fetchProductPrices',
    ]),
    setOrderItem() {
      if(this.localPrice) {
        this.productData.order_item.price = this.localPrice.related_service_pricing.amount;

        // Logic for setting term and period.
        const intervalLabel = this.localPrice.related_service_pricing.interval;
        const { term, period } = this.getTermAndPeriodAccordingIntervalLabel(intervalLabel);
        this.productData.order_item.term = term;
        this.productData.order_item.period = period;
      }
    }
  },

  computed: {
    domesticPrice() {
      return this.pricing[this.businessEntityType] ? this.pricing[this.businessEntityType].domestic : undefined;
    },
    foreignPrice() {
      return this.pricing[this.businessEntityType] ? this.pricing[this.businessEntityType].foreign : undefined;
    },
    zbPrice() {
      if (!this.domesticPrice && !this.foreignPrice.price) {
        return undefined;
      }
      return this.domesticPrice ? this.domesticPrice.price : this.foreignPrice.price;
    },
    waivePrice: {
      get() {
        return this.waiveZen;
      },
      set(value) {
        this.waiveZen = value;
        if (this.localPrice) {
          this.productData.order_item.price = (value === true) ? 0 : this.localPrice.related_service_pricing.amount;
        } else {
          this.productData.order_item.price = (value === true) ? 0 : this.pricing.price;
        }
      }
    }
  },

  created() {
    const product = 'wfg_initial_report';
    this.fetchProductPrices({ product, affiliate: 'pf' })
      .then((data) => {
        if (data.success) {
          this.localPrices = data.prices;

          if (this.localPrices?.length === 1) {
            this.localPrice = this.localPrices[0];
            this.setOrderItem();
          }
        }
      });
  }

};
</script>
