<template>
  <div>
    <h2 class="header">Fulfillment Info</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <h5>{{`${jurisdiction_code} `}}Agency Account Management</h5>
        </div>
      </div>
      <div class="row" >
        <div class="col">
          <form>
            <label for="agency_login_account">Primary Agency Login Account</label>
            <select class="form-control input-sm" v-model="agencyLoginAccount" name="agency_login_account" id="agency_login_account">
              <option v-bind:value="''">Select a primary agency login account</option>
              <option v-for="ala in this.agencyLoginAccounts" v-bind:key="ala.uuid" v-bind:value="ala.uuid">{{ ala.username }}</option>
            </select>
            <small v-if="this.orderTaskFulfillment">Updated by {{orderTaskFulfillment.updated_by}} on {{orderTaskFulfillment.updated_datetime | moment('MM/DD/YYYY h:mma')}}</small>
            <ZgButton :isEnable="!!agencyLoginAccount && isSaveButtonEnable" class="zg-btn-default zg-text-sm" @click="saveAgencyLoginAccount()" type="button">
              <template v-slot:title><i class="fa fa-save"></i>&nbsp;Save</template>
            </ZgButton>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <CollapsiblePanel v-if="jurisdiction"
            :titleBorder="false"
          >
            <template v-slot:title>
              {{ getMessageAccountAccess }}
            </template>
            <template v-slot:content>
              <SharedAgencyLoginAccounts v-if="isAccessEstablished" 
                :jurisdiction="jurisdiction" :key="primaryAgencyLoginKey">
              </SharedAgencyLoginAccounts>
              <JurisdictionPinEntry v-else
                :jurisdiction="jurisdiction">
              </JurisdictionPinEntry>
            </template>
          </CollapsiblePanel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapActions, mapState } from 'vuex'
import JurisdictionPinEntry from './jurisdiction-pin-entry'
import SharedAgencyLoginAccounts from './SharedAgencyLoginAccounts.vue'
import ZgButton from '../../common/Generics/ZgButton.vue'
import { hasPermissionMixin, userInformationMixin } from '@/components/mixins';
import CollapsiblePanel from '@/components/common/collapsible-panel';

export default {
  name: 'OrderTaskFulfillment',
  components: {
    JurisdictionPinEntry,
    SharedAgencyLoginAccounts,
    ZgButton,
    CollapsiblePanel
  },
  props: ['jurisdiction_code', 'order_task_status', 'business_entity_uuid'],
  mixins: [hasPermissionMixin, userInformationMixin],
  computed: {
    ...mapState({
      agencyLoginAccounts: (state) => state.fulfillment.agencyLoginAccounts,
      jurisdiction: (state) => state.businessEntities.jurisdictions[0],
      orderTaskFulfillment: (state) => state.fulfillment.orderTaskFulfillment
    }),
    isAccessEstablished() {
      return this.orderTaskFulfillment?.agency_login_account?true:false;
    },
    getMessageAccountAccess() {
      return this.isAccessEstablished?`Account Access has been Established`:`Account Access has not yet been Established`;
    }
  },
  methods: {
    ...mapActions({
      fetchAgencyLoginAccounts: 'fulfillment/fetchAgencyLoginAccounts',
      fetchOrderTaskFulfillment: 'fulfillment/fetchOrderTaskFulfillment',
      createOrderTaskFulfillment: 'fulfillment/createOrderTaskFulfillment',
      fetchBusinessEntity: 'businessEntities/fetchBusinessEntity',
      updateOrderTaskFulfillment: 'fulfillment/updateOrderTaskFulfillment'
    }),
    saveAgencyLoginAccount(){
      this.isSaveButtonEnable = false
      // If we have an Order Task Fulfillment we update else we create
      if (this.orderTaskFulfillment) {
        this.updateOrderTaskFulfillment({
          order_task_fulfillment_uuid: this.orderTaskFulfillment.order_task_fulfillment_uuid,
          order_task_uuid: this.$route.params.taskUuid,
          agency_login_account_uuid: this.agencyLoginAccount,
          user_email: this.getUserEmail()
        }).then( () => {
          this.fetchOrderTaskFulfillment({order_task_uuid: this.$route.params.taskUuid})
          this.primaryAgencyLoginKey += 1
        })
      } else {
        this.createOrderTaskFulfillment({
          order_task_uuid: this.$route.params.taskUuid,
          agency_login_account_uuid: this.agencyLoginAccount,
          user_email: this.getUserEmail()
        }).then( () => {
          this.fetchOrderTaskFulfillment({order_task_uuid: this.$route.params.taskUuid})
          this.primaryAgencyLoginKey += 1
        })
      }
    }
  },

  data() {
    return {
      isSaveButtonEnable: false,
      agencyLoginAccount: '',
      primaryAgencyLoginKey: 0
    }
  },
  created(){
    this.agencyLoginAccount = '';
    this.fetchBusinessEntity({uuid: this.business_entity_uuid});
    this.fetchAgencyLoginAccounts();
    this.fetchOrderTaskFulfillment({order_task_uuid: this.$route.params.taskUuid});
  },
  updated(){
    if(this.orderTaskFulfillment && this.agencyLoginAccount=='') {
      this.agencyLoginAccount = this.orderTaskFulfillment.agency_login_account?.uuid;
    } else if(this.orderTaskFulfillment && this.orderTaskFulfillment.agency_login_account.uuid == this.agencyLoginAccount) {
      this.isSaveButtonEnable = false;
    } else {
      this.isSaveButtonEnable = true;
    }
  }
};
</script>

<style lang="scss" scoped>
  .box {
    .row {
      margin-bottom: em(15);
    }
  }
  .primary-login {
    margin-left: 0.25em;
  }
  small {
    color: $clr-font-secondary;
    grid-column: 1/2;
    grid-row: 3;
  }
  label {
    color: $clr-nav-primary;
    font-weight: bold;
    font-size: .875em;
    grid-column: 1/2;
    grid-row: 1;
  }
  select {
    grid-column: 1;
    grid-row: 2;
  }
  .zg-btn {
    grid-column: 2;
    grid-row: 2;
    min-height: 100%;
  }
  form {
    display: grid;
    grid-template-columns: 85% 15%;
    grid-template-rows: auto 1fr;
    grid-row-gap: 0.5em;
    margin-left: 0.25em;
  }
</style>
